import { Fragment, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Moment from 'moment';

const ItemAddFlight = ( { keyItem, arrFlights, setArrFlights } ) => {

    const [name, setName] = useState(null)
    const [fromTime, setFromTime] = useState(null)
    const [toTime, setToTime] = useState(null)
    const [durationTime, setDurationTime] = useState(null)
    const [dateFlight, setDateFlight] = useState(null)

    const onChangeValue = (index, event) => {
        if (!arrFlights[index]) {
            arrFlights[index] = {};
        }
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        arrFlights[index][fieldName] =  event.target.value;
        if(fieldName === 'name'){
            setName(event.target.value)
        }
        if(fieldName === 'fromTime'){
            setFromTime(event.target.value)
        }
        if(fieldName === 'toTime'){
            setToTime(event.target.value)
        }
        if(fieldName === 'dateFlight'){
            setDateFlight(Moment(event.target.value).format('YYYY-MM-DD'))
        }
    }

    const secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay; 
    }
    //Moment(result).format('YYYY-MM-DD')

    useEffect(() => {
        async function changeDurationTime(){
            if(fromTime !== 0 && toTime !== 0 && dateFlight !== null){
                const timeAndDateFrom = Moment(dateFlight + ' ' + fromTime);
                const timeAndDateTo = Moment(dateFlight + ' ' + toTime);
                const timeStep = Moment(Math.abs(timeAndDateTo - timeAndDateFrom)).unix();
                setDurationTime(secondsToHms(timeStep))
                arrFlights[keyItem]['durationTime'] =  secondsToHms(timeStep);
                //console.log(Moment(Math.abs(timeAndDateTo - timeAndDateFrom)).unix());
                //console.log(Date.parse(timeAndDateTo)/1000 - Date.parse(timeAndDateFrom)/1000)
            }
        }
        changeDurationTime()
    }, [fromTime, toTime, dateFlight, durationTime, keyItem]) // eslint-disable-line react-hooks/exhaustive-deps

    console.log('arrFlights', arrFlights)

    return (
        <Fragment>
            <tr>
                <td>
                    <input type="text" className="form-control" id="name" name={`name-${keyItem}`} placeholder="Flight Route" value={name !== null ? name : ''} onChange={onChangeValue.bind(this, keyItem)} />
                </td>
                <td className="">
                    <Row>
                        <Col>
                            <input type="date" className="form-control" id="dateFlight" name={`dateFlight-${keyItem}`} value={dateFlight !== null ? dateFlight : ''} onChange={onChangeValue.bind(this, keyItem)} />
                        </Col>
                        <Col>
                            <input type="time" className="form-control" id="fromTime" name={`fromTime-${keyItem}`} placeholder="Flight Route" value={fromTime !== null ? fromTime : ''} onChange={onChangeValue.bind(this, keyItem)} />
                        </Col>
                        <Col>
                            <input type="time" className="form-control" id="toTime" name={`toTime-${keyItem}`} placeholder="Flight Route" value={toTime !== null ? toTime : ''} onChange={onChangeValue.bind(this, keyItem)} />
                        </Col>
                    </Row>
                </td>
                <td className="tx-center">
                    <input type="text" className="form-control" id="durationTime" name={`durationTime-${keyItem}`} placeholder="1 hour 15 minutes" value={durationTime !== null ? durationTime : ''} readOnly />
                </td>
            </tr>
        </Fragment>
    )
}

export default ItemAddFlight