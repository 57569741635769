import { createContext, useState, useReducer } from 'react';
import { customerReducer } from '../reducers/customerReducer';
import { apiUrl, CUSTOMERS_LOADED_FAIL, CUSTOMERS_LOADED_SUCCESS, FIND_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER, ADD_CUSTOMER } from './constants';
import axios from 'axios';


export const CustomerContext = createContext()

const CustomerContextProvider = ({ children }) => {

    // State
    const [customerState, dispatch] = useReducer(customerReducer, {
        customer: null,
        customers: [],
        customersLoading: true
    })

    const [showAddCustomer, setAddCustomer] = useState(false)
    const [showUpdateCustomer, setShowUpdateCustomer] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Customers
    const getCustomers = async() => {
        try {
            const response = await axios.get(`${apiUrl}/customers`)
            if (response.data.success) {
                dispatch({ type: CUSTOMERS_LOADED_SUCCESS, payload: response.data.customers })
            }
        } catch (error) {
            dispatch({ type: CUSTOMERS_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Car
    const addCustomer = async newCustomer => {
        try {
            const response = await axios.post(`${apiUrl}/customers`, newCustomer)
            if (response.data.success) {
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: response.data.customer
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Customer when user is updating Customer
    const findCustomer = customerId => {
        const customer = customerState.customers.find(customer => customer.id === customerId)
        dispatch({
            type: FIND_CUSTOMER,
            payload: customer
        })
    }

    // update Customer
    const updateCustomer = async updateCustomer => {
        try {
            const response = await axios.put(`${apiUrl}/customers/${updateCustomer.id}`, updateCustomer)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: response.data.customer
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Customer
    const deleteCustomer = async customerId => {
        try {
            const response = await axios.delete(`${apiUrl}/customers/${customerId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_CUSTOMER,
                    payload: customerId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Customer Context Data
    const customerContextData = {
        customerState,
        getCustomers,
        addCustomer,
        findCustomer,
        updateCustomer,
        deleteCustomer,
        showToast,
        setShowToast,
        showAddCustomer,
        setAddCustomer,
        showUpdateCustomer,
        setShowUpdateCustomer
    }

    return ( 
        <CustomerContext.Provider value = { customerContextData } > 
            { children } 
        </CustomerContext.Provider>
    )

}

export default CustomerContextProvider