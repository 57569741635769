import { Fragment, useContext, useEffect, useState } from 'react';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import { Image } from 'react-bootstrap';
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import imageToBase64 from 'image-to-base64/browser';
import { apiUrlLink } from '../../contexts/constants';

const Detail = () => {

    const { 
        invoiceState: { invoice },
        searchInvoice
    } = useContext(InvoiceContext)

    const { id } = useParams()

    const [srcImage, setSrcImage] = useState(null)

    // Start: Get all staffs , []
    useEffect( () => searchInvoice(id), [id] ) // eslint-disable-line react-hooks/exhaustive-deps

    const outputPdf = () => {
        exportPDF();
    }

    const exportPDF = () => {

        const element = document.getElementById('contentPDF');
        const opt = {
            margin:       0.5,
            filename:     'myfile.pdf',
            image: { type: "jpeg", quality: 1 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        };
        
        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();
        
        // Old monolithic-style usage:
        //html2pdf(element, opt);

    }

    //console.log('invoice', invoice)
    let body = null
    if(invoice !== null){
        const dataImage = apiUrlLink + '/uploads/users/images/thumb_img/50/' + invoice.sale.images;
        const arrServices = JSON.parse(invoice.service);
        const linkPayments = invoice.linkPayments;
        const arrPayments = []
        let totalPay = 0
        if(linkPayments.length > 0){
            linkPayments.map((payment, index) => {
                if(payment.status === "pay"){
                    arrPayments.push(payment)
                    totalPay += payment.amount
                    //console.log(payment.amount)
                }
            })
        }

        

        imageToBase64(dataImage) // Path to the image
        .then(
            (response) => {
                setSrcImage(response); // "cGF0aC90by9maWxlLmpwZw=="
            }
        )
        .catch(
            (error) => {
                console.log(error); // Logs an error if there was one
            }
        )
        //console.log('srcImage', srcImage)
        body = (
            <div className="container">
                <div className="breadcrumb-header justify-content-between"> 
                    <button className="btn btn-success btn-with-icon" onClick={outputPdf}><i className="fe fe-arrow-up-circle"></i> Export Invoice</button>
                </div>
                <div className="row row-sm">
                    <div className="col-md-12 col-xl-12">
                        <div className='main-content-body-invoice'>
                            <div className='card card-invoice' id="contentPDF" style={ {boxShadow: "none"} }>
                                <div className='card-body'>
                                    <div className="invoice-header"> 
                                        <h1 className="invoice-title">Invoice</h1> 
                                        <div className="billed-from"> 
                                            <h6>Asia Links Travel, Jsc.</h6> 
                                            <p><strong>Address:</strong> 25, Group 7, Tan Mai, Hoang Mai, Hanoi, Vietnam<br /><strong>Tel No:</strong> +84 987 975 514<br /><strong>Email:</strong> info@asiaprivateholidays.com</p>
                                        </div>
                                    </div>
                                    <div className="row mg-t-20">
                                        <div className="col-md"> 
                                            <label className="tx-gray-600"><strong>Billed To</strong></label> 
                                            <div className="billed-to"> 
                                                <h6>{invoice.booking.mrName} {invoice.booking.firstName} {invoice.booking.lastName},</h6> 
                                                <p>
                                                    <strong>Country:</strong> {invoice.booking.country}<br />
                                                    <strong>Tel No:</strong> {invoice.booking.phone}<br />
                                                    <strong>Email:</strong> {invoice.booking.email}
                                                </p>
                                            </div> 
                                            <label className="tx-gray-600"><strong>Issued By</strong></label> 
                                            <div className="billed-to">
                                                <h6>{invoice.sale.name},</h6> 
                                                <div className="main-img-user profile-user">
                                                    {srcImage !== null ? (
                                                    <Image alt={invoice.sale.name} src={`data:image/jpg;base64,${srcImage}`} width="35" />
                                                    ) : null}
                                                </div>
                                                <p>
                                                    <strong>Regency:</strong> Travel Consultant <br />
                                                    <strong>Tel No:</strong> {invoice.sale.sdt}<br />
                                                    <strong>Email:</strong> {invoice.sale.email}<br />
                                                    <strong>Issued Date:</strong> {Moment(invoice.created_at).format('MMM DD, YYYY')}
                                                </p>
                                            </div>
                                        </div> 
                                        <div className="col-md"> 
                                            <label className="tx-gray-600"><strong>Invoice Information</strong></label> 
                                            <p className="invoice-info-row"><span>Invoice No</span> <span>ALT-APH-{invoice.booking.id}</span></p>
                                            <p className="invoice-info-row"><span>Project ID</span> <span>{Moment(invoice.booking.created_at).format('MMDYY')}{invoice.booking.id}</span></p>
                                            <p className="invoice-info-row"><span>Tour Name</span> <span>{invoice.booking.tourName}</span></p>
                                            <p className="invoice-info-row"><span>Duration</span> <span>{invoice.duration} Day</span></p>
                                            <p className="invoice-info-row"><span>Arrival Date</span> <span>{Moment(invoice.dateStart).format('MMM DD, YYYY')}</span></p>
                                            <p className="invoice-info-row"><span>Departure Date</span> <span>{Moment(invoice.dateEnd).format('MMM DD, YYYY')}</span></p>
                                            <p className="invoice-info-row"><span>Adults</span> <span>{invoice.adult} Pax</span></p>
                                            {invoice.child !== null ? (
                                                <p className="invoice-info-row"><span>Children's</span> <span>{invoice.child} Pax</span></p>
                                            ) : (
                                                <p className="invoice-info-row"><span>Children's</span> <span>0 Pax</span></p>
                                            )}
                                            {invoice.infant !== null ? (
                                                <p className="invoice-info-row"><span>Infants</span> <span>{invoice.infant} Pax</span></p>
                                            ) : (
                                                <p className="invoice-info-row"><span>Infants</span> <span>0 Pax</span></p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-responsive mg-t-40">
                                        <table className="table table-invoice border text-md-nowrap mb-0"> 
                                            <thead>
                                                <tr>
                                                    <th className="wd-25p">Service Name</th>
                                                    <th className="wd-25p">Description</th>
                                                    <th className="tx-center">QNTY</th>
                                                    <th className="tx-right">Unit Price</th>
                                                    <th className="tx-right">Amount</th>
                                                </tr> 
                                            </thead> 
                                            <tbody>
                                                {arrServices.map((service, index) => (
                                                    <tr key={index}>
                                                        <td style={ {lineHeight: "40px"} }>
                                                            {service.name}
                                                        </td>
                                                        <td className="tx-14" style={ {lineHeight: "40px"} }>
                                                            {service.subDescription}
                                                        </td>
                                                        <td className="tx-center" style={ {lineHeight: "40px"} }>
                                                            {service.quantity}
                                                        </td>
                                                        <td className="tx-right" style={ {lineHeight: "40px"} }>
                                                            ${service.price}
                                                        </td>
                                                        <td className="tx-right" style={ {lineHeight: "40px"} }>
                                                            <strong>${service.totalPrice}</strong>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className="valign-middle" colSpan="2"  rowSpan={totalPay > 0 ? "5" : "4"}>
                                                        <div className="invoice-notes"> 
                                                            <label className="main-content-label tx-13">Notes</label> 
                                                            <p>{invoice.description}</p>
                                                        </div>
                                                    </td>
                                                    <td className="tx-right">Sub-Total</td>
                                                    <td className="tx-right" colSpan="2">${Number(invoice.amount).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="tx-right">Online Payment Fee (3%)</td>
                                                    <td className="tx-right" colSpan="2">
                                                        $
                                                        { invoice.checkDiscount === "money" ? 
                                                            Number(((invoice.amount - invoice.discount)*0.03) - (totalPay*0.03)).toFixed(2) : Number(((invoice.amount - ((invoice.discount/100)*invoice.amount))*0.03) - (totalPay*0.03)).toFixed(2)
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="tx-right">Discount</td>
                                                    <td className="tx-right custom-discount" colSpan="2">
                                                        {invoice.checkDiscount === "money" ? `$${invoice.discount}` : `${invoice.discount}%`}
                                                    </td>
                                                </tr>
                                                {totalPay > 0 ? (
                                                <tr>
                                                    <td className="tx-right">Already Paid</td>
                                                    <td className="tx-right custom-discount" colSpan="2">
                                                        {arrPayments.map((payment, dem) => {
                                                            if(dem === 0) {
                                                                return (<>Pay {dem + 1}: ${Number(payment.amount).toFixed(2)}</>)               
                                                            }else{
                                                                return (<><br />Pay {dem + 1}: ${Number(payment.amount).toFixed(2)}</>)
                                                            }
                                                        })}
                                                    </td>
                                                </tr>
                                                ) : null}
                                                <tr>
                                                    <td className="tx-right tx-uppercase tx-bold tx-inverse">Balance</td>
                                                    <td className="tx-right" colSpan="2"> 
                                                        <h4 className="tx-primary tx-bold">
                                                            $
                                                            { invoice.checkDiscount === "money" ? 
                                                                //Number(((invoice.amount - invoice.discount)*0.03) - (totalPay*0.03) - totalPay).toFixed(2) : Number(((invoice.amount - ((invoice.discount/100)*invoice.amount))*0.03) - (totalPay*0.03) - totalPay).toFixed(2)
                                                                Number((invoice.amount + (invoice.amount - invoice.discount)*0.03) - (totalPay*0.03) - totalPay).toFixed(2) : Number((invoice.amount + (invoice.amount - ((invoice.discount/100)*invoice.amount))*0.03) - (totalPay*0.03) - totalPay).toFixed(2)
                                                            }
                                                        </h4> 
                                                    </td>
                                                </tr> 
                                            </tbody>
                                        </table> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body !== null ? body : null}
        </Fragment>
    )
}

export default Detail