import { Fragment, useState } from 'react';
import { Tab, Tabs, Form, Row, Col } from 'react-bootstrap';

const ItemRoom = ( { keyItem, arrayPrices } ) => {

    // State
    const [newRoomPrice, setNewRoomPrice] = useState({
        nameRoom: '',
        adultLowSeason: '',
        childLowSeason: '',
        infantLowSeason: '',
        extraLowSeason: '',
        adultHighSeason: '',
        childHighSeason: '',
        infantHighSeason: '',
        extraHighSeason: ''
    })

    const { nameRoom, adultLowSeason, childLowSeason, infantLowSeason, extraLowSeason,
        adultHighSeason, childHighSeason, infantHighSeason, extraHighSeason
    } = newRoomPrice

    const onChangeValue = ( event ) => {
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        setNewRoomPrice( {...newRoomPrice, [fieldName]: event.target.value } )
    }
    
    const onChangeNumber = (event) => {
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        setNewRoomPrice( {...newRoomPrice, [fieldName]: Number(event.target.value) } )
    }

    if(nameRoom !== null && nameRoom !== ''){
        arrayPrices[keyItem] = nameRoom + "#" + adultLowSeason + "|" + childLowSeason + "|" + infantLowSeason + "|" + extraLowSeason
        + "#" + adultHighSeason + "|" + childHighSeason + "|" + infantHighSeason + "|" + extraHighSeason;
    }


    return (
        <Fragment>
            <div className="form-group">
                <label><strong>Room Name {keyItem + 1}</strong></label>
                <input type="text" className="form-control" id={`nameRoom-${keyItem}`} name={`nameRoom-${keyItem}`} value={nameRoom} placeholder="Enter Room Name" onChange={onChangeValue} required aria-describedby='name-help' />
                <Form.Text id='name-help' muted>Required</Form.Text>
            </div>
            <Tabs defaultActiveKey={`home${keyItem}`} id={`uncontrolled-tab-example${keyItem}`}>
                <Tab eventKey={`home${keyItem}`} title="Low Season">
                    <div className="form-group">
                        <Row>
                            <Col>
                                <label><strong>Adult</strong></label>
                                <input type="number" className="form-control" id={`adultLowSeason-${keyItem}`} name={`adultLowSeason-${keyItem}`} value={adultLowSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                            <Col>
                                <label><strong>Child</strong></label>
                                <input type="number" className="form-control" id={`childLowSeason-${keyItem}`} name={`childLowSeason-${keyItem}`} value={childLowSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                            <Col>
                                <label><strong>Infant</strong></label>
                                <input type="number" className="form-control" id={`infantLowSeason-${keyItem}`} name={`infantLowSeason-${keyItem}`} value={infantLowSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                            <Col>
                                <label><strong>Extra Bed</strong></label>
                                <input type="number" className="form-control" id={`extraLowSeason-${keyItem}`} name={`extraLowSeason-${keyItem}`} value={extraLowSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                        </Row>
                    </div>
                </Tab>
                <Tab eventKey={`profile${keyItem}`} title="High Season">
                    <div className="form-group">
                        <Row>
                            <Col>
                                <label><strong>Adult</strong></label>
                                <input type="number" className="form-control" id={`adultHighSeason-${keyItem}`} name={`adultHighSeason-${keyItem}`} value={adultHighSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                            <Col>
                                <label><strong>Child</strong></label>
                                <input type="number" className="form-control" id={`childHighSeason-${keyItem}`} name={`childHighSeason-${keyItem}`} value={childHighSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                            <Col>
                                <label><strong>Infant</strong></label>
                                <input type="number" className="form-control" id={`infantHighSeason-${keyItem}`} name={`infantHighSeason-${keyItem}`} value={infantHighSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                            <Col>
                                <label><strong>Extra Bed</strong></label>
                                <input type="number" className="form-control" id={`extraHighSeason-${keyItem}`} name={`extraHighSeason-${keyItem}`} value={extraHighSeason} onChange={onChangeNumber} placeholder="No. Price" />
                            </Col>
                        </Row>
                    </div>
                </Tab>
            </Tabs>
        </Fragment>
    )
}

export default ItemRoom