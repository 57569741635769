import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './assets/css/icons.css';
import './assets/css/style.css';
import './App.css';
import Landing from './components/layout/Landing';
import Auth from './views/Auth';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';

import Itinerary from './views/itineraries/Itinerary';
import User from './views/users/User';
import Car from './views/cars/Car';
import Guide from './views/guides/Guide';
import Service from './views/services/Service';
import Accommodation from './views/accommodations/Accommodation';
import Request from './views/requests/Request';
import Dealing from './views/requests/dealings/Dealing';
import New from './views/requests/news/New';
import Success from './views/requests/success/Success';
import Complete from './views/requests/complete/Complete';
import Fails from './views/requests/failed/Fails';
import Invoice from './views/invoices/Invoice';
import Detail from './views/invoices/Detail';
import FullItinerary from './views/fullItineraries/FullItinerary';
import DetailItinerary from './views/fullItineraries/DetailItinerary';
import Customer from './views/customers/Customer';
import DetailRequest from './views/requests/DetailRequest';
import PromotionMail from './views/promotions/PromotionMail';
import MailCompose from './views/promotions/composes/MailCompose';
import MailMarketing from './views/promotions/marketing/MailMarketing';
import Operating from './views/operating/Operating';
import Partner from './views/partners/Partner';
import ContractFile from './views/contracts/ContractFile';
import ContractDetail from './views/contracts/ContractDetail';

import AuthContextProvider from './contexts/AuthContext';
import ProtectedRoute from './components/routing/ProtectedRoute';
import PostContextProvider from './contexts/PostContext';

import CarContextProvider from './contexts/CarContext';
import GuideContextProvider from './contexts/GuideContext';
import CountryContextProvider from './contexts/CountryContext';
import DestinationContextProvider from './contexts/DestinationContext';
import ItineraryContextProvider from './contexts/ItineraryContext';
import ServiceContextProvider from './contexts/ServiceContext';
import AccommodationContextProvider from './contexts/AccommodationContext';
import RequestContextProvider from './contexts/RequestContext';
import InvoiceContextProvider from './contexts/InvoiceContext';
import FullItineraryContextProvider from './contexts/FullItineraryContext';
import CustomerContextProvider from './contexts/CustomerContext';
import TodoListContextProvider from './contexts/TodoListContext';
import PartnerContextProvider from './contexts/PartnerContext';
import ContractContextProvider from './contexts/ContractContext';


function App() {
    return (
        <AuthContextProvider>
            <PostContextProvider>
                <CarContextProvider>
                    <CountryContextProvider>
                        <DestinationContextProvider>
                            <GuideContextProvider>
                                <ItineraryContextProvider>
                                    <ServiceContextProvider>
                                        <AccommodationContextProvider>
                                            <RequestContextProvider>
                                                <InvoiceContextProvider>
                                                    <FullItineraryContextProvider>
                                                        <CustomerContextProvider>
                                                            <TodoListContextProvider>
                                                                <PartnerContextProvider>
                                                                    <ContractContextProvider>
                                                                        <Router>
                                                                            <Switch>
                                                                                <Route exact path='/' component={Landing} />
                                                                                <Route exact path='/login' render={ props => <Auth { ...props } authRoute='login' /> } />
                                                                                <Route exact path='/register' render={ props => <Auth { ...props } authRoute='register' /> } />
                                                                                <ProtectedRoute exact path='/dashboard' component={Dashboard} />
                                                                                <ProtectedRoute exact path='/profile/:id' component={Profile} />
                                                                                <ProtectedRoute exact path='/itineraries' component={Itinerary} />
                                                                                <ProtectedRoute exact path='/users' component={User} />
                                                                                <ProtectedRoute exact path='/cars' component={Car} />
                                                                                <ProtectedRoute exact path='/guides' component={Guide} />
                                                                                <ProtectedRoute exact path='/services' component={Service} />
                                                                                <ProtectedRoute exact path='/accommodations' component={Accommodation} />
                                                                                <ProtectedRoute exact path='/partners' component={Partner} />
                                                                                <ProtectedRoute exact path='/requests' component={Request} />
                                                                                <ProtectedRoute exact path='/requests/:id' component={DetailRequest} />
                                                                                <ProtectedRoute exact path='/dealings' component={Dealing} />
                                                                                <ProtectedRoute exact path='/new-requests' component={New} />
                                                                                <ProtectedRoute exact path='/success' component={Success} />
                                                                                <ProtectedRoute exact path='/completes' component={Complete} />
                                                                                <ProtectedRoute exact path='/failed' component={Fails} />
                                                                                <ProtectedRoute exact path='/invoices' component={Invoice} />
                                                                                <ProtectedRoute exact path='/invoices/:id' component={Detail} />
                                                                                <ProtectedRoute exact path='/full-itineraries' component={FullItinerary} />
                                                                                <ProtectedRoute exact path='/full-itineraries/:id' component={DetailItinerary} />
                                                                                <ProtectedRoute exact path='/customers' component={Customer} />
                                                                                <ProtectedRoute exact path='/promotions-mail' component={PromotionMail} />
                                                                                <ProtectedRoute exact path='/mail-compose' component={MailCompose} />
                                                                                <ProtectedRoute exact path='/mail-marketing' component={MailMarketing} />
                                                                                <ProtectedRoute exact path='/operating/:id' component={Operating} />
                                                                                <ProtectedRoute exact path='/contract-file' component={ContractFile} />
                                                                                <ProtectedRoute exact path='/contract-file/:id' component={ContractDetail} />
                                                                            </Switch>
                                                                        </Router>
                                                                    </ContractContextProvider>
                                                                </PartnerContextProvider>
                                                            </TodoListContextProvider>
                                                        </CustomerContextProvider>
                                                    </FullItineraryContextProvider>
                                                </InvoiceContextProvider>
                                            </RequestContextProvider>
                                        </AccommodationContextProvider>
                                    </ServiceContextProvider>
                                </ItineraryContextProvider>
                            </GuideContextProvider>
                        </DestinationContextProvider>
                    </CountryContextProvider>
                </CarContextProvider>
            </PostContextProvider>
        </AuthContextProvider>
    );
}

export default App;