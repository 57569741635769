import { Fragment } from 'react';
import ItemAddLinkPayment from './ItemAddLinkPayment';

const AddLinkPayment = ( { numberPaymentKey, invoice, optionOldOrders, arrLinkPayments, setArrLinkPayments, checkLinkOrder } ) => {

    let optionOrders = []

    for (var k = 1; k < 32; k++) {
        //.includes('jane'))
        optionOrders = [ ...optionOrders,
            {
                value: `order-${k}`,
                label: `Order ${k}`,
            }
        ]
    }

    //let difference = optionOldOrders.filter(x => !optionOrders.includes(x.value));

    const resultOrders = optionOrders.filter(col => {
        return optionOldOrders.find(option => option.value !== col.value)
    })

    //console.log('optionOldOrders', optionOldOrders)

    

    return (
        <Fragment>
            {Array(numberPaymentKey).fill(1).map((option, index) => (
                <ItemAddLinkPayment key={index} itemKey={index} invoice={invoice} resultOrders={resultOrders} arrLinkPayments={arrLinkPayments} setArrLinkPayments={setArrLinkPayments} checkLinkOrder={checkLinkOrder} />
            ))}
        </Fragment>
    )
}

export default AddLinkPayment