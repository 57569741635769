import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import ItemUpdateRoom from './ItemUpdateRoom';

const ListUpdateRoom = ( { numberKey, arrayPrices } ) => {
    return (
        <Accordion defaultActiveKey="0" className="list-room-price">
            {
                Array(numberKey).fill(1).map((option, index) => (
                    <Card key={index}>
                        <Accordion.Toggle as={Card.Header} eventKey={`0${index}`}>
                            Room {index + 1}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`0${index}`}>
                            <Card.Body>
                                <ItemUpdateRoom keyItem={index} arrayPrices={arrayPrices} dataItemPrice={arrayPrices[index]} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))
            }
        </Accordion>
    )
}

export default ListUpdateRoom