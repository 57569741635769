import { Fragment, useContext, useEffect } from 'react';
import { ServiceContext } from '../../contexts/ServiceContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Spinner, Card} from 'react-bootstrap';
import UpdateService from './UpdateService';
import DeleteModal from '../../components/layout/DeleteModal';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListService = () => {

    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)
    const { 
        serviceState: { service, services, servicesLoading },
        getServices,
        findService,
        deleteService,
        setShowUpdateService
    } = useContext(ServiceContext)

    const chooseService = serviceId => {
        findService(serviceId)
        setShowUpdateService(true)
    }

    // Start: Get all Itineraries , []
    useEffect( () => getServices(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps


    if(servicesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(services.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a Service
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Destination</th>
                                                <th>Country</th>
                                                <th>Code Service</th>
                                                <th className="w-220">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {services.map((service, index) => (
                                            <tr key={index}>
                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                <td style={ {lineHeight: "38px"} }>{service.name}</td>
                                                <td style={ {lineHeight: "38px"} }>{service.amount}</td>
                                                <td style={ {lineHeight: "38px"} }>{service.destinationName}</td>
                                                <td style={ {lineHeight: "38px"} }>{service.countryName}</td>
                                                <td style={ {lineHeight: "38px"} }>{service.codeService}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            <button className="btn btn-success btn-with-icon btn-block" onClick={chooseService.bind(this, service.id)}><i className="fe fe-edit"></i> Edit</button>
                                                        </div>
                                                        <div className="col-sm">
                                                            <DeleteModal idProps={service.id} deleteFunc={deleteService} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body}
            {service !== null && <UpdateService />}
        </Fragment>
    )
}

export default ListService