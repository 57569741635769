import { TODO_LISTS_LOADED_FAIL, TODO_LISTS_LOADED_SUCCESS, FIND_TODO_LIST, UPDATE_TODO_LIST, DELETE_TODO_LIST, ADD_TODO_LIST } from '../contexts/constants';

export const todoListReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case TODO_LISTS_LOADED_SUCCESS:
            return {
                ...state,
                todoLists: payload,
                todoListsLoading: false
            }
        case TODO_LISTS_LOADED_FAIL:
            return {
                ...state,
                todoLists: [],
                todoListsLoading: false
            }
        case ADD_TODO_LIST:
            {
                return {
                    ...state,
                    todoLists: [...state.todoLists, payload],
                }
            }
        case FIND_TODO_LIST:
            return {
                ...state,
                todoList: payload
            }
        case UPDATE_TODO_LIST:
            const newTodoList = state.todoLists.map(todoList => {
                if (todoList.id === payload.id) {
                    return payload
                } else {
                    return todoList
                }
            })
            return {
                ...state,
                todoLists: newTodoList
            }
        case DELETE_TODO_LIST:
            return {
                ...state,
                todoLists: state.todoLists.filter(todoList => todoList.id !== payload)
            }
        default:
            return state
    }
}