import { Fragment, useContext, useEffect } from 'react';
import { CustomerContext } from '../../contexts/CustomerContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdateCustomer from './UpdateCustomer';
import DeleteModal from '../../components/layout/DeleteModal';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListCustomer = () => {

    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)
    const { 
        customerState: { customer, customers, customersLoading },
        getCustomers,
        findCustomer,
        deleteCustomer,
        setShowUpdateCustomer
    } = useContext(CustomerContext)

    const chooseCustomer = customerId => {
        findCustomer(customerId)
        setShowUpdateCustomer(true)
    }

    // Start: Get all xe , []
    useEffect( () => getCustomers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(customersLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(customers === null || customers.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a Car
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Country</th>
                                                <th>Code Tour</th>
                                                <th>Sales</th>
                                                <th className="w-220">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customers.map((customer, index) => (
                                            <tr key={index}>
                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                <td style={ {lineHeight: "38px"} }>{customer.name}</td>
                                                <td style={ {lineHeight: "38px"} }>{customer.email}</td>
                                                <td style={ {lineHeight: "38px"} }>{customer.countryName}</td>
                                                <td style={ {lineHeight: "38px"} }>{customer.codeTour}</td>
                                                <td style={ {lineHeight: "38px"} }>{customer.infoSales.name}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            <button className="btn btn-success btn-with-icon btn-block" onClick={chooseCustomer.bind(this, customer.id)}><i className="fe fe-edit"></i> Edit</button>
                                                        </div>
                                                        <div className="col-sm">
                                                            <DeleteModal idProps={customer.id} deleteFunc={deleteCustomer} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <Fragment>
            {body}
            {customer !== null && <UpdateCustomer />}
        </Fragment>
    )
}

export default ListCustomer