import { CONTRACTS_LOADED_FAIL, CONTRACTS_LOADED_SUCCESS, FIND_CONTRACT, SEARCH_CONTRACT, UPDATE_CONTRACT, DELETE_CONTRACT, ADD_CONTRACT } from '../contexts/constants';

export const contractReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case CONTRACTS_LOADED_SUCCESS:
            return {
                ...state,
                contracts: payload,
                contractsLoading: false
            }
        case CONTRACTS_LOADED_FAIL:
            return {
                ...state,
                contracts: [],
                contractsLoading: false
            }
        case SEARCH_CONTRACT:
            return {
                ...state,
                contract: payload
            }
        case ADD_CONTRACT:
            {
                return {
                    ...state,
                    contracts: [...state.contracts, payload],
                }
            }
        case FIND_CONTRACT:
            return {
                ...state,
                contract: payload
            }
        case UPDATE_CONTRACT:
            const newContract = state.contracts.map(contract => {
                if (contract.id === payload.id) {
                    return payload
                } else {
                    return contract
                }
            })
            return {
                ...state,
                contracts: newContract
            }
        case DELETE_CONTRACT:
            return {
                ...state,
                contracts: state.contracts.filter(contract => contract.id !== payload)
            }
        default:
            return state
    }
}