import { Fragment, useContext } from 'react';
import TodoList from '../../views/todoLists/TodoList';
import Moment from 'moment';

const Information = () => {

    //var myCurrentDate = new Date();
    //var myPastDate = new Date(myCurrentDate);
    //myCurrentDate.setDate(myCurrentDate.getDate() - 3);

    //console.log('myCurrentDate', Moment(myCurrentDate).format('YYYY-MM-DD'))

    return (
        <Fragment>
            <div className="row row-sm">
                
                <div className="col-md-12 col-lg-6 col-xl-6 dashboard-table"> 
                    <div className="card card-table-two"> 
                        <div className="d-flex justify-content-between"> 
                            <h4 className="card-title mb-1">Sent mail 3 days</h4> 
                        </div> 
                        <span className="tx-12 tx-muted mb-3 ">List of emails sent to customers 3 days without reply.</span> 
                        <div className="table-responsive country-table"> 
                            <table className="table table-striped table-bordered mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap"> 
                                <thead>
                                    <tr>
                                        <th className="">STT</th>
                                        <th className="tx-right">Name</th>
                                        <th className="tx-right">Email</th>
                                        <th className="tx-right">Date Send</th>
                                        <th className="tx-right">Action</th>
                                    </tr> 
                                </thead> 
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td className="tx-right tx-medium tx-inverse">34</td>
                                        <td className="tx-right tx-medium tx-inverse">34</td>
                                        <td className="tx-right tx-medium tx-inverse">$658.20</td>
                                        <td className="tx-right tx-medium tx-danger">-$45.10</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td className="tx-right tx-medium tx-inverse">26</td>
                                        <td className="tx-right tx-medium tx-inverse">34</td>
                                        <td className="tx-right tx-medium tx-inverse">$453.25</td>
                                        <td className="tx-right tx-medium tx-danger">-$15.02</td>
                                    </tr>
                                    
                                </tbody>
                            </table> 
                        </div> 
                    </div> 
                    <div className="card card-table-two"> 
                        <div className="d-flex justify-content-between"> 
                            <h4 className="card-title mb-1">Sent mail 6 days</h4> 
                        </div> 
                        <span className="tx-12 tx-muted mb-3 ">List of emails sent to customers 6 days without reply.</span> 
                        <div className="table-responsive country-table"> 
                            <table className="table table-striped table-bordered mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap"> 
                                <thead>
                                    <tr>
                                        <th className="">STT</th>
                                        <th className="tx-right">Name</th>
                                        <th className="tx-right">Email</th>
                                        <th className="tx-right">Date Send</th>
                                        <th className="tx-right">Action</th>
                                    </tr> 
                                </thead> 
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td className="tx-right tx-medium tx-inverse">34</td>
                                        <td className="tx-right tx-medium tx-inverse">34</td>
                                        <td className="tx-right tx-medium tx-inverse">$658.20</td>
                                        <td className="tx-right tx-medium tx-danger">-$45.10</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td className="tx-right tx-medium tx-inverse">26</td>
                                        <td className="tx-right tx-medium tx-inverse">34</td>
                                        <td className="tx-right tx-medium tx-inverse">$453.25</td>
                                        <td className="tx-right tx-medium tx-danger">-$15.02</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div> 
                    </div> 
                </div>

                <div className="col-md-12 col-lg-6 col-xl-6 dashboard-table"> 
                    <div className="card card-dashboard-eight pb-2"> 
                        <h6 className="card-title">To-do List</h6>
                        <span className="d-block mg-b-10 text-muted tx-12">To-do list</span> 
                        <TodoList />
                    </div> 
                </div> 

            </div>
        </Fragment>
    )
}

export default Information
