import { Fragment, useContext, useEffect } from 'react';
import ContractController from './ContractController';
import { ContractContext } from '../../contexts/ContractContext';


const ContractFile = () => {

    const dt = new Date();
    //const month = ("0" + (dt.getMonth() + 1)).slice(-2); //("0" + 3).slice(-2); //("0" + (dt.getMonth() + 1)).slice(-2);
    const year = dt.getFullYear(); 

    //const [arrDestinations, setArrDestinations] = useState([]);

    const { 
        contractState: { contracts, contractsLoading },
        getContracts
    } = useContext(ContractContext)

    // Start: Get all xe , []
    useEffect( () => getContracts(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Contract in {year} of Asia Links Travel</h3>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <ContractController dataContracts={contracts} />
                </div>
            </div>
        </Fragment>
    )
}

export default ContractFile