import { createContext, useReducer } from 'react';
import { destinationReducer } from '../reducers/destinationReducer';
import { apiUrl, DESTINATIONS_LOADED_FAIL, DESTINATIONS_LOADED_SUCCESS } from './constants';
import axios from 'axios';


export const DestinationContext = createContext()

const DestinationContextProvider = ({ children }) => {

    // State
    const [destinationState, dispatch] = useReducer(destinationReducer, {
        destination: null,
        destinations: [],
        destinationsLoading: true
    })

    /*const [showAddCar, setAddCar] = useState(false)
    const [showUpdateCar, setShowUpdateCar] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })*/

    // Get all Cars
    const getDestinations = async() => {
        try {
            const response = await axios.get(`${apiUrl}/destinations`)
            if (response.data.success) {
                dispatch({ type: DESTINATIONS_LOADED_SUCCESS, payload: response.data.destinations })
            }
        } catch (error) {
            dispatch({ type: DESTINATIONS_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Country Context Data
    const destinationContextData = {
        destinationState,
        getDestinations
    }

    return ( 
        <DestinationContext.Provider value = { destinationContextData } > 
            { children } 
        </DestinationContext.Provider>
    )

}

export default DestinationContextProvider