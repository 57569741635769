import React, { Fragment, useContext, useEffect } from 'react';
import { ContractContext } from '../../contexts/ContractContext';
import { apiUrlLink } from '../../contexts/constants';
import { Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Moment from 'moment';

const ContractDetail = () => {

    const { 
        contractState: { contract },
        getContract
    } = useContext(ContractContext)

    const { id } = useParams()

    // Start: Get all staffs , []
    useEffect( () => getContract(id), [id] ) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('promotions', promotions)

    let body = null

    if(contract !== null){

        const dataImage = apiUrlLink + contract.photo;

        const rawHTML = ``;

        body = (
            <div className="row row-sm">
                <div className="col-lg-4">
                    <div className="card mg-b-20">
                        <div className="card-body">
                            <div className="pl-0">
                                <div className="main-profile-overview">
                                    <div className="main-img-user profile-user">
                                        <img alt={contract.name} src={dataImage} />
                                    </div>
                                    <div className="d-flex justify-content-between mg-b-20">
                                        <div>
                                            <h5 className="main-profile-name">{contract.name}</h5>
                                            <p className="main-profile-name-text">{contract.partnerName === 'Individual' ? 'Individual' : contract.partnerName + ' - Company'}</p>
                                        </div>
                                    </div>
                                    <hr className="mg-y-30" />
                                    <label className="main-content-label tx-13 mg-b-20">Contact Info</label>
                                    <div className="main-profile-social-list">
                                        <div className="media">
                                            <div className="media-icon bg-primary-transparent text-primary">
                                                <i className="si si-screen-smartphone"></i>
                                            </div>
                                            <div className="media-body">
                                                <span>Phone</span> {contract.phone}
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-icon bg-primary-transparent text-primary">
                                                <i className="si si-location-pin"></i>
                                            </div>
                                            <div className="media-body">
                                                <span>Email</span> {contract.email}
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-icon bg-primary-transparent text-primary">
                                                <i className="icons icon-globe"></i>
                                            </div>
                                            <div className="media-body">
                                                <span>Website</span> <a href={contract.website} title={contract.name} target={'_blank'}>{contract.website}</a>
                                            </div>
                                        </div>
                                        {contract.linkDriver !== null && (
                                            <div className="media">
                                                <div className="media-icon bg-primary-transparent text-primary">
                                                    <i className="icons icon-link"></i>
                                                </div>
                                                <div className="media-body">
                                                    <span>Google Driver</span> <a href={contract.linkDriver} title="Open Driver Link" target={'_blank'}>Click to Open</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    {contract.promotionTime.length > 0 && (
                        <div className="card mg-b-20">
                            <div className="card-body">
                                <div className="pl-0">
                                    <div className="main-profile-overview">
                                        <div className="d-flex justify-content-between mg-b-10">
                                            <div>
                                                <h5 className="main-profile-name">Promotion Info</h5>
                                            </div>
                                        </div>
                                        <hr className="mg-y-10" />
                                        <div className="main-profile-social-list promotion-show">
                                            {contract.promotionTime.map((promotion, keyIndex) => (
                                                <div key={keyIndex} className='item-promotion text-left'>
                                                    <h6>{promotion.name}</h6>
                                                    <p><strong>Start Date:</strong>&nbsp;{Moment(promotion.startDate).format('MMMM D, YYYY')}</p>
                                                    <p><strong>End Date:</strong>&nbsp;{Moment(promotion.endDate).format('MMMM D, YYYY')}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    )}
                    <div className="card mg-b-20">
                        <div className="card-body">
                            <div className="pl-0">
                                <div className="main-profile-overview">
                                    <div className="d-flex justify-content-between mg-b-20">
                                        <div>
                                            <h5 className="main-profile-name">Contact Sales</h5>
                                        </div>
                                    </div>
                                    <hr className="mg-y-30" />
                                    <div className="main-profile-social-list">
                                        <div className="media">
                                            <div dangerouslySetInnerHTML={{__html: contract.detail}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                    <div className="card mg-b-20">
                        <div className="card-body">
                            <div className="pl-0">
                                <div className="main-profile-overview">
                                    <div className="d-flex justify-content-between mg-b-20">
                                        <div>
                                            <h5 className="main-profile-name">Info Bank - {contract.name}</h5>
                                        </div>
                                    </div>
                                    <hr className="mg-y-10" />
                                    <div className="main-profile-social-list">
                                        <div className="media-tab">
                                            <Tabs className="nav nav-tabs profile navtab-custom panel-tabs" defaultActiveKey="home" transition={false} id="noanim-tab-example">
                                                <Tab className="border-left border-bottom border-right border-top-0 p-4" eventKey="home" title="Bank Company">
                                                    <div dangerouslySetInnerHTML={{__html: contract.bankCompany}} />
                                                </Tab>
                                                <Tab className="border-left border-bottom border-right border-top-0 p-4" eventKey="contact" title="Bank Individual" >
                                                    <div dangerouslySetInnerHTML={{__html: contract.bankIndividual}} />
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <Tabs className="nav nav-tabs block-pdf-content navtab-custom panel-tabs" defaultActiveKey="contentPdf0" transition={false} id="noanim-tab-example">
                                {contract.arrContractDetails.map((infoContact, index) => (
                                    <Tab key={index} className="border-left border-bottom border-right border-top-0 p-4" eventKey={`contentPdf${index}`} title={infoContact.name}>
                                        <div className="streamline user-activity">
                                            <div dangerouslySetInnerHTML={{__html: `<object data="${apiUrlLink+infoContact.urlFile}" type="application/pdf" width="100%" height="900px"><p>Unable to display PDF file. <a href="${apiUrlLink+infoContact.urlFile}">Download</a> instead.</p></object>`}} />
                                        </div>
                                    </Tab>
                                ))}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="page is-expanded">
                <div className="app-content mg-t-30">
                    <div className="container">
                        {body !== null ? body : null}
                    </div>
                </div>
            </div>
        </Fragment>
    )

    
}

export default ContractDetail