import { Fragment } from 'react'
import ListPromotionMail from './ListPromotionMail'

const PromotionMail = () => {
    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="pull-left">List Promotion Mail</h3>
                            </div>
                        </div>
                    </div>
                    <ListPromotionMail />
                </div>
            </div>
        </Fragment>
    )
}

export default PromotionMail