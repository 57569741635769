import { Fragment } from 'react'

const Operating = () => {
    return (
        <Fragment>
            <div className='horizontal-mainwrapper container-fluid clearfix'>
                <div className='explain-dashboard mt-5'>
                    <div className="operating-booking-cruise dashboard-table">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="table-info-cruise mg-b-20">
                                    <div className="table-info-cruise-body">
                                        <div className="table-responsive">
                                            <table id="operating-request" className="table table-striped table-bordered text-center mt-2 mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th className="th-style" rowSpan="2">Day</th>
                                                        <th className="th-style" rowSpan="2">Tour Code</th>
                                                        <th className="th-style" rowSpan="2">Date</th>
                                                        <th className="th-style" rowSpan="2">City</th>
                                                        <th className="th-style" rowSpan="2">Type</th>
                                                        <th className="th-style" rowSpan="2">Room</th>
                                                        <th className="th-style" rowSpan="2">Nite</th>
                                                        <th colSpan="2">Date</th>
                                                        <th className="th-style" rowSpan="2">Receive</th>
                                                        <th className="th-style" rowSpan="2">Confirmed by</th>
                                                        <th colSpan="2">Service Cost</th>
                                                        <th colSpan="3">Service Payment</th>
                                                        <th className="th-style" rowSpan="2">Payment</th>
                                                        <th className="th-style" rowSpan="2"><strong>Notes</strong></th>
                                                    </tr>
                                                    <tr>
                                                        <th>In</th>
                                                        <th>Out</th>
                                                        <th>VND</th>
                                                        <th>USD</th>
                                                        <th>Bank Account</th>
                                                        <th>Cash</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>3</td>
                                                        <td>4</td>
                                                        <td>5</td>
                                                        <td>6</td>
                                                        <td>7</td>
                                                        <td>8</td>
                                                        <td>9</td>
                                                        <td>10</td>
                                                        <td>11</td>
                                                        <td>12</td>
                                                        <td>13</td>
                                                        <td>14</td>
                                                        <td>15</td>
                                                        <td>16</td>
                                                        <td>17</td>
                                                        <td>18</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Operating