import { Fragment, useState, useEffect } from 'react';
import { apiUrl } from '../../../contexts/constants';
import axios from 'axios';
import Moment from 'moment';
import ShowMarketing from './ShowMarketing';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const MailMarketing = () => {

    const [mailMarketings, setMailMarketings] = useState([])
    const [dataMarketing, setDataMarketing] = useState(null)
    const [showMarketing, setShowMarketing] = useState(false)
    const handleClose = () => setShowMarketing(false)
    const handleShow = () => setShowMarketing(true)

    // Start Get all countries , []
    useEffect(() => {
        async function getDataMails(){
            try {
                const response = await axios.get(`${apiUrl}/mail-marketing`)
                if(response){
                    setMailMarketings(response.data.marketings)
                }
            } catch (error) {
                return error ? error : { success: false, message: 'Server error!' }
            }
        }
        getDataMails()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const chooseMarketing = marketingId => {
        mailMarketings.map((marketing) => {
            if(marketing.id === marketingId){
                setDataMarketing(marketing)
            }
        })
        handleShow()
    }

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="pull-left">Send Mail List</h3>
                            </div>
                        </div>
                    </div>
                    {mailMarketings.length > 0 && (
                        <div className="ajax-booking-cruise">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="table-info-cruise mg-b-20">
                                        <div className="table-info-cruise-body">
                                            <div className="table-responsive">
                                                <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>STT</th>
                                                            <th>Name</th>
                                                            <th>Subject</th>
                                                            <th>Country</th>
                                                            <th>Sales</th>
                                                            <th>Time Send</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {mailMarketings.map((marketing, index) => (
                                                            <tr key={index}>
                                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                                <td style={ {lineHeight: "38px"} }>{marketing.name}</td>
                                                                <td style={ {lineHeight: "38px"} }>{marketing.subject}</td>
                                                                <td style={ {lineHeight: "38px"} }>
                                                                    {JSON.parse(marketing.nameCountry).map((country, index) => {
                                                                        if(index === 0){
                                                                            return (
                                                                                country
                                                                            )
                                                                        }else{
                                                                            return (
                                                                                `, ${country}`
                                                                            )
                                                                        }
                                                                    })}
                                                                </td>
                                                                <td style={ {lineHeight: "38px"} }>{marketing.nameSales}</td>
                                                                <td style={ {lineHeight: "38px"} }>{Moment(marketing.created_at).format('MMMM D, YYYY hh:mm A')}</td>
                                                                <td>
                                                                    <div className="row">
                                                                        <div className="col-sm">
                                                                            <button className="btn btn-primary btn-with-icon btn-block" onClick={chooseMarketing.bind(this, marketing.id)}><i className="fe fe-book-open"></i> View</button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {dataMarketing !== null && <ShowMarketing dataMarketing={dataMarketing} showMarketing={showMarketing} handleClose={handleClose} />}
                </div>
            </div>
        </Fragment>
    )
}

export default MailMarketing
