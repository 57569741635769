import { Fragment, useContext, useEffect } from 'react';
import { CarContext } from '../../contexts/CarContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Spinner, Card} from 'react-bootstrap';
import UpdateCar from './UpdateCar';
import DeleteModal from '../../components/layout/DeleteModal';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListCar = () => {

    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)
    const { 
        carState: { car, cars, carsLoading },
        getCars,
        findCar,
        deleteCar,
        setShowUpdateCar
    } = useContext(CarContext)

    const chooseCar = carId => {
        findCar(carId)
        setShowUpdateCar(true)
    }

    // Start: Get all xe , []
    useEffect( () => getCars(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(carsLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(cars === null || cars.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a Car
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Seats</th>
                                                <th>Amount</th>
                                                <th>Destination</th>
                                                <th>Code Car</th>
                                                <th className="w-220">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cars.map((car, index) => (
                                            <tr key={index}>
                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                <td style={ {lineHeight: "38px"} }>{car.name}</td>
                                                <td style={ {lineHeight: "38px"} }>{car.seats}</td>
                                                <td style={ {lineHeight: "38px"} }>{car.amount}</td>
                                                <td style={ {lineHeight: "38px"} }>{car.destinationName}</td>
                                                <td style={ {lineHeight: "38px"} }>{car.codeCar}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            <button className="btn btn-success btn-with-icon btn-block" onClick={chooseCar.bind(this, car.id)}><i className="fe fe-edit"></i> Edit</button>
                                                        </div>
                                                        <div className="col-sm">
                                                            <DeleteModal idProps={car.id} deleteFunc={deleteCar} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <Fragment>
            {body}
            {car !== null && <UpdateCar />}
        </Fragment>
    )
}

export default ListCar
