import { Fragment, useState } from 'react';
import ItemAddService from './ItemAddService';
import { Form, Row, Col } from "react-bootstrap";

const ListAddService = ( { arrServices, setArrServices, setTotal, numberKey, newInvoice, setNewInvoice, discount, onChangeValue, description, amount } ) => {

    const [allTotal, setAllTotal] = useState(0)
    const [arrTotal, setArrTotal] = useState([])
    const [itemRadio, setItemRadio] = useState("money");
    //const [discount, setDiscount] = useState(0);

    Array(numberKey).fill(1).map((option, index) => {
        if (!arrTotal[index]) {
            arrTotal[index] = [];
        }
    })

    

    const itemTotal = (total, item) => {
        if(total > 0){
            arrTotal[item].push(total)
            totalFnc(arrTotal)
        }
    }

    const totalFnc = (arrTotal) => {
        var totalAll = 0
        // eslint-disable-next-line array-callback-return
        arrTotal.map((item) => {
            if(item[item.length -1] !== undefined){
                totalAll += Number(item[item.length -1])
            }
        })
        setAllTotal(totalAll)
        setTotal(totalAll)
        if( itemRadio === "money" ){
            setNewInvoice( {...newInvoice, amount: (totalAll - discount) } )
        }else{
            setNewInvoice( {...newInvoice, amount: Number((totalAll - ((discount/100)*totalAll)).toFixed(2)) } )
        }
    }

    //console.log('arrServices', arrServices)

    const handleChange = e => {
        e.persist();
        setNewInvoice( {...newInvoice, checkDiscount: e.target.value } )
        setItemRadio(e.target.value);
    };

    return (
        <Fragment>
            <div className="table-responsive mg-t-40"> 
                <table className="table table-invoice border text-md-nowrap mb-0"> 
                    <thead>
                        <tr>
                            <th className="wd-20p">Service Name</th>
                            <th className="wd-40p">Description</th>
                            <th className="tx-center">QNTY</th>
                            <th className="tx-right">Unit Price</th>
                            <th className="tx-right">Amount</th>
                        </tr> 
                    </thead> 
                    <tbody>
                        {
                            Array(numberKey).fill(1).map((option, index) => (
                                <ItemAddService key={index} arrServices={arrServices} setArrServices={setArrServices} keyItem={index} itemTotal={itemTotal} />
                                )
                            )
                        }
                        <tr>
                            <td className="valign-middle" colSpan="2" rowSpan="4">
                                <div className="invoice-notes"> 
                                    <label className="main-content-label tx-13">Notes</label> 
                                    <textarea className="form-control" id="description" placeholder="Note Service" name='description' value={description} onChange={onChangeValue} rows="4" spellCheck="false"></textarea>
                                </div>
                            </td>
                            <td className="tx-right">Sub-Total</td>
                            <td className="tx-right" colSpan="2">${allTotal}</td>
                        </tr>
                        <tr>
                            <td className="tx-right">Online Payment Fee (3%)</td>
                            <td className="tx-right" colSpan="2">
                                $
                                { itemRadio === "money" ? 
                                    Number((allTotal - discount)*0.03).toFixed(2) : Number((allTotal - ((discount/100)*allTotal))*0.03).toFixed(2)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="tx-right">Discount</td>
                            <td className="tx-right custom-discount" colSpan="2">
                                <Row>
                                    <Col xs={4}>
                                        <Form.Group controlId="kindOfStand">
                                            <Form.Check
                                                id="money"
                                                value="money"
                                                type="radio"
                                                aria-label="radio 1"
                                                label="No.Money"
                                                onChange={handleChange}
                                                checked={itemRadio === "money"}
                                            />
                                            <Form.Check
                                                value="percent"
                                                type="radio"
                                                aria-label="radio 2"
                                                label="Percent (%)"
                                                onChange={handleChange}
                                                checked={itemRadio === "percent"}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <input type="text" className="form-control" id="discount" name={`discount`} value={discount} onChange={onChangeValue} placeholder={ itemRadio === "percent" ? "Percent (%)" : "Money" }  />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td className="tx-right tx-uppercase tx-bold tx-inverse">Total Due</td>
                            <td className="tx-right" colSpan="2"> 
                                <h4 className="tx-primary tx-bold">
                                    { itemRadio === "money" ? 
                                        `$${allTotal - discount}` : `$${Number((allTotal - ((discount/100)*allTotal)).toFixed(2))}`
                                    }
                                </h4> 
                            </td>
                        </tr> 
                    </tbody>
                </table> 
            </div>
        </Fragment>
    )
}

export default ListAddService