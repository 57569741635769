import { Fragment, useContext, useState, useEffect } from 'react';
import { ServiceContext } from '../../contexts/ServiceContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { CountryContext } from '../../contexts/CountryContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';

const AddService = () => {

    // Context
    const {showAddService, setAddService, addService, setShowToast} = useContext(ServiceContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    // State
    const [newService, setNewService] = useState({
        name: '',
        codeService: '',
        amount: '',
        detail: ''
    })

    const { name, codeService, amount, detail } = newService

    const onChangeValue = event => setNewService( {...newService, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setNewService( {...newService, [event.target.name]: Number(event.target.value) } )

    // Start: Get all data countries, []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const typeServices = [
        { value: 'meal', label: 'Meal' },
        { value: 'car', label: 'Car'},
        { value: 'ticket', label: 'Ticket'},
        { value: 'service', label: 'Service'}
    ]
    
    let optionCountries = []
    let optionDestinations = []

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.id,
                    label: country.location,
                }
            ]
        )
    }

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setNewService( {...newService, destination: dataValue } )
            }
            if(actionMeta.name === "country"){
                setNewService( {...newService, country: dataValue } )
            }
            //typeService
            if(actionMeta.name === "typeService"){
                setNewService( {...newService, typeService: dataValue } )
            }
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        //console.log('newGuide', newGuide)
        const {success, message} = await addService(newService)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    

    const closeDialog = () => {
        setNewService({
            name: '',
            codeService: '',
            amount: '',
            detail: ''
        })
        setAddService(false)
    }


    return (
        <Fragment>
            <Modal show={showAddService} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Service
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col md={{ span: 9 }}>
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Service Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Type Service</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={typeServices}
                                        menuPosition={'fixed'}
                                        placeholder="Select Type"
                                        name="typeService"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Destination</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="destination"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Country</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionCountries} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Country"
                                        name="country"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Amount</strong></label>
                                    <input type="number" className="form-control" id="amount" name="amount" placeholder="No. Amount" value={amount} onChange={onChangeNumber} required aria-describedby='phone-help' />
                                    <Form.Text id='phone-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Code Service</strong></label>
                                    <input type="text" className="form-control" id="codeService" name="codeService" placeholder="No. Surcharge" value={codeService} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Detail</strong></label>
                            <textarea className="form-control" placeholder="Detail or Note Service" rows="5" name='detail' value={detail} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddService