import { Fragment } from 'react'
import { Image } from 'react-bootstrap'
import '../../../assets/css/template.css'


const DefaultTemplate = () => {

    const imgLogo = 'https://asiaprivateholidays.com/images/image-default/logo.png'

    return (
        <Fragment>
            
            <div>
                <table align="center" border="0" cellPadding="0" cellSpacing="0" style={{backgroundColor: '#fff', width: '650px', margin: '0 auto'}}>
                    <tbody>
                        <tr>
                            <td>
                                <table align="center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                                    <tr className="header">
                                        <td align="left" valign='top'>
                                            <Image width={110} height={43} src={imgLogo} className="main-logo" />
                                        </td>
                                        <td className="menu" align="right">
                                            <ul>
                                                <li><a href="https://asialinkstravel.com/" title="Home">Home</a></li>
                                                <li><a href="https://asialinkstravel.com/tour?country=1" title="Vietnam Tours" >Vietnam</a></li>
                                                <li><a href="https://asialinkstravel.com/tour?country=3" title="Vietnam Tours" >Cambodia</a></li>
                                                <li><a href="https://asialinkstravel.com/tour?country=2" title="Vietnam Tours" >Laos</a></li>
                                                <li><a href="https://asialinkstravel.com/tour?country=4" title="Vietnam Tours" >Myanmar</a></li>
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className="banner">
                                <table>
                                    <tr>
                                        <td colSpan="2"><img style={{width: '100%'}} src="https://asiaprivateholidays.com/images/image-default/banner6.jpg" alt="banner" /></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className="section-t" style={{position: 'relative', padding: '10px 45px'}}>
                                <table style={{width: '100%'}}>
                                    <tr>
                                        <td>
                                            <p className="pera">Dear City Break UK,</p>
                                            <p className="pera">We are pleased to inform you that Vietnam and Cambodia are fully opened to welcome international visitors.</p>
                                            <p className="pera">Currently, the Covid-19 pandemic situation in Vietnam has been completely controlled and people's lives as well as economic and entertainment activities have been fully reopened.</p>
                                            <p className="pera">At this moment, the number of visitors to Vietnam and Cambodia is constantly increasing every day, and direct flights from Europe, Asia, Australia, America have also been reopened almost as fully as before the pandemic.</p>
                                            <p className="pera">Asia Links Travel Company with more than 10 years of experience in organizing package tours in Indochina (Vietnam, Cambodia, Laos, Myanmar) for tourists from European, American and Asian countries is very willing to serve
                                                your customers.</p>
                                            <p className="pera">Please contact us at email address: <br /><span className="link-detail">admin@asialinkstravel.com</span> or our hotline/WhatsApp: <span className="link-detail">+84915441100</span> for advice and receive the latest products for
                                                the 2022 - 2023 travel season. We look forward to continuing to receive the trust and fruitful cooperation from you.</p>
                                            <p className="pera" style={{margin: '15px 0', fontStyle: 'italic'}}>Best regards,</p>
                                            <p className="pera"><b>Tuan Nguyen (Mr.)</b></p>
                                            <p className="pera">Founder &amp; Vice Managing Director</p>
                                            <p className="pera">Headphone/WhatsApp: <span className="link-detail">+84 915 44 11 00</span></p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className="section-t" style={{position: 'relative', padding: '0 calc(28px + (188 - 28) * ((100vw - 320px) / (1920 - 320)))', textAlign: 'center'}}>
                                <table style={{width: '100%'}}>
                                    <tr>
                                        <td>
                                            <h1 className="heading-1 font-2" style={{marginBottom: 'calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)))'}}>Suggested Tours</h1>
                                            <p className="pera" style={{textAlign: 'center'}}>Some tours you might like.</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" style={{padding: '0 calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)))'}}>
                                <table className="product-card" style={{width: '100%'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{width: '33%'}} colSpan="1">
                                                <a href="#">
                                                    <img src="https://asiaprivateholidays.com/images/image-default/9.jpg" alt="product" />
                                                    <h5 className="font-2">Southern Vietnam Holiday</h5>
                                                </a>
                                            </td>
                                            <td style={{width: '33%'}} colSpan="1">
                                                <a href="#">
                                                    <img src="https://asiaprivateholidays.com/images/image-default/10.jpg" alt="product" />
                                                    <h5 className="font-2">Wellness Retreat Vietnam</h5>
                                                </a>
                                            </td>
                                            <td style={{width: '33%'}} colSpan="1">
                                                <a href="#">
                                                    <img src="https://asiaprivateholidays.com/images/image-default/11.jpg" alt="product" />
                                                    <h5 className="font-2">Vintage Vietnam</h5>
                                                </a>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{width: '33%'}} colSpan="1">
                                                <a href="#">
                                                    <img src="https://asiaprivateholidays.com/images/image-default/12.jpg" alt="product" />
                                                    <h5 className="font-2">Vietnam Highland Explorer</h5>
                                                </a>
                                            </td>
                                            <td style={{width: '33%'}} colSpan="1">
                                                <a href="#">
                                                    <img src="https://asiaprivateholidays.com/images/image-default/13.jpg" alt="product" />
                                                    <h5 className="font-2">Charms of Vietnam &amp; Cambodia</h5>
                                                </a>
                                            </td>
                                            <td style={{width: '33%'}} colSpan="1">
                                                <a href="#">
                                                    <img src="https://asiaprivateholidays.com/images/image-default/14.jpg" alt="product" />
                                                    <h5 className="font-2">Immerse in the Nature</h5>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className="section-t">
                                <a href="https://asialinkstravel.com/tour?country=1" className="button-solid" title="See All Tours">See All Tours</a>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2" className="section-t" style={{backgroundColor: '#212121', padding: 'calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320))) 0'}}>
                                <table className="footer" style={{backgroundColor: '#212121'}}>
                                    <tr style={{backgroundColor: '#212121'}}>
                                        <td className="footer-content" style={{backgroundColor: '#212121'}}>
                                            <table style={{backgroundColor: '#212121', margin: '0 auto', verticalAlign: 'middle', width: '326px'}} border="0" cellPadding="0" cellSpacing="0" className="footer-social-icon text-center" align="center">
                                                <tr className="social" style={{backgroundColor: '#212121'}}>
                                                    <td style={{backgroundColor: '#212121'}}>
                                                        <a href="#"><img src="https://asiaprivateholidays.com/images/image-default/fb.png" alt="fb" /></a>
                                                    </td>
                                                    <td style={{backgroundColor: '#212121'}}>
                                                        <a href="#"><img src="https://asiaprivateholidays.com/images/image-default/insta.png" alt="insta" /></a>
                                                    </td>
                                                    <td style={{backgroundColor: '#212121'}}>
                                                        <a href="#"><img src="https://asiaprivateholidays.com/images/image-default/twitter-w.png" alt="twitter" /></a>
                                                    </td>
                                                    <td style={{backgroundColor: '#212121'}}>
                                                        <a href="#"><img src="https://asiaprivateholidays.com/images/image-default/pinterest-w.png" alt="pinterest" /></a>
                                                    </td>
                                                </tr>
                                                <tr style={{backgroundColor: '#212121'}}>
                                                    <td style={{backgroundColor: '#212121'}}>
                                                        <p><a style={{color: '#ffffff'}} href="https://asialinkstravel.com/" title="ASIA LINKS TRAVEL">ASIA LINKS TRAVEL</a></p>
                                                        <p style={{marginTop: 0, marginBottom: 0}}>Your trusted DMC for Vietnam &amp; Indochina</p>
                                                        <p style={{marginTop: 0, marginBottom: 0}}>Email: admin@asialinkstravel.com</p>
                                                        <p style={{marginTop: 0, marginBottom: 0}}>Hanoi Office: No. 349 Hoang Hoa Tham str, Ba Dinh dist, Hanoi, Vietnam</p>
                                                        <p style={{marginTop: 0, marginBottom: 0}}>Myanmar Office: 26/A, Thirizayar Str, Thingangyun, Yangon, Myanmar</p>
                                                        <p style={{marginTop: 0, marginBottom: 0}}>Laos Office: Ban Mano. Manomai RD, Luang Prabang, Laos</p>
                                                        <p style={{marginTop: 0, marginBottom: 0}}>Cambodia Office: #131 Group 4, Traing Village, Sangkat Slorkram, Siem Reap, Cambodia</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </Fragment>
    )
}

export default DefaultTemplate
