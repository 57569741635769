import { Fragment, useContext, useState, useEffect } from 'react';
import { GuideContext } from '../../contexts/GuideContext';
import { CountryContext } from '../../contexts/CountryContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';

const AddGuide = () => {

    // Context
    const { showAddGuide, setAddGuide, addGuide, setShowToast } = useContext(GuideContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    // State
    const [newGuide, setNewGuide] = useState({
        name: '',
        codeGuide: '',
        amount: '',
        surcharge: '',
        duration: '',
        language: '',
        detail: ''
    })

    const { name, codeGuide, amount, surcharge, language, detail } = newGuide

    const onChangeValue = event => setNewGuide( {...newGuide, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setNewGuide( {...newGuide, [event.target.name]: Number(event.target.value) } )

    // Start: Get all data countries, []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionCountries = []
    let optionDestinations = []
    let optionDurations = []

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.id,
                    label: country.location,
                }
            ]
        )
    }

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    for (var i = 1; i < 32; i++) {
        optionDurations = [ ...optionDurations,
            {
                value: i,
                label: i + ' Day',
            }
        ]
    }

    optionDurations = [{ value: 0.5, label: '1/2 Day' }].concat(optionDurations);

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setNewGuide( {...newGuide, destination: dataValue } )
            }
            if(actionMeta.name === "country"){
                setNewGuide( {...newGuide, country: dataValue } )
            }
            if(actionMeta.name === "duration"){
                setNewGuide( {...newGuide, duration: dataValue } )
            }
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        //console.log('newGuide', newGuide)
        const {success, message} = await addGuide(newGuide)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    

    const closeDialog = () => {
        setNewGuide({
            name: '',
            codeGuide: '',
            amount: '',
            surcharge: '',
            language: '',
            detail: ''
        })
        setAddGuide(false)
    }


    return (
        <Fragment>
            <Modal show={showAddGuide} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Guide
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Route Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Code Guide</strong></label>
                                    <input type="text" className="form-control" id="codeGuide" name="codeGuide" placeholder="Guide Code" value={codeGuide} onChange={onChangeValue} required aria-describedby='code-help' />
                                    <Form.Text id='code-help' muted>Required</Form.Text>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Destination</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="destination"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Country</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionCountries} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Country"
                                        name="country"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Amount</strong></label>
                                    <input type="number" className="form-control" id="amount" name="amount" placeholder="No. Amount" value={amount} onChange={onChangeNumber} required aria-describedby='phone-help' />
                                    <Form.Text id='phone-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Surcharge</strong></label>
                                    <input type="number" className="form-control" id="surcharge" name="surcharge" placeholder="No. Surcharge" value={surcharge} onChange={onChangeNumber} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Duration</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionDurations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Duration"
                                        name="duration"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Language</strong></label>
                                    <input type="text" className="form-control" id="language" name="language" placeholder="English" value={language} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Detail</strong></label>
                            <textarea className="form-control" placeholder="Detail Route" rows="5" name='detail' value={detail} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
    
}

export default AddGuide