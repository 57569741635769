import { Fragment, useContext, useState, useEffect } from 'react';
import { AccommodationContext } from '../../contexts/AccommodationContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { CountryContext } from '../../contexts/CountryContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import ListRoom from './ListRoom';

const AddAccommodation = () => {

    // Context
    const {
        showAddAccommodation, 
        setAddAccommodation, 
        addAccommodation, 
        setShowToast
    } = useContext(AccommodationContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    const [numberKey, setNumberKey] = useState(0);

    // State
    const [newAccommodation, setNewAccommodation] = useState({
        name: '',
        codeAccom: '',
        hotline: '',
        email: '',
        address: '',
        numberBank: '',
        nameBank: '',
        detail: '',
        websiteLink: '',
        fromLowSeason: '',
        toLowSeason: '',
        fromHighSeason: '',
        toHighSeason: ''
    })

    const { name, codeAccom, hotline, email, address, numberBank, nameBank, detail, fromLowSeason, toLowSeason, fromHighSeason, toHighSeason, websiteLink } = newAccommodation

    const onChangeValue = event => setNewAccommodation( {...newAccommodation, [event.target.name]: event.target.value } )

    // Start: Get all data countries, []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionCountries = []
    let optionDestinations = []
    let arrayPrices = []

    const arrayStars = [
        { value: '2', label: '2 stars' },
        { value: '2.5', label: '2 stars+' },
        { value: '3', label: '3 stars' },
        { value: '3.5', label: '3 stars+' },
        { value: '4', label: '4 stars' },
        { value: '4.5', label: '4 stars+' },
        { value: '5', label: '5 stars' },
        { value: '5.5', label: '5 stars+' },
        { value: '6', label: '6 stars' },
        { value: '6.5', label: '6 stars+' },
        { value: '7', label: '7 stars' },
    ]

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.id,
                    label: country.location,
                }
            ]
        )
    }

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setNewAccommodation( {...newAccommodation, destination: dataValue } )
            }
            if(actionMeta.name === "country"){
                setNewAccommodation( {...newAccommodation, country: dataValue } )
            }
            //star
            if(actionMeta.name === "star"){
                setNewAccommodation( {...newAccommodation, star: dataValue } )
            }
        }
    }

    let textPrice = '';

    const onSubmit = async event => {
        event.preventDefault()
        if(arrayPrices.length > 0){
            arrayPrices.map((item, i) => {
                if(i === 0){
                    textPrice = item;
                }else{
                    textPrice = textPrice + '$' + item;
                }
            })
        }

        newAccommodation['price'] = textPrice;
        const {success, message} = await addAccommodation(newAccommodation)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setNewAccommodation({
            name: '',
            codeAccom: '',
            hotline: '',
            email: '',
            address: '',
            numberBank: '',
            nameBank: '',
            detail: '',
            websiteLink: '',
            fromLowSeason: '',
            toLowSeason: '',
            fromHighSeason: '',
            toHighSeason: ''
        })
        setAddAccommodation(false)
    }

    const addButtonRoom = (index) => {
        setNumberKey(index + 1)
    }

    //console.log('numberKey', numberKey)

    return (
        <Fragment>
            <Modal show={showAddAccommodation} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Accommodation
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Accommodation Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Destination</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionDestinations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Destination"
                                                name="destination"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Country</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionCountries} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Country"
                                                name="country"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Code Accommodation</strong></label>
                                            <input type="text" className="form-control" id="codeAccom" name="codeAccom" placeholder="Code Accommodation" value={codeAccom} onChange={onChangeValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Star</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={arrayStars}
                                        menuPosition={'fixed'}
                                        placeholder="Select Star"
                                        name="star"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Hotline</strong></label>
                                    <input type="text" className="form-control" id="hotline" name="hotline" placeholder="No. Hotline" value={hotline} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Email</strong></label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter Address Email" value={email} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Address</strong></label>
                                    <input type="text" className="form-control" id="address" name="address" placeholder="Enter Address" value={address} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Bank Name</strong></label>
                                    <input type="text" className="form-control" id="nameBank" name="nameBank" placeholder="Enter Bank Name" value={nameBank} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Bank Number</strong></label>
                                    <input type="text" className="form-control" id="numberBank" name="numberBank" placeholder="No. Bank Number" value={numberBank} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Website</strong></label>
                                    <input type="text" className="form-control" id="websiteLink" name="websiteLink" placeholder="Enter Website" value={websiteLink} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Low Season</strong></label>
                                    <Row>
                                        <Col>
                                            <label><strong>From</strong></label>
                                            <input type="date" className="form-control" id="fromLowSeason" name="fromLowSeason" value={fromLowSeason} onChange={onChangeValue} />
                                        </Col>
                                        <Col>
                                            <label><strong>To</strong></label>
                                            <input type="date" className="form-control" id="toLowSeason" name="toLowSeason" value={toLowSeason} onChange={onChangeValue} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <label><strong>High Season</strong></label>
                                    <Row>
                                        <Col>
                                            <label><strong>From</strong></label>
                                            <input type="date" className="form-control" id="fromHighSeason" name="fromHighSeason" value={fromHighSeason} onChange={onChangeValue} />
                                        </Col>
                                        <Col>
                                            <label><strong>To</strong></label>
                                            <input type="date" className="form-control" id="toHighSeason" name="toHighSeason" value={toHighSeason} onChange={onChangeValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group d-inline-block w-100">
                            <Button className="float-right btn btn-primary btn-with-icon" variant='primary' onClick={addButtonRoom.bind(this, numberKey)}><i className="fe fe-plus-circle"></i> Add Room</Button>
                        </div>
                        {numberKey > 0 ? (
                            <ListRoom numberKey={numberKey} arrayPrices={arrayPrices} />
                        ) : ''}
                        <div className="form-group">
                            <label><strong>Detail</strong></label>
                            <textarea className="form-control" placeholder="Detail or Note Accommodation" rows="5" name='detail' value={detail} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddAccommodation