import { Fragment, useContext, useState, useEffect } from 'react';
import { CustomerContext } from '../../contexts/CustomerContext';
import { CountryContext } from '../../contexts/CountryContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';

const UpdateCustomer = () => {

    // Context
    const {
        customerState: { customer },
        showUpdateCustomer, 
        setShowUpdateCustomer, 
        updateCustomer, 
        setShowToast
    } = useContext(CustomerContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    const { authState: { users }, getUsers } = useContext(AuthContext)

    const [updatedCustomer, setUpdatedCustomer] = useState(customer)

    useEffect( () => setUpdatedCustomer(customer), [customer] )

    const { name, birthday, email, phone, codeTour, tourName, arrival } = updatedCustomer

    const onChangeValue = event => setUpdatedCustomer( {...updatedCustomer, [event.target.name]: event.target.value } )

    let optionCountries = []
    let optionSales = []


    // Start: Get all data , []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data users, []
    useEffect( () => getUsers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.id,
                    label: country.location,
                }
            ]
        )
    }

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    const onSubmit = async event => {
        event.preventDefault()
        //console.log('newCustomer', newCustomer)
        //updatedCustomer['sales'] = 
        const {success, message} = await updateCustomer(updatedCustomer)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const handleChange = (newValue, actionMeta) => {
        const data = newValue.value;
        if(actionMeta.name === "country"){
            setUpdatedCustomer( {...updatedCustomer, country: data } )
        }
        if(actionMeta.name === "sales"){
            setUpdatedCustomer( {...updatedCustomer, sales: data } )
        }
    }

    const closeDialog = () => {
        setUpdatedCustomer(customer)
        setShowUpdateCustomer(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdateCustomer} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Customer
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <label><strong>Name</strong></label>
                            <input type="text" className="form-control" id="name" name="name" placeholder="Enter Name Customer" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                            <Form.Text id='name-help' muted>Required</Form.Text>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Birthday</strong></label>
                                    <input type="date" className="form-control" id="birthday" name="birthday" value={birthday} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Country</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        defaultValue={
                                            optionCountries.filter(option => 
                                                option.value === customer.country ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionCountries}
                                        menuPosition={'fixed'}
                                        placeholder="Select Country"
                                        name="country"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Email</strong></label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder='example@asiaprivateholidays.com' value={email} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Phone</strong></label>
                                    <input type="tel" className="form-control" id="phone" name="phone" placeholder='+84 987 975 514' value={phone} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name Tour</strong></label>
                                    <input type="text" className="form-control" id="tourName" name="tourName" placeholder='Enter Name Tour' value={tourName} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Code Tour</strong></label>
                                    <input type="text" className="form-control" id="codeTour" name="codeTour" placeholder='Enter Code Tour' value={codeTour} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Arrival</strong></label>
                                    <input type="date" className="form-control" id="arrival" name="arrival" value={arrival} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Sales</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        defaultValue={
                                            optionSales.filter(option => 
                                                option.value === customer.sales ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionSales}
                                        menuPosition={'fixed'}
                                        placeholder="Select Sales"
                                        name="sales"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateCustomer