import { Fragment } from 'react'
import ItemCopyFlight from './ItemCopyFlight'

const ListCopyFlight = ( { numberKey, arrFlights, setArrFlights, totalFlight, onChangeNumber } ) => {
    return (
        <Fragment>
            <div className="form-group">
                <div className="table-responsive mg-t-40">
                    <table className="table table-invoice border text-md-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Flight</th>
                                <th>TIMETABLE (From - To)</th>
                                <th>DURATION</th>
                            </tr> 
                        </thead> 
                        <tbody>
                            {
                                Array(numberKey).fill(1).map((option, index) => (
                                    <ItemCopyFlight key={index} keyItem={index} arrFlights={arrFlights} setArrFlights={setArrFlights} flight={arrFlights[index]} />
                                ))
                            }
                            <tr>
                                <td style={ {lineHeight: "38px"} } colSpan="2"><strong>Total Flight</strong></td>
                                <td>
                                    <input type="number" className="form-control" id="totalFlight" name="totalFlight" value={totalFlight} placeholder="No. Price" onChange={onChangeNumber} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default ListCopyFlight