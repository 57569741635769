import { Fragment, useContext, useEffect } from 'react';
import { TodoListContext } from '../../contexts/TodoListContext';
import Moment from 'moment';
import { apiUrlLink } from '../../contexts/constants';
import UpdatedTodoList from './UpdatedTodoList';

const TodoList = () => {

    const { 
        todoListState: { todoList, todoLists },
        getTodoLists,
        findTodoList,
        setShowUpdateTodoList
    } = useContext(TodoListContext)

    // Start: Get all data users, []
    useEffect( () => getTodoLists(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const chooseTodo = todoId => {
        findTodoList(todoId)
        setShowUpdateTodoList(true)
    }

    //console.log('todoLists', todoLists)

    return (
        <Fragment>
            <div className="table-responsive country-table">
                <table className="table table-striped table-bordered mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap"> 
                    <thead>
                        <tr>
                            <th className="">STT</th>
                            <th className="tx-right">Name</th>
                            <th className="tx-right">Priority</th>
                            <th className="tx-right">Due</th>
                            <th className="tx-center"><strong><i className='si si-user'></i></strong></th>
                            <th className="tx-right">Action</th>
                        </tr> 
                    </thead> 
                    <tbody>
                        {todoLists.map((todo, index) => {
                            const dataImage = apiUrlLink + '/uploads/users/images/' + todo.infoSales.images;
                            return (
                                <tr key={todo.id}>
                                    <td>{index+1}</td>
                                    <td className="tx-right tx-medium tx-inverse">{todo.name}</td>
                                    <td className={`tx-right tx-medium tx-inverse`}><strong className={`tx-${todo.priority}`}>{todo.priority}</strong></td>
                                    <td className="tx-right tx-medium tx-inverse">{Moment(todo.dueTo).format('DD/MM/YYYY')}</td>
                                    <td className="tx-center tx-medium tx-inverse">
                                        <img style={{borderRadius: '50%'}} width={25} alt={todo.infoSales.name} src={dataImage} />
                                    </td>
                                    <td className="tx-right tx-medium tx-danger">
                                        <button className="btn btn-success btn-with-icon btn-block" onClick={chooseTodo.bind(this, todo.id)}><i className="fe fe-edit"></i></button>
                                    </td>
                                </tr>
                            )})}
                    </tbody>
                </table> 
            </div>
            {todoList !== null && <UpdatedTodoList />}
        </Fragment>
    )
}

export default TodoList
