import { Fragment, useContext, useState, useEffect } from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { CarContext } from '../../contexts/CarContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import Select from 'react-select';

const UpdateCar = () => {

    // Context
    const {
        carState: { car },
        showUpdateCar, 
        setShowUpdateCar, 
        updateCar, 
        setShowToast
    } = useContext(CarContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    let optionDestinations = []

    // Start: Get all data , []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const [updatedCar, setUpdatedCar] = useState(car)

    useEffect( () => setUpdatedCar(car), [car] )

    const {name, amount, seats, codeCar, detail} = updatedCar


    const onChangeValue = event => setUpdatedCar( {...updatedCar, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setUpdatedCar( {...updatedCar, [event.target.name]: Number(event.target.value) } )

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    const onSubmit = async event => {
        event.preventDefault()
        const {success, message} = await updateCar(updatedCar)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const handleChange = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            setUpdatedCar( {...updatedCar, destination: dataValue } )
        }else{
            setUpdatedCar( {...updatedCar, destination: "" } )
        }
    }

    const closeDialog = () => {
        setUpdatedCar(car)
        setShowUpdateCar(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdateCar} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Car
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <label><strong>Name</strong></label>
                            <input type="text" className="form-control" id="name" name="name" placeholder="Enter Name Car" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                            <Form.Text id='name-help' muted>Required</Form.Text>
                        </div>

                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>No. Seats</strong></label>
                                    <input type="number" className="form-control" id="seats" name="seats" placeholder="No. Seats" value={seats} onChange={onChangeNumber} />
                                </Col>
                                <Col>
                                    <label><strong>Amount</strong></label>
                                    <input type="number" className="form-control" id="amount" name="amount" placeholder="No. Amount" value={amount} onChange={onChangeNumber} required aria-describedby='phone-help' />
                                    <Form.Text id='phone-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Code Car</strong></label>
                                    <input type="text" className="form-control" id="codeCar" name="codeCar" placeholder="Enter Code Car" value={codeCar} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Destination</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        defaultValue={
                                            optionDestinations.filter(option => 
                                                option.value === car.destination ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="destination"
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            <label><strong>Detail</strong></label>
                            <textarea className="form-control" placeholder="Enter Detail" rows="5" name='detail' value={detail} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateCar
