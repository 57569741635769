import { createContext, useState, useReducer } from 'react';
import { invoiceReducer } from '../reducers/invoiceReducer';
import { apiUrl, INVOICES_LOADED_FAIL, INVOICES_LOADED_SUCCESS, FIND_INVOICE, UPDATE_INVOICE, DELETE_INVOICE, ADD_INVOICE, SEARCH_INVOICE } from './constants';
import axios from 'axios';


export const InvoiceContext = createContext()

const InvoiceContextProvider = ({ children }) => {

    // State
    const [invoiceState, dispatch] = useReducer(invoiceReducer, {
        invoice: null,
        invoices: [],
        invoicesLoading: true
    })

    const [showAddInvoice, setAddInvoice] = useState(false)
    const [showUpdateInvoice, setShowUpdateInvoice] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Invoices
    const getInvoices = async() => {
        try {
            const response = await axios.get(`${apiUrl}/invoices`)
            if (response.data.success) {
                dispatch({ type: INVOICES_LOADED_SUCCESS, payload: response.data.invoices })
            }
        } catch (error) {
            dispatch({ type: INVOICES_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Invoice
    const addInvoice = async newInvoice => {
        try {
            const response = await axios.post(`${apiUrl}/invoices`, newInvoice)
            if (response.data.success) {
                dispatch({
                    type: ADD_INVOICE,
                    payload: response.data.invoice
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Invoice when user is updating Invoice
    const findInvoice = invoiceId => {
        const invoice = invoiceState.invoices.find(invoice => invoice.id === invoiceId)
        dispatch({
            type: FIND_INVOICE,
            payload: invoice
        })
    }

    // Search Invoice
    const searchInvoice = async invoiceId => {
        try {
            const response = await axios.get(`${apiUrl}/invoices/${invoiceId}`)
            if (response.data.success) {
                dispatch({
                    type: SEARCH_INVOICE,
                    payload: response.data.invoice
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // update Invoice
    const updateInvoice = async updateInvoice => {
        try {
            const response = await axios.put(`${apiUrl}/invoices/${updateInvoice.id}`, updateInvoice)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_INVOICE,
                    payload: response.data.invoice
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Invoice
    const deleteInvoice = async invoiceId => {
        try {
            const response = await axios.delete(`${apiUrl}/invoices/${invoiceId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_INVOICE,
                    payload: invoiceId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Invoice Context Data
    const invoiceContextData = {
        invoiceState,
        getInvoices,
        addInvoice,
        findInvoice,
        searchInvoice,
        updateInvoice,
        deleteInvoice,
        showToast,
        setShowToast,
        showAddInvoice,
        setAddInvoice,
        showUpdateInvoice,
        setShowUpdateInvoice
    }

    return ( 
        <InvoiceContext.Provider value = { invoiceContextData } > 
            { children } 
        </InvoiceContext.Provider>
    )

}

export default InvoiceContextProvider