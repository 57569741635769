import { CUSTOMERS_LOADED_FAIL, CUSTOMERS_LOADED_SUCCESS, FIND_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER, ADD_CUSTOMER } from '../contexts/constants';

export const customerReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case CUSTOMERS_LOADED_SUCCESS:
            return {
                ...state,
                customers: payload,
                customersLoading: false
            }
        case CUSTOMERS_LOADED_FAIL:
            return {
                ...state,
                customers: [],
                customersLoading: false
            }
        case ADD_CUSTOMER:
            {
                return {
                    ...state,
                    customers: [...state.customers, payload],
                }
            }
        case FIND_CUSTOMER:
            return {
                ...state,
                customer: payload
            }
        case UPDATE_CUSTOMER:
            const newCustomer = state.customers.map(customer => {
                if (customer.id === payload.id) {
                    return payload
                } else {
                    return customer
                }
            })
            return {
                ...state,
                customers: newCustomer
            }
        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.filter(customer => customer.id !== payload)
            }
        default:
            return state
    }
}