import { Fragment, useContext, useState, useEffect } from 'react';
import { RequestContext } from '../../contexts/RequestContext';
import { CountryContext } from '../../contexts/CountryContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';

const AddRequest = () => {

    // Context
    const { 
        showAddRequest, 
        setAddRequest, 
        addRequest, 
        setShowToast 
    } = useContext(RequestContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { authState: { users }, getUsers } = useContext(AuthContext)

    // State
    const [newRequest, setNewRequest] = useState({
        mrName: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        messages: '',
        email: '',
        species: '',
        tourClass: '',
        please: '1',
        iam: '1',
        adult: '',
        child: '',
        infant: '',
        tourName: '',
        status: '',
        duration: '',
        ip_client: '127.0.0.1',
        socialNetwork: 'Google Search',
        tourDate: '',
        route: '',
        sales: ''
    })

    const { firstName, lastName, phone, messages, email, tourName, tourDate } = newRequest

    const onChangeValue = event => setNewRequest( {...newRequest, [event.target.name]: event.target.value } )

    // Start: Get all data countries, []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data users, []
    useEffect( () => getUsers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('users', users)

    let optionCountries = []
    let optionDurations = []
    let optionSales = []
    let optionDestinations = []
    let optionNumbers = []

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.location,
                    label: country.location,
                }
            ]
        )
    }

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.name,
                    label: destination.name,
                }
            ]
        )
    }

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    for (var i = 1; i < 32; i++) {
        optionDurations = [ ...optionDurations,
            {
                value: i,
                label: i + ' Day',
            }
        ]
    }

    for (var j = 1; j < 32; j++) {
        optionNumbers = [ ...optionNumbers,
            {
                value: j,
                label: j + ' Pax',
            }
        ]
    }

    const optionTitles = [
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
        { value: 'Miss.', label: 'Miss.' }
    ]

    const optionStatus = [
        { value: 'new', label: 'New' },
        { value: 'dealing', label: 'Dealing' },
        { value: 'success', label: 'Success' },
        { value: 'run', label: 'Running' },
        { value: 'complete', label: 'Complete' },
        { value: 'fall', label: 'Fail' }
    ]

    const optionSpecies = [
        { value: 'contact', label: 'Contact' },
        { value: 'mytrip', label: 'Mytrip' },
        { value: 'booking', label: 'Booking' }
    ]

    const optionHotels = [
        { value: 'Local homestay: From $20-$30/r/n', label: 'Local homestay: From $20-$30/r/n' },
        { value: '3 Stars: From $40-$60/r/n', label: '3 Stars: From $40-$60/r/n' },
        { value: '4 Stars: From $70-$110/r/n', label: '4 Stars: From $70-$110/r/n' },
        { value: '5 Stars: From $120-$190/r/n', label: '5 Stars: From $120-$190/r/n' }
    ]

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "country"){
                setNewRequest( {...newRequest, country: dataValue } )
            }
            if(actionMeta.name === "duration"){
                setNewRequest( {...newRequest, duration: dataValue } )
            }
            if(actionMeta.name === "status"){
                setNewRequest( {...newRequest, status: dataValue } )
            }
            if(actionMeta.name === "sales"){
                setNewRequest( {...newRequest, sales: dataValue } )
            }
            if(actionMeta.name === "adult"){
                setNewRequest( {...newRequest, adult: dataValue } )
            }
            if(actionMeta.name === "child"){
                setNewRequest( {...newRequest, child: dataValue } )
            }
            if(actionMeta.name === "infant"){
                setNewRequest( {...newRequest, infant: dataValue } )
            }
            if(actionMeta.name === "title"){
                setNewRequest( {...newRequest, mrName: dataValue } )
            }
            if(actionMeta.name === "species"){
                setNewRequest( {...newRequest, species: dataValue } )
            }
            if(actionMeta.name === "title"){
                setNewRequest( {...newRequest, mrName: dataValue } )
            }
            if(actionMeta.name === "hotels"){
                setNewRequest( {...newRequest, tourClass: dataValue } )
            }
        }
    }

    const handleChangeDestinations = (newValue, actionMeta) => {
        const arrayDestinations = newValue;
        let textRoute = "";
        if(arrayDestinations){
            arrayDestinations.map((itemDestination, index) => {
                if(index === 0){
                    textRoute = itemDestination.value;
                }else{
                    textRoute = textRoute + ',' + itemDestination.value;
                }
            })
            setNewRequest( {...newRequest, route: textRoute } )
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        const {success, message} = await addRequest(newRequest)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
        if(success === true){
            window.location.reload()
        }
    }

    const closeDialog = () => {
        setNewRequest({
            mrName: '',
            firstName: '',
            lastName: '',
            phone: '',
            country: '',
            messages: '',
            email: '',
            species: '',
            tourClass: '',
            please: '1',
            iam: '1',
            adult: '',
            child: '',
            infant: '',
            tourName: '',
            status: '',
            duration: '',
            ip_client: '127.0.0.1',
            socialNetwork: 'Google Search',
            tourDate: '',
            route: '',
            sales: ''
        })
        setAddRequest(false)
    }

    return (
        <Fragment>
            <Modal show={showAddRequest} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Request
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Tour Name</strong></label>
                                    <input type="text" className="form-control" id="tourName" name="tourName" placeholder="Tour Name" value={tourName} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Sales</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionSales} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Sales"
                                                name="sales"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Status</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionStatus} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Status"
                                                name="status"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Duration</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionDurations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Duration"
                                                name="duration"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Tour Route</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeDestinations.bind(this)}
                                        options={optionDestinations} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Tour Route"
                                        name="route"
                                        isMulti
                                    />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Tour Date</strong></label>
                                            <input type="date" className="form-control" id="tourDate" name="tourDate" placeholder="MMMM DD YYYY" value={tourDate}  onChange={onChangeValue} required aria-describedby='tour-date' />
                                            <Form.Text id='tour-date' muted>Required</Form.Text>
                                        </Col>
                                        <Col>
                                            <label><strong>Country</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionCountries} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Country"
                                                name="country"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>{`Adult ( > 12)`}</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionNumbers} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Adult"
                                        name="adult"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>{`Child (4 -> 12)`}</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionNumbers} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Child"
                                        name="child"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>{`Infant (0 - 3)`}</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionNumbers} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Infant"
                                        name="infant"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col xs={2}>
                                    <label><strong>{`Title`}</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionTitles} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Title"
                                        name="title"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>First Name</strong></label>
                                    <input type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name" value={firstName} onChange={onChangeValue}  required aria-describedby='first-help' />
                                    <Form.Text id='first-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Last Name</strong></label>
                                    <input type="text" className="form-control" id="lastName" name="lastName" value={lastName} onChange={onChangeValue} placeholder="Last Name" required aria-describedby='last-help' />
                                    <Form.Text id='last-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Email</strong></label>
                                    <input type="email" className="form-control" id="email" name="email" value={email} onChange={onChangeValue} placeholder="Enter Email" required aria-describedby='email-help' />
                                    <Form.Text id='email-help' muted>Required</Form.Text>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Phone</strong></label>
                                    <input type="text" className="form-control" id="phone" name="phone" value={phone} placeholder="No. Phone" onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Species</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionSpecies} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Specie"
                                        name="species"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Hotels</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionHotels} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Hotel"
                                        name="hotels"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Messages</strong></label>
                            <textarea className="form-control" placeholder="Messages" rows="5" id='messages' name='messages' value={messages} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>    
        </Fragment>
    )
}

export default AddRequest