import { Fragment, useContext, useEffect, useState } from 'react';
import { ItineraryContext } from '../../../contexts/ItineraryContext';
import { AccommodationContext } from '../../../contexts/AccommodationContext';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const ItemCopyDurationItinerary = ( { keyItem, arrItineraries, setArrItineraries, itemTotal, adult, child, infant, arrival, detailItinerary } ) => {
    
    const [chooseDefault, setChooseDefault] = useState(null)
    const [chooseAccommodation, setChooseAccommodation] = useState(null)
    const [defaultPriceAdult, setDefaultPriceAdult] = useState(0)
    const [defaultPriceChild, setDefaultPriceChild] = useState(0)
    const [defaultPriceInfant, setDefaultPriceInfant] = useState(0)
    const [defaultPriceExtra, setDefaultPriceExtra] = useState(0)
    const [defaultNameRoom, setDefaultNameRoom] = useState(null)
    const [checkDay, setCheckDay] = useState(0)
    const [arrRoomInfo, setArrRoomInfo] = useState([])

    const { 
        itineraryState: { itineraries },
        getItineraries 
    } = useContext(ItineraryContext)

    const { 
        accommodationState: { accommodations },
        getAccommodations 
    } = useContext(AccommodationContext)

    // Start: Get all data destination, []
    useEffect( () => getItineraries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getAccommodations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(detailItinerary !== null && detailItinerary !== undefined && accommodations.length > 0 && itineraries.length > 0){
            itineraries.map((itinerary) => {
                if(itinerary.id === detailItinerary.itinerary){
                    setChooseDefault(itinerary)
                }
            })

            accommodations.map((accommodation) => {
                if(accommodation.id === detailItinerary.accommodation){
                    setChooseAccommodation(accommodation)
                    setArrRoomInfo(accommodation.price.split("$"))
                    if(arrival >= accommodation.fromLowSeason && accommodation.toLowSeason >= arrival ) {
                        setCheckDay(1)
                    }
                    if(arrival >= accommodation.fromHighSeason && accommodation.toHighSeason >= arrival ) {
                        setCheckDay(2)
                    }
                }
            })

            const priceDefaultRoom = detailItinerary.infoRoom.split("|")
            //console.log('priceDefaultRoom', priceDefaultRoom)
            setDefaultNameRoom(priceDefaultRoom[0])
            setDefaultPriceAdult(priceDefaultRoom[1])
            setDefaultPriceChild(priceDefaultRoom[2])
            setDefaultPriceInfant(priceDefaultRoom[3])
            setDefaultPriceExtra(priceDefaultRoom[4])
            //setChooseDefault(detailItinerary.itinerary)
            //setChooseAccommodation(detailItinerary.accommodation)
            
        }
    }, [detailItinerary, accommodations, itineraries, arrival] )

    let optionDurations = []
    let optionItineraries = []
    let optionAccommodations = []

    for (var z = 1; z < 32; z++) {
        optionDurations = [ ...optionDurations,
            {
                value: z,
                label: z + ' Day',
            }
        ]
    }

    if(itineraries.length > 0){
        itineraries.map((itinerary) => 
            optionItineraries = [ ...optionItineraries, 
                {
                    value: itinerary.id,
                    label: itinerary.name,
                    itinerary: itinerary
                }
            ]
        )
    }

    if(accommodations.length > 0){
        accommodations.map((accommodation) => 
            optionAccommodations = [ ...optionAccommodations, 
                {
                    value: accommodation.id,
                    label: accommodation.name,
                    accommodation: accommodation
                }
            ]
        )
    }

    const handleChangeSelect = (index, newValue, actionMeta) => {
        //console.log('index', index)
        if (!arrItineraries[index]) {
            arrItineraries[index] = {};
        }
        const data = newValue;
        const fieldNames = actionMeta.name.split('-');
        const fieldName = fieldNames[0];
        arrItineraries[index][fieldName] =  data.value;
        
        if(fieldNames[0] === "itinerary"){
            setChooseDefault(data.itinerary)
        }
        if(fieldNames[0] === "accommodation"){
            setChooseAccommodation(data.accommodation)
            changeDefaultRoom(data.accommodation)
        }
    }

    const changeDefaultRoom = (accommodation) => {
        setArrRoomInfo(accommodation.price.split("$"))
        if(arrival >= accommodation.fromLowSeason && accommodation.toLowSeason >= arrival ) {
            setCheckDay(1)
        }
        if(arrival >= accommodation.fromHighSeason && accommodation.toHighSeason >= arrival ) {
            setCheckDay(2)
        }
    }

    const handleChange = (e, priceRoom, nameRoom, keyItem) => {
        e.persist();
        if (!arrItineraries[keyItem]) {
            arrItineraries[keyItem] = {};
        }
        setDefaultNameRoom(nameRoom);
        setDefaultPriceAdult(priceRoom[0]);
        setDefaultPriceChild(priceRoom[1]);
        setDefaultPriceInfant(priceRoom[2]);
        setDefaultPriceExtra(priceRoom[3]);
        arrItineraries[keyItem]['infoRoom'] =  nameRoom + '|' + priceRoom[0] + '|' + priceRoom[1] + '|' + priceRoom[2] + '|' + priceRoom[3];
        arrItineraries[keyItem]['totalLand'] = (priceRoom[0]*adult + priceRoom[1]*child + priceRoom[2]*infant) + (adult + child)*chooseDefault.totalService
        itemTotal((priceRoom[0]*adult + priceRoom[1]*child + priceRoom[2]*infant) + (adult + child)*chooseDefault.totalService, keyItem)
    };

    let body = null

    if(detailItinerary !== null && detailItinerary !== undefined){
        
        body = (
            <Fragment>
                <div className="form-group">
                    <h4>Day {keyItem + 1}</h4>
                    <Row>
                        <Col>
                            <label><strong>Itinerary</strong></label>
                            {optionItineraries.length > 0 ? (
                            <Select isClearable
                                onChange={handleChangeSelect.bind(this, keyItem)}
                                defaultValue={
                                    optionItineraries.filter(option => 
                                        option.value === detailItinerary.itinerary ? {label: option.label, value: option.value} : ''
                                    )
                                }
                                options={optionItineraries}
                                menuPosition={'fixed'}
                                placeholder="Select Itinerary"
                                name={`itinerary-${keyItem}`}
                            />
                            ) : null}
                        </Col>
                        <Col>
                            <label><strong>Accommodation</strong></label>
                            {optionAccommodations.length > 0 ? (
                            <Select isClearable
                                onChange={handleChangeSelect.bind(this, keyItem)}
                                defaultValue={
                                    optionAccommodations.filter(option => 
                                        option.value === detailItinerary.accommodation ? {label: option.label, value: option.value} : ''
                                    )
                                }
                                options={optionAccommodations}
                                menuPosition={'fixed'}
                                placeholder="Select Accommodation"
                                name={`accommodation-${keyItem}`}
                            />
                            ) : null}
                        </Col>
                    </Row>
                </div>
                <div className="form-group">
                    <Row>
                        {chooseDefault !== null ? (
                            <Col>
                                <div className="detail-info">
                                    <p><strong>Tour Name:</strong> {chooseDefault.name}</p>
                                    <p><strong>Destination:</strong> {chooseDefault.destinationName}</p>
                                    {chooseDefault.textService.length > 0 ? (
                                    <p>
                                        <strong>Service:</strong> {chooseDefault.textService.map((text, key) => {
                                            if(key === 0){
                                                return `${text.name}`
                                            }else{
                                                return `, ${text.name}`
                                            }
                                        })}
                                    </p>
                                    ) : null }
                                    <p><strong>Detail:</strong> <span dangerouslySetInnerHTML={{__html: chooseDefault.detail}} /></p>
                                    <p><strong>Group Size:</strong> {adult} Adult {child > 0 ? ', ' + child + ' Child' : null} {infant > 0 ? ', ' + infant + 'Infant' : null}</p>
                                    <p><strong>Price:</strong> ${chooseDefault.totalService}/pax</p>
                                    <p><strong>Total:</strong> ${child > 0 ? (adult + child)*chooseDefault.totalService : chooseDefault.totalService*adult }</p>
                                </div>
                            </Col>
                        ) : null }
                        {chooseAccommodation !== null ? (
                            <Col>
                                <div className="detail-info">
                                    <Form.Group controlId="kindOfStand">
                                        <Row>
                                            {arrRoomInfo.map((roomInfo, index) => {
                                                const arrRoom = roomInfo.split("#")
                                                const priceRoom = arrRoom[checkDay].split("|")
                                                if(checkDay > 0){
                                                    return (
                                                        <Col key={index}>
                                                            <Form.Check
                                                                id={`money${index}${keyItem}`}
                                                                value={`room${index+1}${keyItem}`}
                                                                name={`group${index+1}${keyItem}`}
                                                                type="radio"
                                                                aria-label={`radio ${index+1}${keyItem}`}
                                                                label={arrRoom[0]}
                                                                onChange={(e) => handleChange(e, priceRoom, arrRoom[0], keyItem)}
                                                                checked={arrRoom[0] === defaultNameRoom}
                                                            /> 
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Form.Group>
                                    {checkDay > 0 ? (
                                    <div className='info-room'>
                                        <p><strong>Room:</strong> {defaultNameRoom}</p>
                                        <p><strong>Adult:</strong> ${defaultPriceAdult}/pax</p>
                                        <p><strong>Child:</strong> ${defaultPriceChild}/pax</p>
                                        <p><strong>Infant:</strong> {defaultPriceInfant > 0 ? `$${defaultPriceInfant}` : 'Free'}</p>
                                        <p><strong>Group Size:</strong> {adult} Adult {child > 0 ? ', ' + child + ' Child' : null} {infant > 0 ? ', ' + infant + 'Infant' : null}</p>
                                        <p><strong>Total:</strong> ${defaultPriceAdult*adult + defaultPriceChild*child + defaultPriceInfant*infant}/pax</p>
                                    </div>
                                    ) : null}
                                </div>
                            </Col>
                        ) : null }
                    </Row>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {body !== null ? body : null}
        </Fragment>

    )

}

export default ItemCopyDurationItinerary