import { Fragment, useState, useEffect } from 'react';
import ItemCopyDurationItinerary from './ItemCopyDurationItinerary';

const ListCopyDurationItinerary = ( { numberDuration, arrItineraries, setArrItineraries, adult, child, infant, arrival, updatedFullItinerary, setUpdatedFullItinerary } ) => {

    //console.log('updatedFullItinerary', updatedFullItinerary)
    //const [totalLand, setTotalLand] = useState(0)
    const [allTotal, setAllTotal] = useState(0)
    const [arrTotal, setArrTotal] = useState([])

    useEffect( () => {
        if(updatedFullItinerary){
            setAllTotal(updatedFullItinerary.totalLand)
            setArrItineraries(JSON.parse(updatedFullItinerary.detailItinerary))
        }
    }, [updatedFullItinerary] ) // eslint-disable-line react-hooks/exhaustive-deps
    
    

    

    Array(numberDuration).fill(1).map((option, index) => {
        if (!arrTotal[index]) {
            arrTotal[index] = [];
        }
    })

    
    const itemTotal = (total, item) => {
        if(total > 0){
            arrTotal[item].push(total)
            totalFnc(arrTotal)
        }
    }

    const totalFnc = (arrTotal) => {
        var totalAll = 0
        // eslint-disable-next-line array-callback-return
        arrTotal.map((item) => {
            if(item[item.length -1] !== undefined){
                totalAll += Number(item[item.length -1])
            }
        })
        setAllTotal(totalAll)
        setUpdatedFullItinerary( {...updatedFullItinerary, totalLand: totalAll } )
    }

    //console.log('arrItineraries', arrItineraries)

    return (
        <Fragment>
            {
                Array(numberDuration).fill(1).map((option, index) => (
                    <ItemCopyDurationItinerary key={index} keyItem={index} arrItineraries={arrItineraries} setArrItineraries={setArrItineraries} itemTotal={itemTotal} adult={adult} child={child} infant={infant} arrival={arrival} detailItinerary={arrItineraries[index]} />
                    )
                )
            }
            <div className="form-group">
                <h3>Total Land tour: ${allTotal}</h3>
            </div>
        </Fragment>
    )
}

export default ListCopyDurationItinerary