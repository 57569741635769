import { Fragment, useContext, useState, useEffect } from 'react';
import { AccommodationContext } from '../../contexts/AccommodationContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { CountryContext } from '../../contexts/CountryContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import ListUpdateRoom from './ListUpdateRoom';

const UpdateAccommodation = () => {

    // Context
    const {
        accommodationState: { accommodation },
        showUpdateAccommodation, 
        setShowUpdateAccommodation, 
        updateAccommodation, 
        setShowToast
    } = useContext(AccommodationContext)

    const [numberKey, setNumberKey] = useState(0);
    const [arrayPrices, setArrayPrices] = useState([]);

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    const [updatedAccommodation, setUpdatedAccommodation] = useState(accommodation)

    useEffect( () => setUpdatedAccommodation(accommodation), [accommodation] )

    const { name, codeAccom, hotline, email, address, numberBank, nameBank, detail, websiteLink, fromLowSeason, toLowSeason, fromHighSeason, toHighSeason } = updatedAccommodation

    const onChangeValue = event => setUpdatedAccommodation( {...updatedAccommodation, [event.target.name]: event.target.value } )

    // Start: Get all data countries, []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionCountries = []
    let optionDestinations = []
    //let arrayPrices = []

    /*if(accommodation.price !== '' && accommodation.price !== null){
        arrayPrices = accommodation.price.split("$");
    }*/

    

    // Start: Get all data countries, []
    useEffect( () => {
        function setNumberKeyDefault(){
            //var arr
            setArrayPrices(accommodation.price.split("$"));
            setNumberKey(accommodation.price.split("$").length);
        }
        setNumberKeyDefault()
    }, [accommodation] ) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('numberKey', numberKey)

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.id,
                    label: country.location,
                }
            ]
        )
    }

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    const arrayStars = [
        { value: '2', label: '2 stars' },
        { value: '2.5', label: '2 stars+' },
        { value: '3', label: '3 stars' },
        { value: '3.5', label: '3 stars+' },
        { value: '4', label: '4 stars' },
        { value: '4.5', label: '4 stars+' },
        { value: '5', label: '5 stars' },
        { value: '5.5', label: '5 stars+' },
        { value: '6', label: '6 stars' },
        { value: '6.5', label: '6 stars+' },
        { value: '7', label: '7 stars' },
    ]

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setUpdatedAccommodation( {...updatedAccommodation, destination: dataValue } )
            }
            if(actionMeta.name === "country"){
                setUpdatedAccommodation( {...updatedAccommodation, country: dataValue } )
            }
            //star
            if(actionMeta.name === "star"){
                setUpdatedAccommodation( {...updatedAccommodation, star: dataValue } )
            }
        }
    }

    let textPrice = '';

    const onSubmit = async event => {
        event.preventDefault()
        if(arrayPrices.length > 0){
            arrayPrices.map((item, i) => {
                if(i === 0){
                    textPrice = item;
                }else{
                    textPrice = textPrice + '$' + item;
                }
            })
        }

        updatedAccommodation['price'] = textPrice;
        //console.log('updatedAccommodation', updatedAccommodation)
        const {success, message} = await updateAccommodation(updatedAccommodation)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setUpdatedAccommodation(accommodation)
        setShowUpdateAccommodation(false)
    }

    const addButtonRoom = (index) => {
        setNumberKey(index + 1)
    }

    return (
        <Fragment>
            <Modal show={showUpdateAccommodation} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Accommodation
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Accommodation Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Destination</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                defaultValue={
                                                    optionDestinations.filter(option => 
                                                        option.value === accommodation.destination ? {label: option.label, value: option.value} : ''
                                                    )
                                                }
                                                options={optionDestinations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Destination"
                                                name="destination"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Country</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                defaultValue={
                                                    optionCountries.filter(option => 
                                                        option.value === accommodation.country ? {label: option.label, value: option.value} : ''
                                                    )
                                                }
                                                options={optionCountries} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Country"
                                                name="country"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Code Accommodation</strong></label>
                                            <input type="text" className="form-control" id="codeAccom" name="codeAccom" placeholder="Code Accommodation" value={codeAccom} onChange={onChangeValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Star</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            arrayStars.filter(option => 
                                                parseFloat(option.value) === parseFloat(accommodation.star) ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={arrayStars}
                                        menuPosition={'fixed'}
                                        placeholder="Select Star"
                                        name="star"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Hotline</strong></label>
                                    <input type="text" className="form-control" id="hotline" name="hotline" placeholder="No. Hotline" value={hotline} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Email</strong></label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter Address Email" value={email} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Address</strong></label>
                                    <input type="text" className="form-control" id="address" name="address" placeholder="Enter Address" value={address} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Bank Name</strong></label>
                                    <input type="text" className="form-control" id="nameBank" name="nameBank" placeholder="Enter Bank Name" value={nameBank} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Bank Number</strong></label>
                                    <input type="text" className="form-control" id="numberBank" name="numberBank" placeholder="No. Bank Number" value={numberBank} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Website</strong></label>
                                    <input type="text" className="form-control" id="websiteLink" name="websiteLink" placeholder="Enter Website" value={websiteLink} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Low Season</strong></label>
                                    <Row>
                                        <Col>
                                            <label><strong>From</strong></label>
                                            <input type="date" className="form-control" id="fromLowSeason" name="fromLowSeason" value={fromLowSeason !== null ? fromLowSeason : ''} onChange={onChangeValue} />
                                        </Col>
                                        <Col>
                                            <label><strong>To</strong></label>
                                            <input type="date" className="form-control" id="toLowSeason" name="toLowSeason" value={toLowSeason !== null ? toLowSeason : ''} onChange={onChangeValue} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <label><strong>High Season</strong></label>
                                    <Row>
                                        <Col>
                                            <label><strong>From</strong></label>
                                            <input type="date" className="form-control" id="fromHighSeason" name="fromHighSeason" value={fromHighSeason !== null ? fromHighSeason : ''} onChange={onChangeValue} />
                                        </Col>
                                        <Col>
                                            <label><strong>To</strong></label>
                                            <input type="date" className="form-control" id="toHighSeason" name="toHighSeason" value={toHighSeason !== null ? toHighSeason : ''} onChange={onChangeValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group d-inline-block w-100">
                            <Button className="float-right btn btn-primary btn-with-icon" variant='primary' onClick={addButtonRoom.bind(this, numberKey)}><i className="fe fe-plus-circle"></i> Add Room</Button>
                        </div>
                        {numberKey > 0 ? (
                            <ListUpdateRoom numberKey={numberKey} arrayPrices={arrayPrices} />
                        ) : ''}
                        <div className="form-group">
                            <label><strong>Detail</strong></label>
                            <textarea className="form-control" placeholder="Detail or Note Accommodation" rows="5" name='detail' value={detail} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateAccommodation
