import { Fragment } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import DefaultTemplate from '../templates/DefaultTemplate';

const ShowMarketing = ( { dataMarketing, showMarketing, handleClose } ) => {

    console.log('dataMarketing', dataMarketing)

    return (
        <Fragment>
            <Modal show={showMarketing} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Show Email Marketing
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>Name</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>{dataMarketing.name}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>Subject</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>{dataMarketing.subject}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>Sales</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>{dataMarketing.nameSales}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>List Partner</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>{dataMarketing.listName}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>List Email</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>{dataMarketing.listEmails}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>Countries</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>
                                {JSON.parse(dataMarketing.nameCountry).map((country, index) => {
                                    if(index === 0){
                                        return (
                                            country
                                        )
                                    }else{
                                        return (
                                            `, ${country}`
                                        )
                                    }
                                })}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <label><strong>Template</strong></label>
                        </div>
                        <div className='col-md-8'>
                            <p>Default Template</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultTemplate />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={handleClose}><i className="fe fe-x-circle"></i> Close</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default ShowMarketing
