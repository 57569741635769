import { Fragment, useContext, useState, useEffect } from 'react';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { ServiceContext } from '../../contexts/ServiceContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const UpdateItinerary = () => {

    //const [defaultServices, setDefaultServices] = useState([])
    //const [loadingData, setLoadingData] = useState(false)
    // Context
    const {
        itineraryState: { itinerary },
        showUpdateItinerary, 
        setShowUpdateItinerary, 
        updateItinerary, 
        setShowToast
    } = useContext(ItineraryContext)

    //const [isCheck, setIsCheck] = useState(false)

    const [updatedItinerary, setUpdatedItinerary] = useState(itinerary)
    useEffect( () => setUpdatedItinerary(itinerary), [itinerary])
    
    const { name, nameVN, detail, detailVN } = updatedItinerary

    //console.log('updatedItinerary', updatedItinerary)

    let defaultServices = []
    let defaultTotalService = 0
    if(itinerary.totalService > 0){
        defaultTotalService = itinerary.totalService
    }


    const [totalService, setTotalService] = useState(0)

    if(itinerary.services !== '' && itinerary.services !== null){
        defaultServices = itinerary.services.split(',').map(x=>+x)
    }

    useEffect( () => {
        setUpdatedItinerary(itinerary)
        if(defaultTotalService > 0){
            setTotalService(defaultTotalService)
        }else{
            setTotalService(0)
        }
    }, [itinerary, defaultTotalService])

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const {
        serviceState: { services },
        getServices
    } = useContext(ServiceContext)

    
    
    let optionServices = []
    let optionDestinations = []
    //let defaultServices = itinerary.services.split(',')

    // Start: Get all data , []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data service, []
    useEffect( () => getServices(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeValue = event => setUpdatedItinerary( {...updatedItinerary, [event.target.name]: event.target.value } )

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    if(services.length > 0){
        services.map((service) => 
            optionServices = [ ...optionServices, 
                {
                    value: service.id,
                    label: service.name,
                    price: service.amount
                }
            ]
        )
    }

    /*const optionMeals = [
        { value: 'N/A', label: 'N/A' },
        { value: 'breakfast', label: 'Breakfast' },
        { value: 'lunch', label: 'Lunch' },
        { value: 'brunch', label: 'Brunch' },
        { value: 'dinner', label: 'Dinner' }
    ]*/

    const handleChange = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setUpdatedItinerary( {...updatedItinerary, destination: dataValue } )
            }
        }
    }

    const handleChangeService = (newValue, actionMeta) => {
        const arrayServices = newValue;
        let textServices = "";
        let tong = 0;
        if(arrayServices.length > 0){
            arrayServices.map((service, index) => {
                if(index === 0){
                    textServices = service.value;
                    tong = service.price;
                }else{
                    textServices = textServices + ',' + service.value;
                    tong = tong + service.price;
                }
            })
            //setTotalService
            if(tong > 0){
                setTotalService(tong)
                setUpdatedItinerary( {...updatedItinerary, services: textServices } )
            }
            //console.log('textServices', tong) totalService

            //setNewItinerary( {...newItinerary, meal: textMeal } )
        }else{
            tong = 0;
            setTotalService(0)
            setUpdatedItinerary( {...updatedItinerary, services: null } )
        }
        
    }

    const onSubmit = async event => {
        event.preventDefault()
        updatedItinerary['totalService'] = totalService;
        //console.log('updatedItinerary', updatedItinerary)
        const {success, message} = await updateItinerary(updatedItinerary)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setUpdatedItinerary(itinerary)
        setShowUpdateItinerary(false)
        //setLoadingData(!loadingData)
    }

    let body = null

    if(optionDestinations.length > 0){
        body = (
            <Modal show={showUpdateItinerary} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Day by Day Itinerary
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name English</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Enter Itinerary Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Name VN</strong></label>
                                    <input type="text" className="form-control" id="nameVN" name="nameVN" placeholder="Enter Itinerary Name" value={nameVN} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Destination</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        defaultValue={
                                            optionDestinations.filter(option => 
                                                option.value === itinerary.destination ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="destination"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Services</strong></label>
                                    {defaultServices.length > 0 ? (
                                        <Select isClearable
                                            onChange={handleChangeService.bind(this)}
                                            defaultValue={
                                                optionServices.filter(option => 
                                                    defaultServices.indexOf(option.value) > -1 ? {label: option.label, value: option.value} : ''
                                                )
                                            }
                                            options={optionServices}
                                            menuPosition={'fixed'}
                                            placeholder="Select Service"
                                            name="service"
                                            isMulti
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    ) : (
                                        <Select isClearable
                                            onChange={handleChangeService.bind(this)}
                                            options={optionServices}
                                            menuPosition={'fixed'}
                                            placeholder="Select Service"
                                            name="service"
                                            isMulti
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    ) }
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Detail English</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={itinerary.detail ? itinerary.detail : null}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setUpdatedItinerary( {...updatedItinerary, detail: data } )
                                        } }
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Detail VN</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={itinerary.detailVN ? itinerary.detailVN : null}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setUpdatedItinerary( {...updatedItinerary, detailVN: data } )
                                        } }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <p><strong>Total:</strong> <span className="tx-primary tx-bold">${totalService > 0 ? Number(totalService).toFixed(2) : 0}</span></p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    return (
        <Fragment>
            {body !== null && body}
        </Fragment>
    )
}

export default UpdateItinerary