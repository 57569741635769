import { Fragment, useContext, useState, useEffect } from 'react';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import Moment from 'moment';
import ListUpdateService from './ListUpdateService';
import AddLinkPayment from './AddLinkPayment';

const UpdateInvoice = () => {

    // Context
    const { 
        invoiceState: { invoice },
        showUpdateInvoice, 
        setShowUpdateInvoice, 
        updateInvoice, 
        setShowToast 
    } = useContext(InvoiceContext)

    const [updatedInvoice, setUpdatedInvoice] = useState(invoice)
    const [checkLinkOrder, setCheckLinkOrder] = useState(0)
    const [checkOldPay, setCheckOldPay] = useState(0)

    useEffect( () => setUpdatedInvoice(invoice), [invoice] )

    const { description, amount, dateStart, dateEnd, discount, amountPay } = updatedInvoice

    const onChangeValue = event => setUpdatedInvoice( {...updatedInvoice, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setUpdatedInvoice( {...updatedInvoice, [event.target.name]: Number(event.target.value) } )

    useEffect( () => {
        if(invoice.linkPayments.length > 0){
            setCheckLinkOrder(invoice.linkPayments.length);
            setUpdatedInvoice( {...updatedInvoice, amountPay: invoice.linkPayments[0].amount } );
        }
    }, [] )

    //console.log('invoice', invoice.linkPayments)

    const defaultServices = JSON.parse(invoice.service);

    const [numberKey, setNumberKey] = useState(defaultServices.length);
    const [arrServices, setArrServices] = useState(defaultServices)
    const [numberPaymentKey, setNumberPaymentKey] = useState(0);
    const [arrLinkPayments, setArrLinkPayments] = useState(invoice.linkPayments);
    
    let optionNumbers = []
    let optionOldOrders = []
    let optionDurations = []
    //let checkLinkOrder = invoice.linkPayments.length

    for (var j = 1; j < 32; j++) {
        optionNumbers = [ ...optionNumbers,
            {
                value: j,
                label: j + ' Pax',
            }
        ]
    }

    for (var z = 1; z < 32; z++) {
        optionDurations = [ ...optionDurations,
            {
                value: z,
                label: z,
            }
        ]
    }

    // let newstr= str.charAt(0).toUpperCase() +   str.slice(1);
    let defaultPay = false;

    if(invoice.linkPayments.length > 0){
        // eslint-disable-next-line array-callback-return
        invoice.linkPayments.map((linkPayment, dem) => {
            var namePayment = linkPayment.orderPay.split('-')
            if(dem === 0){
                //isPay
                if(linkPayment.status === "pay"){
                    defaultPay = true
                }
            }
            optionOldOrders = [ ...optionOldOrders, 
                {
                    value: linkPayment.orderPay,
                    label: `${namePayment[0].charAt(0).toUpperCase() + namePayment[0].slice(1)} ${namePayment[1]}`,
                    payAmount: linkPayment.amount,
                }
            ]
        })
    }

    //console.log('optionOldOrders', optionOldOrders)
    //console.log('checkOldPay', checkOldPay)

    const [isPay, setIsPay] = useState(defaultPay);

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "adult"){
                //setIsValid(true)
                setUpdatedInvoice( {...updatedInvoice, adult: dataValue } )
            }
            if(actionMeta.name === "child"){
                setUpdatedInvoice( {...updatedInvoice, child: dataValue } )
            }
            if(actionMeta.name === "infant"){
                setUpdatedInvoice( {...updatedInvoice, infant: dataValue } )
            }
            if(actionMeta.name === "duration"){
                //setDurationValid(true)
                setUpdatedInvoice( {...updatedInvoice, duration: dataValue } )
                var result = new Date(dateStart);
                if(dataValue > 0){
                    result.setDate(result.getDate() + dataValue);
                }else{
                    result = dateStart;
                }
                setUpdatedInvoice( {...updatedInvoice, dateEnd: Moment(result).format('YYYY-MM-DD') } )
            }
            /*if(actionMeta.name === "order-pay"){
                setOrderValid(true);
                setUpdatedInvoice( {...updatedInvoice, orderPay: dataValue } );
                invoice.linkPayments.map((itemPayment) => {
                    if(itemPayment.orderPay === dataValue){
                        if(itemPayment.status === "pay"){
                            setIsPay(true) 
                        }
                    }
                })
            }*/
        }
    }

    const setTotal = (valueTotal) => {
        //setUpdatedInvoice( {...updatedInvoice, amount: valueTotal } )
    }

    const onSubmit = async event => {
        event.preventDefault();
        console.log('arrLinkPayments', arrLinkPayments)
        console.log('updatedInvoice', updatedInvoice);
        //const {success, message} = await updateInvoice(updatedInvoice);
        //this.props.history.push('/dashboard');
        //window.location.reload(true);
        //closeDialog();
        //setShowToast({show: true, message, type: success ? 'success' : 'danger'});
    }

    const addButtonService = (index) => {
        setNumberKey(index + 1)
    }

    const addButtonLinkPayment = (index) => {
        setNumberPaymentKey(index + 1)
    }

    //console.log('numberPaymentKey', numberPaymentKey)

    const closeDialog = () => {
        setUpdatedInvoice(invoice)
        setShowUpdateInvoice(false)
    }

    const onChangeValuePayment = (index, event) => {
        if (!arrLinkPayments[index]) {
            arrLinkPayments[index] = {};
        }
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        arrLinkPayments[index][fieldName] =  Number(event.target.value);
        //arrLinkPayments[index]['id'] =  null;
        if(fieldName === "amountPay"){
            setUpdatedInvoice( {...updatedInvoice, amountPay: Number(event.target.value) } );
        }
        
    }

    const handleChangeSelectPayment = (index, newValue) => {
        
        const checkIndex = newValue.value.split('-');
        
        console.log('checkIndex', checkIndex[1])
        setCheckOldPay(checkIndex[1]-1)
        const data = newValue;
        if(data){
            const dataValue = data.value
            //optionOldOrders payAmount
            optionOldOrders.filter(option => {
                if(option.value === data.value) {
                    setUpdatedInvoice( {...updatedInvoice, amountPay: data.payAmount } )
                }
            })
            arrLinkPayments[checkIndex[1]-1]['orderPay'] =  dataValue;
        }
    }
    

    return (
        <Fragment>
            <Modal show={showUpdateInvoice} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Invoice
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <div className='main-content-body-invoice'>
                        <div className='card card-invoice'>
                            <div className='card-body'>
                                <div className="invoice-header"> 
                                    <h1 className="invoice-title">Invoice</h1> 
                                    <div className="billed-from"> 
                                        <h6>Asia Links Travel, Jsc.</h6> 
                                        <p>
                                            <strong>Address:</strong> 25, Group 7, Tan Mai, Hoang Mai, Hanoi, Vietnam<br />
                                            <strong>Tel No:</strong> +84 987 975 514<br />
                                            <strong>Email:</strong> info@asiaprivateholidays.com
                                        </p>
                                    </div>
                                </div>
                                <div className="row mg-t-20"> 
                                    <div className="col-md"> 
                                        <label className="tx-gray-600"><strong>Billed To</strong></label> 
                                        <div className="billed-to"> 
                                            <h6>{invoice.mrName} {invoice.firstName} {invoice.lastName},</h6> 
                                            <p>
                                                <strong>Country:</strong> {invoice.country}<br />
                                                <strong>Tel No:</strong> {invoice.phone}<br />
                                                <strong>Email:</strong> {invoice.email}
                                            </p>
                                        </div> 
                                    </div> 
                                    <div className="col-md"> 
                                        <label className="tx-gray-600"><strong>Invoice Information</strong></label> 
                                        <p className="invoice-info-row"><span>Invoice No</span> <span>{invoice.invoiceCode}</span></p>
                                        <p className="invoice-info-row"><span>Project ID</span> <span>{invoice.projectCode}</span></p>
                                        <p className="invoice-info-row"><span>Tour Name</span> <span>{invoice.booking.tourName}</span></p>
                                        <p className="invoice-info-row"><span>Arrival Date:</span> <span>{Moment(dateStart).format('MMM DD, YYYY')}</span></p>
                                        <p className="invoice-info-row"><span>Departure Date:</span> <span>{Moment(dateEnd).format('MMM DD, YYYY')}</span></p>
                                    </div> 
                                </div>
                                <div className='period-trip'>
                                    <h6>Period of Trip</h6>
                                </div>
                                <Row className='mg-t-20'>
                                    <Col className="col-md">
                                        <Row>
                                            <Col>
                                                <label><strong>From</strong></label>
                                                <input type="date" className="form-control" id="dateStart" name="dateStart" value={dateStart} onChange={onChangeValue} required />
                                            </Col>
                                            <Col>
                                                <label><strong>To</strong></label>
                                                <input type="date" className="form-control" id="dateEnd" name="dateEnd" value={dateEnd} onChange={onChangeValue} required />
                                            </Col>
                                            <Col>
                                                <label><strong>Duration</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    defaultValue={
                                                        optionDurations.filter(option => 
                                                            option.value === invoice.duration ? {label: option.label, value: option.value} : ''
                                                        )
                                                    }
                                                    options={optionDurations} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Duration"
                                                    name="duration"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md">
                                        <Row>
                                            <Col>
                                                <label><strong>{`Adult ( > 12)`}</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    defaultValue={
                                                        optionNumbers.filter(option => 
                                                            option.value === invoice.adult ? {label: option.label, value: option.value} : ''
                                                        )
                                                    }
                                                    options={optionNumbers} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Adult"
                                                    name="adult"
                                                />
                                            </Col>
                                            <Col>
                                                <label><strong>{`Child (4 -> 12)`}</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    defaultValue={
                                                        optionNumbers.filter(option => 
                                                            option.value === invoice.child ? {label: option.label, value: option.value} : ''
                                                        )
                                                    }
                                                    options={optionNumbers} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Child"
                                                    name="child"
                                                />
                                            </Col>
                                            <Col>
                                                <label><strong>{`Infant (0 - 3)`}</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    defaultValue={
                                                        optionNumbers.filter(option => 
                                                            option.value === invoice.infant ? {label: option.label, value: option.value} : ''
                                                        )
                                                    }
                                                    options={optionNumbers} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Infant"
                                                    name="infant"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {numberKey > 0 ? (
                                    <ListUpdateService arrServices={arrServices} setArrServices={setArrServices} setTotal={setTotal} numberKey={numberKey} updatedInvoice={updatedInvoice} setUpdatedInvoice={setUpdatedInvoice} discount={discount} onChangeValue={onChangeValue} description={description} amount={amount} />
                                ) : ''}
                                <div className="form-group d-inline-block w-100">
                                    <Button className="float-right btn btn-success btn-with-icon mt-3" variant='success' onClick={addButtonService.bind(this, numberKey)}><i className="fe fe-plus-circle"></i> Add Service</Button>
                                </div>
                                <Row className='mg-t-20'>
                                    <Col md={{ span: 4, offset: 4 }}>
                                        <div className='info-transaction'>
                                            <h4 className='text-center'>Edit Link Payment</h4>
                                            <div className="form-group">
                                                <label><strong>Transaction OrderInfo</strong></label>
                                                <input type="text" className="form-control" id="vpc_OrderInfo" name="vpc_OrderInfo" defaultValue={`#${invoice.booking_id}`} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label><strong>Order Pay</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelectPayment.bind(this, checkOldPay)}
                                                    defaultValue={
                                                        optionOldOrders.filter(option => 
                                                            option.value === invoice.linkPayments[0].orderPay ? {label: option.label, value: option.value} : ''
                                                        )
                                                    }
                                                    options={optionOldOrders} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Order"
                                                    name="order-pay"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label><strong>Purchase Amount</strong></label>
                                                <input type="number" className="form-control" id={`amountPay-${checkOldPay}`} name={`amountPay-${checkOldPay}`} placeholder="No. Amount" onChange={onChangeValuePayment.bind(this, checkOldPay)} value={amountPay} readOnly={isPay === true ? true : false }  />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {numberPaymentKey > 0 ? (
                                    <AddLinkPayment numberPaymentKey={numberPaymentKey} invoice={invoice} optionOldOrders={optionOldOrders} arrLinkPayments={arrLinkPayments} setArrLinkPayments={setArrLinkPayments} checkLinkOrder={checkLinkOrder} />
                                ) : null }
                                <Row>
                                    <Col md={{ span: 4, offset: 4 }}>
                                        <div className="form-group d-inline-block w-100">
                                            <Button className="float-right btn btn-info btn-with-icon mt-3" variant='info' onClick={addButtonLinkPayment.bind(this, numberPaymentKey)} ><i className="fe fe-plus-circle"></i> Add Link Payment</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        {numberKey > 0 ? (
                            <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit' ><i className="fe fe-save"></i> Save!!!</Button>
                        ) : null}
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateInvoice