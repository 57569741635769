import { Fragment, useContext, useEffect } from 'react';
import { RequestContext } from '../../../contexts/RequestContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
//import Moment from 'moment';
import { Link } from 'react-router-dom';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { apiUrlLink } from '../../../contexts/constants';

const ListFails = () => {
    // Context
    const {
        authState: { user: {name}, users },
        getUsers
    } = useContext(AuthContext)

    const { 
        requestState: { booking, bookings, bookingsLoading },
        getRequests
    } = useContext(RequestContext)


    const getStatus = textStatus => {
        var textResult = '';
        if(textStatus === 'new'){
            textResult = 'label-danger';
        }
        if(textStatus === 'dealing'){
            textResult = 'label-info';
        }
        if(textStatus === 'success'){
            textResult = 'label-success';
        }
        if(textStatus === 'run'){
            textResult = 'label-info';
        }
        if(textStatus === 'complete'){
            textResult = 'label-primary';
        }
        if(textStatus === 'fall'){
            textResult = 'label-warning';
        }
        return textResult;
    }

    // Start: Get all staffs , []
    useEffect( () => getRequests(), [] ) // eslint-disable-line react-hooks/exhaustive-deps
    let body = null

    let dem = 0;

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(bookingsLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(bookings.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name},</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a request
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <Fragment>
                <div className="ajax-booking-cruise">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-info-cruise mg-b-20">
                                <div className="table-info-cruise-body">
                                    <div className="table-responsive">
                                        <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Code</th>
                                                    <th>Species</th>
                                                    <th>Tour Code</th>
                                                    <th>Email</th>
                                                    <th>Website</th>
                                                    <th>Sales</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bookings.map((booking, index) => {
                                                    if(booking.status === "fall"){
                                                        dem = dem + 1
                                                        return (
                                                            <tr key={index}>
                                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                                <td style={ {lineHeight: "38px"} }>#{booking.id}</td>
                                                                <td style={ {lineHeight: "38px"} }>
                                                                    {booking.typeBooking === 'cruise' ? 'Cruise' : booking.species}
                                                                </td>
                                                                <td style={ {lineHeight: "38px"} }>
                                                                    {booking.tourName}
                                                                </td>
                                                                <td style={ {lineHeight: "38px"} }><Link to={`/requests/${booking.id}`} title="Detail Request" target={'_blank'}>{booking.email}</Link> <span className={`label ${getStatus(booking.status)}`}>{booking.status}</span></td>
                                                                <td style={ {lineHeight: "38px"} }>
                                                                    {booking.websiteLink/*Moment(booking.created_at).format('MMM D, YYYY h:mm:ss')*/}
                                                                </td>
                                                                <td className='image-sale'>
                                                                    { booking.sale !== '' && booking.sale !== null ? <img className="avatar-member" src={`${apiUrlLink}/uploads/users/images/thumb_img/50/${booking.sale.images}`} alt={booking.sale.name} /> : null }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {body}
        </Fragment>
    )
}

export default ListFails