import { Fragment, useContext, useEffect } from 'react';
import { PartnerContext } from '../../contexts/PartnerContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdatePartner from './UpdatePartner';
import DeleteModal from '../../components/layout/DeleteModal';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListPartner = () => {

    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)
    const { 
        partnerState: { partner, partners, partnersLoading },
        getPartners,
        findPartner,
        deletePartner,
        setShowUpdatePartner
    } = useContext(PartnerContext)

    const choosePartner = partnerId => {
        findPartner(partnerId)
        setShowUpdatePartner(true)
    }

    // Start: Get all Itineraries , []
    useEffect( () => getPartners(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-partners').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(partnersLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(partners.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a Partner
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-partners" className="table table-striped mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Type</th>
                                                <th>Destination</th>
                                                <th className="w-220">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partners.map((partner, index) => (
                                            <tr key={index}>
                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                <td style={ {lineHeight: "38px"} }>{partner.name}</td>
                                                <td style={ {lineHeight: "38px"} }>{partner.phone}</td>
                                                <td style={ {lineHeight: "38px"} }>{partner.type_partners}</td>
                                                <td style={ {lineHeight: "38px"} }>{partner.destinationName}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            <button className="btn btn-success btn-with-icon btn-block" onClick={choosePartner.bind(this, partner.id)}><i className="fe fe-edit"></i> Edit</button>
                                                        </div>
                                                        <div className="col-sm">
                                                            <DeleteModal idProps={partner.id} deleteFunc={deletePartner} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body}
            {partner !== null && <UpdatePartner />}
        </Fragment>
    )
}

export default ListPartner