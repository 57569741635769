import { Fragment, useContext, useEffect } from 'react';
import { FullItineraryContext } from '../../contexts/FullItineraryContext';
import Moment from 'moment';
import { useParams } from 'react-router-dom';
//import logoAsiaLinks from '../../assets/img/logo_asia_links_travel.png';
import { Link } from 'react-router-dom';
//import { Document, Font, Image, Page, PDFDownloadLink, Text, View } from '@react-pdf/renderer';
//import ItineraryTableHeader from './pdfForder/ItineraryTableHeader';
import html2Pdf from 'html2pdf.js';
//import imageToBase64 from 'image-to-base64/browser';
//import HtmlToDocx from 'html-docx';

const DetailItinerary = () => {

    const { 
        fullItineraryState: { fullItinerary },
        searchFullItinerary
    } = useContext(FullItineraryContext)

    const { id } = useParams()

    // Start: Get all staffs , []
    useEffect( () => searchFullItinerary(id), [id] ) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('fullItinerary', fullItinerary)

    const convertString = (stringTexts) => {
        
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"    
        ];

        for (var i=0; i<AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            stringTexts = stringTexts.replace(re, char);
        }

       // console.log('stringTexts', stringTexts)

        stringTexts = stringTexts.split(' ');
        var resultText = "";

        stringTexts.map((itemText, dem) => { 
            if(dem === 0){
                resultText = itemText;
            }else{
                resultText = resultText + '-' + itemText;
            }
        })
        return resultText.toLocaleLowerCase()
    }

    const exportPDF = () => {

        let element = document.getElementById('html-docx');

        let nameFile = convertString(fullItinerary.customerName) + '-' + convertString(Moment(fullItinerary.arrival).format('Do MMM YYYY')) + '-' + convertString(fullItinerary.sale.name) + '.pdf';
        let opt = {
            margin:       0.5,
            filename:     `${nameFile}`,
            image:        { type: 'jpeg', quality: 1 },
            html2canvas:  { scale: 2, logging: true, dpi: 192 },
            pageBreak:    { mode: 'avoid-all' },
            jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        //console.log('html2Pdf().set(opt).from(element)', html2Pdf().set(opt).from(element))

        html2Pdf().set(opt).from(element).save();
        
        // Old monolithic-style usage:
        //html2pdf(element, opt);  
    }

    const docExport = () => {
        const nameFile = convertString(fullItinerary.customerName) + '-' + convertString(Moment(fullItinerary.arrival).format('Do MMM YYYY')) + '-' + convertString(fullItinerary.sale.name);
        Export2Word('html-docx', nameFile)
    }

    

    const Export2Word = (element, filename = '') => {
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title><style>@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}div.WordSection1 {page: WordSection1;}table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}</style></head><body>";
        var postHtml = "</body></html>";
        var html = preHtml+document.getElementById(element).innerHTML+postHtml;
    
        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });

        // Specify link url
        //var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

        var url = URL.createObjectURL(blob);
        
        // Specify file name
        filename = filename?filename+'.doc':'document.doc';
        
        // Create download link element
        var downloadLink = document.createElement("a");
    
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob ){
            navigator.msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;
            
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
        }
        
        document.body.removeChild(downloadLink);
    }

    

    let body = null
    

    if(fullItinerary !== null){
        const numberPax = fullItinerary.adult + fullItinerary.child + fullItinerary.infant
        const addDays = (date, days) => {
            var result = new Date(date);
            if(days > 0){
                result.setDate(result.getDate() + days);
            }else{
                result = date;
            }
            return Moment(result).format('DD-MMM-YYYY');
        }
        const arrayDestinations = fullItinerary.nameRoute.split(',');
        //flightSchedule
        const flights = JSON.parse(fullItinerary.flightSchedule);

        body = (
            <div className="container">
                <div className="breadcrumb-header justify-content-between"> 
                    {/*<button className="btn btn-success btn-with-icon" onClick={exportPDF.bind(this)}><i className="fe fe-arrow-up-circle"></i> Export Full Itinerary PDF</button>*/}
                    <button className="btn btn-success btn-with-icon" onClick={docExport.bind(this)}><i className="fe fe-arrow-up-circle"></i> Export Full Itinerary</button>
                </div>
                <div id="html-docx" className='contentPDFItinerary'>
                    <div style={{textAlign: 'center'}} className="breadcrumb-header justify-content-center"> 
                        <img 
                            src="https://asiaprivatetravels.com/images/logo_asia_links_travel.png"
                            alt="Asia Private Travels"
                            style={{width: '200px'}}
                            className="logo-pfd"
                        />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="content-info">
                                <div style={{textAlign: 'center'}} className="header-info text-center">
                                    <h3>{fullItinerary.name}</h3>
                                    <p><strong>({fullItinerary.durations > 1 ? fullItinerary.durations + ' days' : fullItinerary.durations + ' day'} - {(fullItinerary.durations - 1) > 1 ? (fullItinerary.durations - 1) + ' nights' : (fullItinerary.durations - 1) + ' night'})</strong></p>
                                </div>
                                <div className="body-info">
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Prepared for:</strong>&nbsp;&nbsp;&nbsp;<strong>{fullItinerary.customerName}</strong></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Group size:</strong>&nbsp;&nbsp;&nbsp;<span>{numberPax < 10 ? `0${numberPax}` : numberPax} pax - Private tour</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Arrival date:</strong>&nbsp;&nbsp;&nbsp;<span>{Moment(fullItinerary.arrival).format('Do MMM YYYY')}</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Departure date:</strong>&nbsp;&nbsp;&nbsp;<span>{Moment(fullItinerary.departure).format('Do MMM YYYY')}</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Start point:</strong>&nbsp;&nbsp;&nbsp;<span>{fullItinerary.startPoint}</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>End point:</strong>&nbsp;&nbsp;&nbsp;<span>{fullItinerary.endPoint}</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Version:</strong>&nbsp;&nbsp;&nbsp;<span>{fullItinerary.version} - sent from {Moment(fullItinerary.created_at).format('Do MMM YYYY')}</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Travel Consultant:</strong>&nbsp;&nbsp;&nbsp;<span>{fullItinerary.sale.name}</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Email:</strong>&nbsp;&nbsp;&nbsp;<span>{fullItinerary.sale.email} | info@asialinkstravel.com</span></p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'><p style={{marginBottom: '0'}}><strong style={{width: '25%', float: 'left'}}>Hand phone:</strong>&nbsp;&nbsp;&nbsp;<span>+{fullItinerary.sale.sdt}</span></p></div>
                                    </div>
                                    <div className='body-brief-itinerary mt-3'>
                                        <h4 style={{color: '#CC0000', textAlign: 'center'}} className='title-text-label text-center'>BRIEF ITINERARY</h4>
                                        <div className="table-responsive">
                                            <table border="1" style={{width: '100%', "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'}} className="striped table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr style={{backgroundColor: "#c95e0d"}} className='bg-tr text-center'>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>DAY</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>DATE</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>BRIEF ITINERARY</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fullItinerary.arrayItineraries.map((itinerary, index) => (
                                                        <tr key={index}>
                                                            <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>Day {index + 1}</td>
                                                            <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>{addDays(fullItinerary.arrival, index)}</td>
                                                            <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>{itinerary.name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {flights.length > 0 ? (
                                    <div className='body-flight-itinerary mt-3'>
                                        <h4 style={{color: '#CC0000', textAlign: 'center'}} className='title-text-label text-center'>FLIGHT SCHEDULE</h4>
                                        <div className="table-responsive">
                                            <table style={{width: '100%', "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'}} className="striped table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr style={{backgroundColor: "#c95e0d"}} className='bg-tr text-center'>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>FLIGHT</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>TIMETABLE</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>DURATION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {flights.map((flight, itemKey) => (
                                                        <tr className='text-center' key={itemKey}>
                                                            <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>{flight.name}</td>
                                                            <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>{flight.fromTime} - {flight.toTime}/{Moment(flight.dateFlight).format('Do MMM YYYY')}</td>
                                                            <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>{flight.durationTime}</td>
                                                        </tr>
                                                    ))}    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>  
                                    ) : null}                  

                                    <div className='body-accommodation-itinerary mt-3'>
                                        <h4 style={{color: '#CC0000', textAlign: 'center'}} className='title-text-label text-center'>ACCOMMODATION</h4>
                                        <div className="table-responsive">
                                            <table style={{width: '100%', "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'}} className="striped table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr style={{backgroundColor: "#c95e0d"}} className='bg-tr text-center'>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>CITY</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid', color: '#ffffff'} }>HOTEL &amp; CRUISE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {arrayDestinations.map((destination, index) => (
                                                        <tr key={index} className='text-center'>
                                                            <td style={ {"lineHeight": "60px", padding: "3px", color: "#031b4e", backgroundColor: "#ddb291", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }><strong>{destination}</strong></td>
                                                            <td style={ {"lineHeight": "20px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>
                                                                {fullItinerary.arrayAccommodations[index]['name']} <br />
                                                                {fullItinerary.arrayAccommodations[index]['nameRoom']} <br />
                                                                <Link to={`${fullItinerary.arrayAccommodations[index]['websiteLink']}`} title={`View ${fullItinerary.arrayAccommodations[index]['name']}`} target={'_blank'}>
                                                                    {fullItinerary.arrayAccommodations[index]['websiteLink']}
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='body-transportation-itinerary mt-3'>
                                        <h4 style={{color: '#CC0000', textAlign: 'center'}} className='title-text-label text-center'>TRANSPORTATION</h4>
                                        <p className='text-center'>{fullItinerary.transportation}</p>
                                    </div>
                                    <div className='body-quotation-itinerary mt-3'>
                                        <h4 style={{color: '#CC0000', textAlign: 'center'}} className='title-text-label text-center'>QUOTATION</h4>
                                        <div className="table-responsive">
                                            <table style={{width: '100%', "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'}} className="striped table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr style={{backgroundColor: "#c95e0d"}} className='bg-tr text-center'>
                                                        <th style={ {width: "50%", lineHeight: "15px", padding: "3px", color: "#ffffff", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>Destination</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", color: "#ffffff", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>Price/person</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", backgroundColor: "#ddb291", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }><strong>Land tour</strong></td>
                                                        <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>
                                                            US${fullItinerary.totalLand}
                                                        </td>
                                                    </tr>
                                                    {flights.length > 0 ? (
                                                    <tr className='text-center'>
                                                        <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", backgroundColor: "#ddb291", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }><strong>{flights.length < 10 ? `0${flights.length}` : flights.length} Flight(s)</strong></td>
                                                        <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>
                                                            US${fullItinerary.totalFlight}
                                                        </td>
                                                    </tr>
                                                    ) : null}
                                                    <tr className='text-center'>
                                                        <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", backgroundColor: "#ddb291", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }><strong>Total</strong></td>
                                                        <td style={ {lineHeight: "15px", padding: "3px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>
                                                            <strong style={ {color: "#e92b2b"} }>US${fullItinerary.totalLand + fullItinerary.totalFlight}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='body-transportation-itinerary mt-3'>
                                        <h4 style={{color: '#CC0000', textAlign: 'center'}} className='title-text-label text-center'>INCLUSION &amp; EXCLUSION</h4>
                                    </div>
                                    <div className='body-include-itinerary mt-3'>
                                        <div className="table-responsive">
                                            <table style={{width: '100%', "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'}} className="striped table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr style={{backgroundColor: "#c95e0d"}} className='bg-tr text-center'>
                                                        <th style={ {lineHeight: "15px", padding: "3px", color: "#ffffff", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>INCLUSION</th>
                                                        <th style={ {lineHeight: "15px", padding: "3px", color: "#ffffff", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} }>EXCLUSION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-left'>
                                                        <td style={ {width: "50%", lineHeight: "20px", padding: "5px 5px 5px 15px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} } dangerouslySetInnerHTML={{__html: fullItinerary.inclusion}} />
                                                        <td style={ {lineHeight: "20px", padding: "5px 5px 5px 15px", color: "#031b4e", "borderWidth":"1px", 'borderColor':"#0f0f0f", 'borderStyle':'solid'} } dangerouslySetInnerHTML={{__html: fullItinerary.exclusion}} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='body-detail-itinerary mt-3'>
                                        <h4 className='title-text-label text-center'>DETAILED ITINERARY</h4>
                                        <div className='body-content-itinerary'>
                                            {fullItinerary.arrayItineraries.map((itinerary, index) => (
                                                <div key={index} className='content-block'>
                                                    <h5 style={ {fontSize: "14px", padding: "3px 10px", backgroundColor: "#ddb291", color: "#031b4e", marginTop: "3px"} }>{itinerary.name}</h5>
                                                    <div style={ {color: "#031b4e"} } dangerouslySetInnerHTML={{__html: itinerary.detail}} />
                                                    <p style={ {color: "#031b4e"} }><strong><i>Meal:</i></strong> {itinerary.textMeal === "" ? "No" : itinerary.textMeal}</p>
                                                    <p style={ {color: "#031b4e"} }><strong><i>Accommodation:</i></strong> {fullItinerary.arrayAccommodations[index] !== [] ? fullItinerary.arrayAccommodations[index].name : 'No'}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='body-trip-notes-itinerary mt-5 mb-5'>
                                        <h4 className='title-text-label text-center'>TRIP NOTES</h4>
                                        <div style={ {color: "#031b4e"} } className='trip-notes text-left' dangerouslySetInnerHTML={{__html: fullItinerary.tripNotes}} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {fullItinerary !== null && body}
        </Fragment>
    )
}

export default DetailItinerary