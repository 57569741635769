import { Fragment } from 'react';
import ListFails from './ListFails';

const Fails = () => {
    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="pull-left">List Failed</h3>
                            </div>
                        </div>
                    </div>
                    
                    <ListFails />
                </div>
            </div>
        </Fragment>
    )
}

export default Fails