import { createContext, useState, useReducer } from 'react';
import { contractReducer } from '../reducers/contractReducer';
import { apiUrl, CONTRACTS_LOADED_FAIL, CONTRACTS_LOADED_SUCCESS, SEARCH_CONTRACT, FIND_CONTRACT, UPDATE_CONTRACT, DELETE_CONTRACT, ADD_CONTRACT } from './constants';
import axios from 'axios';


export const ContractContext = createContext()

const ContractContextProvider = ({ children }) => {

    // State
    const [contractState, dispatch] = useReducer(contractReducer, {
        contract: null,
        contracts: [],
        contractsLoading: true
    })

    const [showAddContract, setAddContract] = useState(false)
    const [showUpdateContract, setShowUpdateContract] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Contracts
    const getContracts = async() => {
        try {
            const response = await axios.get(`${apiUrl}/contracts`)
            if (response.data.success) {
                dispatch({ type: CONTRACTS_LOADED_SUCCESS, payload: response.data.contracts })
            }
        } catch (error) {
            dispatch({ type: CONTRACTS_LOADED_FAIL })
            return error.response ? error.response : { success: false, message: 'Server error!' }
        }
    }

    // Search Request
    const getContract = async contractId => {
        //
        try {
            const response = await axios.get(`${apiUrl}/contracts/${contractId}`)
            if (response.data.success) {
                dispatch({ 
                    type: SEARCH_CONTRACT, 
                    payload: response.data.contract 
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Contract
    const addContract = async newContract => {
        try {
            const response = await axios.post(`${apiUrl}/contracts`, newContract)
            if (response.data.success) {
                dispatch({
                    type: ADD_CONTRACT,
                    payload: response.data.contract
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Contract when user is updating car
    const findContract = contractId => {
        const contract = contractState.contracts.find(contract => contract.id === contractId)
        dispatch({
            type: FIND_CONTRACT,
            payload: contract
        })
    }

    // update Contract
    const updateContract = async updateContract => {
        try {
            const response = await axios.put(`${apiUrl}/contracts/${updateContract.id}`, updateContract)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_CONTRACT,
                    payload: response.data.contract
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Contract
    const deleteContract = async contractId => {
        try {
            const response = await axios.delete(`${apiUrl}/contracts/${contractId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_CONTRACT,
                    payload: contractId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Contract Context Data
    const contractContextData = {
        contractState,
        getContracts,
        getContract,
        addContract,
        findContract,
        updateContract,
        deleteContract,
        showToast,
        setShowToast,
        showAddContract,
        setAddContract,
        showUpdateContract,
        setShowUpdateContract
    }

    return ( 
        <ContractContext.Provider value = { contractContextData } > 
            { children } 
        </ContractContext.Provider>
    )

}

export default ContractContextProvider