import { Fragment, useContext, useState, useEffect } from 'react';
import { RequestContext } from '../../contexts/RequestContext';
import { CountryContext } from '../../contexts/CountryContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { FullItineraryContext } from '../../contexts/FullItineraryContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';

const UpdateRequest = () => {

    // Context
    const { 
        requestState: { booking },
        showUpdateRequest, 
        setShowUpdateRequest, 
        updateRequest, 
        setShowToast 
    } = useContext(RequestContext)

    const { 
        fullItineraryState: { fullItineraries },
        getFullItineraries
    } = useContext(FullItineraryContext)

    const { 
        countryState: { countries },
        getCountries 
    } = useContext(CountryContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { authState: { users }, getUsers } = useContext(AuthContext)

    const [updatedRequest, setUpdatedRequest] = useState(booking)
    useEffect(() => setUpdatedRequest(booking), [booking])

    const { firstName, lastName, phone, messages, email, tourName, tourDate, birthday } = updatedRequest

    const onChangeValue = event => setUpdatedRequest( {...updatedRequest, [event.target.name]: event.target.value } )

    // Start: Get all data countries, []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data Full Itineraries, []
    useEffect( () => getFullItineraries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data users, []
    useEffect( () => getUsers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionCountries = []
    let optionDurations = []
    let optionSales = []
    let optionDestinations = []
    let optionNumbers = []
    let optionFullItineraries = []

    if(countries.length > 0){
        countries.map((country) => 
            optionCountries = [ ...optionCountries, 
                {
                    value: country.location,
                    label: country.location,
                }
            ]
        )
    }

    if(fullItineraries.length > 0){
        fullItineraries.map((itinerary) => 
            optionFullItineraries = [ ...optionFullItineraries, 
                {
                    value: itinerary.id,
                    label: itinerary.name,
                }
            ]
        )
    }

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.name,
                    label: destination.name,
                }
            ]
        )
    }

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    for (var i = 1; i < 32; i++) {
        optionDurations = [ ...optionDurations,
            {
                value: i,
                label: i + ' Day',
            }
        ]
    }

    for (var j = 1; j < 32; j++) {
        optionNumbers = [ ...optionNumbers,
            {
                value: j,
                label: j + ' Pax',
            }
        ]
    }

    const optionTitles = [
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
        { value: 'Miss.', label: 'Miss.' }
    ]

    const optionStatus = [
        { value: 'new', label: 'New' },
        { value: 'dealing', label: 'Dealing' },
        { value: 'success', label: 'Success' },
        { value: 'run', label: 'Running' },
        { value: 'complete', label: 'Complete' },
        { value: 'fall', label: 'Fail' }
    ]

    const optionSpecies = [
        { value: 'contact', label: 'Contact' },
        { value: 'mytrip', label: 'Mytrip' },
        { value: 'booking', label: 'Booking' }
    ]

    const optionHotels = [
        { value: 'Local homestay: From $20-$30/r/n', label: 'Local homestay: From $20-$30/r/n' },
        { value: '3 Stars: From $40-$60/r/n', label: '3 Stars: From $40-$60/r/n' },
        { value: '4 Stars: From $70-$110/r/n', label: '4 Stars: From $70-$110/r/n' },
        { value: '5 Stars: From $120-$190/r/n', label: '5 Stars: From $120-$190/r/n' }
    ]

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "country"){
                setUpdatedRequest( {...updatedRequest, country: dataValue } )
            }
            if(actionMeta.name === "duration"){
                setUpdatedRequest( {...updatedRequest, duration: dataValue } )
            }
            if(actionMeta.name === "status"){
                setUpdatedRequest( {...updatedRequest, status: dataValue } )
            }
            if(actionMeta.name === "sales"){
                setUpdatedRequest( {...updatedRequest, sales: dataValue } )
            }
            if(actionMeta.name === "adult"){
                setUpdatedRequest( {...updatedRequest, adult: dataValue } )
            }
            if(actionMeta.name === "child"){
                setUpdatedRequest( {...updatedRequest, child: dataValue } )
            }
            if(actionMeta.name === "infant"){
                setUpdatedRequest( {...updatedRequest, infant: dataValue } )
            }
            if(actionMeta.name === "title"){
                setUpdatedRequest( {...updatedRequest, mrName: dataValue } )
            }
            if(actionMeta.name === "species"){
                setUpdatedRequest( {...updatedRequest, species: dataValue } )
            }
            if(actionMeta.name === "title"){
                setUpdatedRequest( {...updatedRequest, mrName: dataValue } )
            }
            if(actionMeta.name === "hotels"){
                setUpdatedRequest( {...updatedRequest, tourClass: dataValue } )
            }
            if(actionMeta.name === "itinerary"){
                setUpdatedRequest( {...updatedRequest, itineraryID: dataValue } )
            }
        }
    }

    const handleChangeDestinations = (newValue, actionMeta) => {
        const arrayDestinations = newValue;
        let textRoute = "";
        if(arrayDestinations){
            arrayDestinations.map((itemDestination, index) => {
                if(index === 0){
                    textRoute = itemDestination.value;
                }else{
                    textRoute = textRoute + ',' + itemDestination.value;
                }
            })
            setUpdatedRequest( {...updatedRequest, route: textRoute } )
        }
        
    }

    const onSubmit = async event => {
        event.preventDefault()
        const {success, message} = await updateRequest(updatedRequest)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
        window.location.reload()
    }

    const closeDialog = () => {
        setUpdatedRequest(booking)
        setShowUpdateRequest(false)
        window.location.reload()
    } 

    const selectShowSale = idSale => {
        var defaultSales = null
        if(idSale > 0){

            optionSales.filter(option => 
                option.value === idSale ? defaultSales = {label: option.label, value: option.value} : ''
            )
            if(defaultSales !== null ){    
                return (
                    <Select isClearable
                        defaultValue={defaultSales}
                        onChange={handleChangeSelect.bind(this)}
                        options={optionSales} 
                        menuPosition={'fixed'}
                        placeholder="Select Sales"
                        name="sales"
                    />
                )
            }
        }else{
            return (
                <Select isClearable
                    onChange={handleChangeSelect.bind(this)}
                    options={optionSales} 
                    menuPosition={'fixed'}
                    placeholder="Select Sales"
                    name="sales"
                />
            )
        }
    }

    const selectShowCountry = textCountry => {
        var defaultCountry = null
        if(textCountry !== null){
            optionCountries.filter(option => 
                option.value === textCountry ? defaultCountry = {label: option.label, value: option.value} : ''
            )
            if(defaultCountry !== null ){    
                return (
                    <Select isClearable
                        defaultValue={defaultCountry}
                        onChange={handleChangeSelect.bind(this)}
                        options={optionCountries} 
                        menuPosition={'fixed'}
                        placeholder="Select Country"
                        name="country"
                    />
                )
            }
        }else{
            return (
                <Select isClearable
                    onChange={handleChangeSelect.bind(this)}
                    options={optionCountries} 
                    menuPosition={'fixed'}
                    placeholder="Select Country"
                    name="country"
                />
            )
        }
    }

    /*const selectShowRoute = textRoute => {

        var defaultTextRoute = null
        var defaultRouteItem = []
        if(textRoute !== null){
            defaultRouteItem = textRoute.split(',');
            optionDestinations.filter(option => 
                defaultRouteItem.indexOf(option.value) > -1 ? defaultTextRoute = {label: option.label, value: option.value} : ''
            )
            if(defaultTextRoute !== null){
                return (
                    <Select isClearable
                        defaultValue={
                            optionDestinations.filter(option => 
                                defaultRouteItem.indexOf(option.value) > -1 ? {label: option.label, value: option.value} : ''
                            )
                        }
                        onChange={handleChangeDestinations.bind(this)}
                        options={optionDestinations} 
                        menuPosition={'fixed'}
                        placeholder="Select Tour Route"
                        name="route"
                        isMulti
                    />
                )
            }
        }else{
            return (
                <Select isClearable
                    onChange={handleChangeDestinations.bind(this)}
                    options={optionDestinations} 
                    menuPosition={'fixed'}
                    placeholder="Select Tour Route"
                    name="route"
                    isMulti
                />
            )
        }
    }*/

    let body = null 

    if(booking.sale.id > 0){
        body = (
            <Modal show={showUpdateRequest} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Request
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Tour Name</strong></label>
                                    <input type="text" className="form-control" id="tourName" name="tourName" placeholder="Tour Name" value={tourName} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Sales</strong></label>
                                            {selectShowSale(booking.sale.id)}
                                        </Col>
                                        <Col>
                                            <label><strong>Status</strong></label>
                                            <Select isClearable
                                                defaultValue={
                                                    optionStatus.filter(option => 
                                                        option.value === booking.status ? {label: option.label, value: option.value} : ''
                                                    )
                                                }
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionStatus} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Status"
                                                name="status"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>Duration</strong></label>
                                            <Select isClearable
                                                defaultValue={
                                                    optionDurations.filter(option => 
                                                        option.value === Number(booking.duration) ? {label: option.label, value: option.value} : ''
                                                    )
                                                }
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionDurations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Duration"
                                                name="duration"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Full Itinerary</strong></label>
                                            <Select isClearable
                                                defaultValue={
                                                    optionFullItineraries.filter(option => 
                                                        option.value === Number(booking.itineraryID) ? {label: option.label, value: option.value} : ''
                                                    )
                                                }
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionFullItineraries} 
                                                menuPosition={'fixed'}
                                                placeholder="Select Itinerary"
                                                name="itinerary"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Tour Date</strong></label>
                                            <input type="date" className="form-control" id="tourDate" name="tourDate" placeholder="MMMM DD YYYY" value={tourDate}  onChange={onChangeValue} required aria-describedby='tour-date' />
                                            <Form.Text id='tour-date' muted>Required</Form.Text>
                                        </Col>
                                        <Col>
                                            <label><strong>Country</strong></label>
                                            {selectShowCountry(booking.country)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>{`Adult ( > 12)`}</strong></label>
                                    <Select isClearable
                                        defaultValue={
                                            optionNumbers.filter(option => 
                                                option.value === booking.adult ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionNumbers} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Adult"
                                        name="adult"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>{`Child (4 -> 12)`}</strong></label>
                                    <Select isClearable
                                        defaultValue={
                                            optionNumbers.filter(option => 
                                                option.value === booking.child ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionNumbers} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Child"
                                        name="child"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>{`Infant (0 - 3)`}</strong></label>
                                    <Select isClearable
                                        defaultValue={
                                            optionNumbers.filter(option => 
                                                option.value === booking.infant ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionNumbers} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Infant"
                                        name="infant"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Birthday</strong></label>
                                    <input type="date" className="form-control" id="birthday" name="birthday" placeholder="MMMM DD YYYY" value={birthday}  onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col xs={2}>
                                    <label><strong>{`Title`}</strong></label>
                                    <Select isClearable
                                        defaultValue={
                                            optionTitles.filter(option => 
                                                option.value === booking.mrName ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionTitles} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Title"
                                        name="title"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>First Name</strong></label>
                                    <input type="text" className="form-control" id="firstName" name="firstName" placeholder="First Name" value={firstName} onChange={onChangeValue}  required aria-describedby='first-help' />
                                    <Form.Text id='first-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Last Name</strong></label>
                                    <input type="text" className="form-control" id="lastName" name="lastName" value={lastName} onChange={onChangeValue} placeholder="Last Name" required aria-describedby='last-help' />
                                    <Form.Text id='last-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Email</strong></label>
                                    <input type="email" className="form-control" id="email" name="email" value={email} onChange={onChangeValue} placeholder="Enter Email" required aria-describedby='email-help' />
                                    <Form.Text id='email-help' muted>Required</Form.Text>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Phone</strong></label>
                                    <input type="text" className="form-control" id="phone" name="phone" value={phone} placeholder="No. Phone" onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Species</strong></label>
                                    <Select isClearable
                                        defaultValue={
                                            optionSpecies.filter(option => 
                                                option.value === booking.species ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionSpecies} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Specie"
                                        name="species"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Hotels</strong></label>
                                    <Select isClearable
                                        defaultValue={
                                            optionHotels.filter(option => 
                                                option.value === booking.tourClass ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionHotels} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Hotel"
                                        name="hotels"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Messages</strong></label>
                            <textarea className="form-control" placeholder="Messages" rows="5" id='messages' name='messages' value={messages} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    //console.log('booking', booking)

    return (
        <Fragment>
            {body !== null && body}
        </Fragment>
    )
}

export default UpdateRequest