import { Fragment, useContext, useEffect } from 'react';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner, Card, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import UpdateInvoice from './UpdateInvoice';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListInvoice = () => {

    // Context
    const {
        authState: { user: {name}, users },
        getUsers
    } = useContext(AuthContext)

    const { 
        invoiceState: { invoice, invoices, invoicesLoading },
        findInvoice,
        getInvoices,
        setShowUpdateInvoice
    } = useContext(InvoiceContext)

    // Start: Get all staffs , []
    useEffect( () => getInvoices(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const chooseInvoice = invoiceId => {
        findInvoice(invoiceId)
        setShowUpdateInvoice(true)
    }

    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(invoicesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(invoices.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name},</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            There are currently no invoices here
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <Fragment>
                <div className="ajax-booking-cruise">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-info-cruise mg-b-20">
                                <div className="table-info-cruise-body">
                                    <div className="table-responsive">
                                        <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Name Customer</th>
                                                    <th>Code Booking</th>
                                                    <th>Totals (USD)</th>
                                                    <th>Payment</th>
                                                    <th>Start Day</th>
                                                    <th>End Day</th>
                                                    <th className='w-220'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoices.map((invoice, index) => (
                                                    <tr key={index}>
                                                        <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                        <td style={ {lineHeight: "38px"} }>{invoice.mrName} {invoice.firstName} {invoice.lastName}</td>
                                                        <td style={ {lineHeight: "38px"} }>
                                                            #{invoice.booking_id}
                                                        </td>
                                                        <td style={ {lineHeight: "38px"} }>
                                                            {invoice.amount}
                                                        </td>
                                                        <td style={ {lineHeight: "38px"} }>
                                                            {invoice.linkPayments.map((payment, key) => {
                                                                if(key === 0){
                                                                    return (
                                                                        `${payment.status}`
                                                                    )
                                                                }else{
                                                                    return (
                                                                        `, ${payment.status}`
                                                                    )
                                                                }
                                                            })}
                                                        </td>
                                                        <td style={ {lineHeight: "38px"} }>
                                                            {Moment(invoice.dateStart).format('MMM D, YYYY')}
                                                        </td>
                                                        <td style={ {lineHeight: "38px"} }>
                                                            {Moment(invoice.dateEnd).format('MMM D, YYYY')}
                                                        </td>
                                                        <td>
                                                            <Row className="action-invoice">
                                                                <Col>
                                                                    <OverlayTrigger overlay={<Tooltip>View Invoice</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            <Link  to={`/invoices/${invoice.id}`} title={`View ${invoice.mrName} ${invoice.firstName} ${invoice.lastName}`} className="btn btn-primary btn-with-icon btn-block" target={'_blank'}>
                                                                                <i className="fe fe-file-text"></i> View
                                                                            </Link>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col>
                                                                    <OverlayTrigger overlay={<Tooltip>Edit Invoice</Tooltip>}>
                                                                        <span className="d-inline-block">
                                                                            <Button className="btn btn-info btn-with-icon btn-block" onClick={chooseInvoice.bind(this, invoice.id)}>
                                                                                <i className="fe fe-edit"></i> Edit
                                                                            </Button>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {body}
            {invoice !== null && <UpdateInvoice />}
        </Fragment>
    )
}

export default ListInvoice