import { INVOICES_LOADED_FAIL, INVOICES_LOADED_SUCCESS, FIND_INVOICE, UPDATE_INVOICE, DELETE_INVOICE, ADD_INVOICE, SEARCH_INVOICE } from '../contexts/constants';

export const invoiceReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case INVOICES_LOADED_SUCCESS:
            return {
                ...state,
                invoices: payload,
                invoicesLoading: false
            }
        case INVOICES_LOADED_FAIL:
            return {
                ...state,
                invoices: [],
                invoicesLoading: false
            }
        case ADD_INVOICE:
            {
                return {
                    ...state,
                    invoices: [...state.invoices, payload],
                }
            }
        case FIND_INVOICE:
            return {
                ...state,
                invoice: payload
            }
        case SEARCH_INVOICE:
            return {
                ...state,
                invoice: payload
            }
        case UPDATE_INVOICE:
            const newInvoice = state.invoices.map(invoice => {
                if (invoice.id === payload.id) {
                    return payload
                } else {
                    return invoice
                }
            })
            return {
                ...state,
                invoices: newInvoice
            }
        case DELETE_INVOICE:
            return {
                ...state,
                invoices: state.invoices.filter(invoice => invoice.id !== payload)
            }
        default:
            return state
    }
}