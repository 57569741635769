import { Fragment, useContext, useState, useEffect } from 'react';
import { FullItineraryContext } from '../../contexts/FullItineraryContext';
//import { CountryContext } from '../../contexts/CountryContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import ListAddFlight from './ListAddFlight';
import ListDurationItinerary from './ListDurationItinerary';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'moment';

const AddFullItinerary = () => {

    // Context
    const { 
        showAddFullItinerary, 
        setAddFullItinerary, 
        addFullItinerary, 
        setShowToast 
    } = useContext(FullItineraryContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { authState: {user, users }, getUsers } = useContext(AuthContext)

    const [numberKey, setNumberKey] = useState(0)
    const [numberDuration, setNumberDuration] = useState(0)
    const [arrItineraries, setArrItineraries] = useState([])
    const [arrFlights, setArrFlights] = useState([])

    // State
    const [newFullItinerary, setNewFullItinerary] = useState({
        name: '',
        codeTour: '',
        arrival: '',
        departure: '',
        version: '',
        email: '',
        phone: '',
        durations: '',
        inclusion: '',
        exclusion: '',
        tripNotes: '',
        transportation: '',
        noteTransportation: '',
        quotation: '',
        adult: '',
        child: '',
        infant: '',
        totalFlight: ''

    })

    const { name, codeTour, arrival, departure, email, phone, inclusion, exclusion, tripNotes, transportation, noteTransportation, quotation, adult, child, infant, totalFlight } = newFullItinerary

    const onChangeValue = event => setNewFullItinerary( {...newFullItinerary, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setNewFullItinerary( {...newFullItinerary, [event.target.name]: Number(event.target.value) } )

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data users, []
    useEffect( () => getUsers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionDestinations = []
    let optionDurations = []
    let optionNumber = []
    let optionSales = []
    let optionNumberKey = []

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    for (var z = 1; z < 32; z++) {
        optionDurations = [ ...optionDurations,
            {
                value: z,
                label: z + ' Day',
            }
        ]
    }

    for (var k = 1; k < 32; k++) {
        optionNumber = [ ...optionNumber,
            {
                value: k,
                label: k + ' Pax',
            }
        ]
    }

    for (var m = 1; m < 21; m++) {
        optionNumberKey = [ ...optionNumberKey,
            {
                value: m,
                label: m,
            }
        ]
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "startPoint"){
                setNewFullItinerary( {...newFullItinerary, startPoint: dataValue } )
            }
            if(actionMeta.name === "endPoint"){
                setNewFullItinerary( {...newFullItinerary, endPoint: dataValue } )
            }
            // duration
            if(actionMeta.name === "duration"){
                var result = new Date(arrival);
                setNumberDuration(dataValue)
                setNewFullItinerary( {...newFullItinerary, durations: dataValue } )
                if(dataValue > 0){
                    result.setDate(result.getDate() + (dataValue - 1));
                }else{
                    result = arrival;
                }
                setNewFullItinerary( {...newFullItinerary, departure: Moment(result).format('YYYY-MM-DD') } )
                
            }
            if(actionMeta.name === "adult"){
                setNewFullItinerary( {...newFullItinerary, adult: dataValue } )
            }
            if(actionMeta.name === "child"){
                setNewFullItinerary( {...newFullItinerary, child: dataValue } )
            }
            if(actionMeta.name === "infant"){
                setNewFullItinerary( {...newFullItinerary, infant: dataValue } )
            }
            if(actionMeta.name === "sales"){
                setNewFullItinerary( {...newFullItinerary, travelConsultant: dataValue } )
            }
            if(actionMeta.name === "version"){
                setNewFullItinerary( {...newFullItinerary, version: dataValue } )
            }
            //route  
            if(actionMeta.name === "route"){
                setNewFullItinerary( {...newFullItinerary, infant: dataValue } )
                const arrayServices = newValue;
                let textServices = "";
                if(arrayServices){
                    arrayServices.map((service, index) => {
                        if(index === 0){
                            textServices = service.value;
                        }else{
                            textServices = textServices + ',' + service.value;
                        }
                    })
                    setNewFullItinerary( {...newFullItinerary, route: textServices } )
                }
            }
            
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        newFullItinerary['flightSchedule'] = JSON.stringify(arrFlights)
        newFullItinerary['detailItinerary'] = JSON.stringify(arrItineraries)
        newFullItinerary['user_id'] = user.id
        //console.log('newFullItinerary', newFullItinerary) 
        const {success, message} = await addFullItinerary(newFullItinerary)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setNewFullItinerary({
            name: '',
            codeTour: '',
            arrival: '',
            departure: '',
            version: '',
            email: '',
            phone: '',
            durations: '',
            inclusion: '',
            exclusion: '',
            tripNotes: '',
            transportation: '',
            noteTransportation: '',
            quotation: '',
            adult: '',
            child: '',
            infant: ''
        })
        setAddFullItinerary(false)
    }

    const addButtonFlight = (index) => {
        setNumberKey(index + 1)
    }

    return (
        <Fragment>
            <Modal show={showAddFullItinerary} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Full Itinerary
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name Tour</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Ex: Indochina Highlights" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Code Itinerary</strong></label>
                                            <input type="text" className="form-control" id="codeTour" name="codeTour" placeholder="LHB-2D1N" value={codeTour} onChange={onChangeValue} required aria-describedby='codeTour-help' />
                                            <Form.Text id='codeTour-help' muted>Required</Form.Text>
                                        </Col>
                                        <Col>
                                            <label><strong>Durations</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionDurations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Durations"
                                                name="duration"
                                                aria-describedby='durationTour-help'
                                            />
                                            <Form.Text id='durationTour-help' muted>Required</Form.Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        
                        <div className="form-group">
                            <Row>
                                <Col md={{ span: 8 }}>
                                    <label><strong>Route</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Choose Destination"
                                        name="route"
                                        isMulti
                                    />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Start Point</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionDestinations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Destination"
                                                name="startPoint"
                                            />
                                        </Col>
                                        <Col>
                                            <label><strong>End Point</strong></label>
                                            <Select isClearable
                                                onChange={handleChangeSelect.bind(this)}
                                                options={optionDestinations}
                                                menuPosition={'fixed'}
                                                placeholder="Select Destination"
                                                name="endPoint"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Transportation</strong></label>
                            <input type="text" className="form-control" id="transportation" name="transportation" placeholder="Ex: Private 4 seat car, except for Hanoi – Halong Bay - Hanoi by Luxury D-Car shuttle bus" value={transportation} onChange={onChangeValue} />
                        </div>
                        <div className="form-group">
                            <label><strong>Note Transportation</strong></label>
                            <textarea className="form-control" placeholder="Note Transportation" rows="3" id='noteTransportation' name='noteTransportation' value={noteTransportation} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        <div className="form-group">
                            <label><strong>Quotation</strong></label>
                            <input type="text" className="form-control" id="quotation" name="quotation" placeholder="Ex: Rate in USD per person (For private tour based on 01 single room)" value={quotation} onChange={onChangeValue} />
                        </div>
                        {numberKey > 0 ? (
                            <ListAddFlight numberKey={numberKey} arrFlights={arrFlights} setArrFlights={setArrFlights} totalFlight={totalFlight} onChangeNumber={onChangeNumber} />
                        ) : ''}
                        <div className="form-group d-inline-block w-100">
                            <Button className="float-right btn btn-success btn-with-icon mt-3" variant='success' onClick={addButtonFlight.bind(this, numberKey)}><i className="fe fe-plus-circle"></i> Add Flight</Button>
                        </div>
                        {numberDuration > 0 ? (
                            <ListDurationItinerary numberDuration={numberDuration} arrItineraries={arrItineraries} setArrItineraries={setArrItineraries} adult={adult} child={child} infant={infant} arrival={arrival} newFullItinerary={newFullItinerary} setNewFullItinerary={setNewFullItinerary} />
                        ) : null }
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Inclusion</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={inclusion ? inclusion : ''}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setNewFullItinerary( {...newFullItinerary, inclusion: data } )
                                        } }
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Exclusion</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={exclusion ? exclusion : ''}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setNewFullItinerary( {...newFullItinerary, exclusion: data } )
                                        } }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Trip Notes</strong></label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={tripNotes ? tripNotes : ''}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setNewFullItinerary( {...newFullItinerary, tripNotes: data } )
                                } }
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddFullItinerary