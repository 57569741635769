import { Fragment, useContext, useState, useEffect } from 'react';
import { FullItineraryContext } from '../../contexts/FullItineraryContext';
import { AuthContext } from '../../contexts/AuthContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ListUpdateFlight from './ListUpdateFlight';
import ListUpdateDurationItinerary from './ListUpdateDurationItinerary';
import Moment from 'moment';

const UpdateFullItinerary = () => {

    // Context
    const { 
        fullItineraryState: { fullItinerary },
        showUpdateFullItinerary, 
        setShowUpdateFullItinerary, 
        updateFullItinerary, 
        setShowToast 
    } = useContext(FullItineraryContext)

    const [updatedFullItinerary, setUpdatedFullItinerary] = useState(fullItinerary)
    useEffect( () => setUpdatedFullItinerary(fullItinerary), [fullItinerary] )

    //console.log('fullItinerary', fullItinerary)

    const { name, customerName, arrival, departure, email, phone, inclusion, exclusion, tripNotes, transportation, noteTransportation, quotation, adult, child, infant, totalFlight } = updatedFullItinerary

    const onChangeValue = event => setUpdatedFullItinerary( {...updatedFullItinerary, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setUpdatedFullItinerary( {...updatedFullItinerary, [event.target.name]: Number(event.target.value) } )

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const { authState: {user, users }, getUsers } = useContext(AuthContext)

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data users, []
    useEffect( () => getUsers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const [numberKey, setNumberKey] = useState(0)
    const [numberDuration, setNumberDuration] = useState(0)
    const [arrItineraries, setArrItineraries] = useState([])
    const [arrFlights, setArrFlights] = useState([])

    useEffect( () => {
        if(fullItinerary !== null){
            setNumberKey(JSON.parse(fullItinerary.flightSchedule).length)
            setArrFlights(JSON.parse(fullItinerary.flightSchedule))
            setArrItineraries(JSON.parse(fullItinerary.detailItinerary))
            setNumberDuration(JSON.parse(fullItinerary.detailItinerary).length)
            //setTotalLand(fullItinerary.totalLand)
        }
    }, [fullItinerary] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionDestinations = []
    let optionDurations = []
    let optionNumber = []
    let optionSales = []
    let optionNumberKey = []
    let defaultRoutes = []

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    for (var z = 1; z < 32; z++) {
        optionDurations = [ ...optionDurations,
            {
                value: z,
                label: z + ' Day',
            }
        ]
    }

    for (var k = 1; k < 32; k++) {
        optionNumber = [ ...optionNumber,
            {
                value: k,
                label: k + ' Pax',
            }
        ]
    }

    for (var m = 1; m < 21; m++) {
        optionNumberKey = [ ...optionNumberKey,
            {
                value: m,
                label: m,
            }
        ]
    }

    if(fullItinerary.route !== '' && fullItinerary.route !== null){
        defaultRoutes = fullItinerary.route.split(',').map(x=>+x)
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "startPoint"){
                setUpdatedFullItinerary( {...updatedFullItinerary, startPoint: dataValue } )
            }
            if(actionMeta.name === "endPoint"){
                setUpdatedFullItinerary( {...updatedFullItinerary, endPoint: dataValue } )
            }
            // duration
            if(actionMeta.name === "duration"){
                var result = new Date(arrival);
                setNumberDuration(dataValue)
                setUpdatedFullItinerary( {...updatedFullItinerary, durations: dataValue } )
                if(dataValue > 0){
                    result.setDate(result.getDate() + (dataValue - 1));
                }else{
                    result = arrival;
                }
                setUpdatedFullItinerary( {...updatedFullItinerary, departure: Moment(result).format('YYYY-MM-DD') } )
            }
            if(actionMeta.name === "adult"){
                setUpdatedFullItinerary( {...updatedFullItinerary, adult: dataValue } )
            }
            if(actionMeta.name === "child"){
                setUpdatedFullItinerary( {...updatedFullItinerary, child: dataValue } )
            }
            if(actionMeta.name === "infant"){
                setUpdatedFullItinerary( {...updatedFullItinerary, infant: dataValue } )
            }
            if(actionMeta.name === "sales"){
                setUpdatedFullItinerary( {...updatedFullItinerary, travelConsultant: dataValue } )
            }
            if(actionMeta.name === "version"){
                setUpdatedFullItinerary( {...updatedFullItinerary, version: dataValue } )
            }
            //route  
            if(actionMeta.name === "route"){
                setUpdatedFullItinerary( {...updatedFullItinerary, infant: dataValue } )
                const arrayServices = newValue;
                let textServices = "";
                if(arrayServices){
                    arrayServices.map((service, index) => {
                        if(index === 0){
                            textServices = service.value;
                        }else{
                            textServices = textServices + ',' + service.value;
                        }
                    })
                    setUpdatedFullItinerary( {...updatedFullItinerary, route: textServices } )
                }
            }
            
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        updatedFullItinerary['flightSchedule'] = JSON.stringify(arrFlights)
        updatedFullItinerary['detailItinerary'] = JSON.stringify(arrItineraries)
        updatedFullItinerary['user_id'] = user.id
        //console.log('updatedFullItinerary', updatedFullItinerary) 
        const {success, message} = await updateFullItinerary(updatedFullItinerary)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setUpdatedFullItinerary(fullItinerary)
        setShowUpdateFullItinerary(false)
    }

    const addButtonFlight = (index) => {
        setNumberKey(index + 1)
    }

    return (
        <Fragment>
            <Modal show={showUpdateFullItinerary} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Full Itinerary
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Ex: Indochina Highlights" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label><strong>Prepared for</strong></label>
                                            <input type="text" className="form-control" id="customerName" name="customerName" placeholder="Customer Name" value={customerName} onChange={onChangeValue} required aria-describedby='customerName-help' />
                                            <Form.Text id='customerName-help' muted>Required</Form.Text>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <label><strong>Adults</strong></label>
                                                    <Select isClearable
                                                        onChange={handleChangeSelect.bind(this)}
                                                        defaultValue={
                                                            optionNumber.filter(option => 
                                                                option.value === fullItinerary.adult ? {label: option.label, value: option.value} : ''
                                                            )
                                                        }
                                                        options={optionNumber}
                                                        menuPosition={'fixed'}
                                                        placeholder="Adult"
                                                        name="adult"
                                                    />
                                                </Col>
                                                <Col>
                                                    <label><strong>Children</strong></label>
                                                    <Select isClearable
                                                        onChange={handleChangeSelect.bind(this)}
                                                        defaultValue={
                                                            optionNumber.filter(option => 
                                                                option.value === fullItinerary.child ? {label: option.label, value: option.value} : ''
                                                            )
                                                        }
                                                        options={optionNumber}
                                                        menuPosition={'fixed'}
                                                        placeholder="Child"
                                                        name="child"
                                                    />
                                                </Col>
                                                <Col>
                                                    <label><strong>Infants</strong></label>
                                                    <Select isClearable
                                                        onChange={handleChangeSelect.bind(this)}
                                                        defaultValue={
                                                            optionNumber.filter(option => 
                                                                option.value === fullItinerary.infant ? {label: option.label, value: option.value} : ''
                                                            )
                                                        }
                                                        options={optionNumber}
                                                        menuPosition={'fixed'}
                                                        placeholder="Infant"
                                                        name="infant"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Arrival Date</strong></label>
                                    <input type="date" className="form-control" id="arrival" name="arrival" value={arrival} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Departure Date</strong></label>
                                    <input type="date" className="form-control" id="departure " name="departure" value={departure} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Start Point</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            optionDestinations.filter(option => 
                                                option.value === fullItinerary.startPoint ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="startPoint"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>End Point</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            optionDestinations.filter(option => 
                                                option.value === fullItinerary.endPoint ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="endPoint"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Travel Consultant</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            optionSales.filter(option => 
                                                option.value === fullItinerary.travelConsultant ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionSales}
                                        menuPosition={'fixed'}
                                        placeholder="Select Sales"
                                        name="sales"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Email</strong></label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter Email" value={email} onChange={onChangeValue} required />
                                </Col>
                                <Col>
                                    <label><strong>Hand Phone</strong></label>
                                    <input type="text" className="form-control" id="phone" name="phone" placeholder="No. Phone" value={phone} onChange={onChangeValue} required />
                                </Col>
                                <Col>
                                    <label><strong>Version</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            optionNumberKey.filter(option => 
                                                option.value === fullItinerary.version ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionNumberKey}
                                        menuPosition={'fixed'}
                                        placeholder="Choose Version"
                                        name="version"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col md={{ span: 9 }}>
                                    <label><strong>Route</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            optionDestinations.filter(option => 
                                                defaultRoutes.indexOf(option.value) > -1 ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Choose Destination"
                                        name="route"
                                        isMulti
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Durations</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        defaultValue={
                                            optionDurations.filter(option => 
                                                option.value === fullItinerary.durations ? {label: option.label, value: option.value} : ''
                                            )
                                        }
                                        options={optionDurations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Durations"
                                        name="duration"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Transportation</strong></label>
                            <input type="text" className="form-control" id="transportation" name="transportation" placeholder="Ex: Private 4 seat car, except for Hanoi – Halong Bay - Hanoi by Luxury D-Car shuttle bus" value={transportation} onChange={onChangeValue} />
                        </div>
                        <div className="form-group">
                            <label><strong>Note Transportation</strong></label>
                            <textarea className="form-control" placeholder="Note Transportation" rows="3" id='noteTransportation' name='noteTransportation' value={noteTransportation !== null ? noteTransportation : ''} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                        <div className="form-group">
                            <label><strong>Quotation</strong></label>
                            <input type="text" className="form-control" id="quotation" name="quotation" placeholder="Ex: Rate in USD per person (For private tour based on 01 single room)" value={quotation} onChange={onChangeValue} />
                        </div>
                        {numberKey > 0 ? (
                            <ListUpdateFlight numberKey={numberKey} arrFlights={arrFlights} setArrFlights={setArrFlights} totalFlight={totalFlight} onChangeNumber={onChangeNumber} />
                        ) : ''}
                        <div className="form-group d-inline-block w-100">
                            <Button className="float-right btn btn-success btn-with-icon mt-3" variant='success' onClick={addButtonFlight.bind(this, numberKey)}><i className="fe fe-plus-circle"></i> Add Flight</Button>
                        </div>
                        {numberDuration > 0 ? (
                            <ListUpdateDurationItinerary numberDuration={numberDuration} arrItineraries={arrItineraries} setArrItineraries={setArrItineraries} adult={adult} child={child} infant={infant} arrival={arrival} updatedFullItinerary={updatedFullItinerary} setUpdatedFullItinerary={setUpdatedFullItinerary} />
                        ) : null }
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Inclusion</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={inclusion ? inclusion : ''}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setUpdatedFullItinerary( {...updatedFullItinerary, inclusion: data } )
                                        } }
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Exclusion</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={exclusion ? exclusion : ''}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setUpdatedFullItinerary( {...updatedFullItinerary, exclusion: data } )
                                        } }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Trip Notes</strong></label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={tripNotes ? tripNotes : ''}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setUpdatedFullItinerary( {...updatedFullItinerary, tripNotes: data } )
                                } }
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateFullItinerary