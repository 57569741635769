import { Fragment, useContext, useEffect } from 'react';
import { GuideContext } from '../../contexts/GuideContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdateGuide from './UpdateGuide';
import DeleteModal from '../../components/layout/DeleteModal';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListGuide = () => {

    // Context
    const {authState: {user: {name} }} = useContext(AuthContext)
    const { 
        guideState: { guide, guides, guidesLoading },
        getGuides,
        findGuide,
        deleteGuide,
        setShowUpdateGuide
    } = useContext(GuideContext)

    const chooseGuide = guideId => {
        findGuide(guideId)
        setShowUpdateGuide(true)
    }

    // Start: Get all staffs , []
    useEffect( () => getGuides(), [] ) // eslint-disable-line react-hooks/exhaustive-deps
    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(guidesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(guides.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name},</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a guide route name
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <Fragment>
                <div className="ajax-booking-cruise">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-info-cruise mg-b-20">
                                <div className="table-info-cruise-body">
                                    <div className="table-responsive">
                                        <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Surcharge</th>
                                                    <th>Duration</th>
                                                    <th>Destination</th>
                                                    <th>Code Guide</th>
                                                    <th>Language</th>
                                                    <th className="w-220">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {guides.map((guide, index) => (
                                                <tr key={index}>
                                                    <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.name}</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.amount}</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.surcharge}</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.duration} Day</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.destinationName}</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.codeGuide}</td>
                                                    <td style={ {lineHeight: "38px"} }>{guide.language}</td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-sm">
                                                                <button className="btn btn-success btn-with-icon btn-block" onClick={chooseGuide.bind(this, guide.id)}><i className="fe fe-edit"></i> Edit</button>
                                                            </div>
                                                            <div className="col-sm">
                                                                <DeleteModal idProps={guide.id} deleteFunc={deleteGuide} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {body}
            {guide !== null && <UpdateGuide />}
        </Fragment>
    )

}

export default ListGuide