import { ADD_ACCOMMODATION, ACCOMMODATIONS_LOADED_FAIL, ACCOMMODATIONS_LOADED_SUCCESS, DELETE_ACCOMMODATION, FIND_ACCOMMODATION, UPDATE_ACCOMMODATION } from '../contexts/constants';

export const accommodationReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ACCOMMODATIONS_LOADED_SUCCESS:
            return {
                ...state,
                accommodations: payload,
                accommodationsLoading: false
            }
        case ACCOMMODATIONS_LOADED_FAIL:
            return {
                ...state,
                accommodations: [],
                accommodationsLoading: false
            }
        case ADD_ACCOMMODATION:
            {
                return {
                    ...state,
                    accommodations: [...state.accommodations, payload],
                }
            }
        case FIND_ACCOMMODATION:
            return {
                ...state,
                accommodation: payload
            }
        case UPDATE_ACCOMMODATION:
            const newAccommodation = state.accommodations.map(accommodation => {
                if (accommodation.id === payload.id) {
                    return payload
                } else {
                    return accommodation
                }
            })
            return {
                ...state,
                accommodations: newAccommodation
            }
        case DELETE_ACCOMMODATION:
            return {
                ...state,
                accommodations: state.accommodations.filter(accommodation => accommodation.id !== payload)
            }
        default:
            return state
    }

}