import { Fragment, useContext, useState, useEffect } from 'react';
import { PartnerContext } from '../../contexts/PartnerContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';

const AddPartner = () => {

    // Context
    const {
        showAddPartner, 
        setAddPartner, 
        addPartner, 
        setShowToast
    } = useContext(PartnerContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    // State
    const [newPartner, setNewPartner] = useState({
        name: '',
        webLink: '',
        detail: '',
        phone: '',
        address: '',
        bank_name: '',
        bank_account: ''
    })

    const { name, webLink, detail, phone, address, bank_name, bank_account } = newPartner

    const onChangeValue = event => setNewPartner( {...newPartner, [event.target.name]: event.target.value } )

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const typePartners = [
        { value: 'guides', label: 'Guides' },
        { value: 'car', label: 'Car'},
        { value: 'ticket', label: 'Ticket'}
    ]

    let optionDestinations = []

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setNewPartner( {...newPartner, destination: dataValue } )
            }
            //typeService
            if(actionMeta.name === "typePartners"){
                setNewPartner( {...newPartner, type_partners: dataValue } )
            }
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        console.log('newPartner', newPartner)
        const {success, message} = await addPartner(newPartner)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setNewPartner({
            name: '',
            webLink: '',
            detail: '',
            phone: '',
            address: '',
            bank_name: '',
            bank_account: ''
        })
        setAddPartner(false)
    }

    return (
        <Fragment>
            <Modal show={showAddPartner} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Partner
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col md={{ span: 8 }}>
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Partner Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Type Partner</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={typePartners}
                                        menuPosition={'fixed'}
                                        placeholder="Select Type"
                                        name="typePartners"
                                        aria-describedby='type-partners-help'
                                    />
                                    <Form.Text id='type-partners-help' muted>Required</Form.Text>
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Phone</strong></label>
                                    <input type="text" className="form-control" id="phone" name="phone" placeholder="0908 888 888" value={phone} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Website</strong></label>
                                    <input type="text" className="form-control" id="webLink" name="webLink" placeholder="Website Partner" value={webLink} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Address</strong></label>
                                    <input type="text" className="form-control" id="address" name="address" placeholder="Enter Address" value={address} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Destination</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeSelect.bind(this)}
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="destination"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Bank Name</strong></label>
                                    <input type="text" className="form-control" id="bank_name" name="bank_name" placeholder="Techcombank" value={bank_name} onChange={onChangeValue} />
                                </Col>
                                <Col>
                                    <label><strong>Bank Account</strong></label>
                                    <input type="text" className="form-control" id="bank_account" name="bank_account" placeholder="Number Bank Account" value={bank_account} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <label><strong>Detail</strong></label>
                            <textarea className="form-control" placeholder="Detail or Note Partner" rows="5" name='detail' value={detail} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>    
            </Modal>
        </Fragment>
    )
}

export default AddPartner