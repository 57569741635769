import { COUNTRIES_LOADED_FAIL, COUNTRIES_LOADED_SUCCESS } from '../contexts/constants';

export const countryReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case COUNTRIES_LOADED_SUCCESS:
            return {
                ...state,
                countries: payload,
                countriesLoading: false
            }
        case COUNTRIES_LOADED_FAIL:
            return {
                ...state,
                countries: [],
                countriesLoading: false
            }
        default:
            return state
    }
}