import { createContext, useState, useReducer } from 'react';
import { guideReducer } from '../reducers/guideReducer';
import { apiUrl, ADD_GUIDE, GUIDES_LOADED_FAIL, GUIDES_LOADED_SUCCESS, DELETE_GUIDE, FIND_GUIDE, UPDATE_GUIDE } from './constants';
import axios from 'axios';


export const GuideContext = createContext()

const GuideContextProvider = ({ children }) => {

    // State
    const [guideState, dispatch] = useReducer(guideReducer, {
        guide: null,
        guides: [],
        guidesLoading: true
    })

    const [showAddGuide, setAddGuide] = useState(false)
    const [showUpdateGuide, setShowUpdateGuide] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Guides
    const getGuides = async() => {
        try {
            const response = await axios.get(`${apiUrl}/guides`)
            if (response.data.success) {
                dispatch({ type: GUIDES_LOADED_SUCCESS, payload: response.data.guides })
            }
        } catch (error) {
            dispatch({ type: GUIDES_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Guide
    const addGuide = async newGuide => {
        try {
            const response = await axios.post(`${apiUrl}/guides`, newGuide)
            console.log(response);
            if (response.data.success) {
                dispatch({
                    type: ADD_GUIDE,
                    payload: response.data.guide
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Guide when user is updating post
    const findGuide = guideId => {
        const guide = guideState.guides.find(guide => guide.id === guideId)
        dispatch({
            type: FIND_GUIDE,
            payload: guide
        })
    }

    // update Guide
    const updateGuide = async updateGuide => {
        try {
            const response = await axios.put(`${apiUrl}/guides/${updateGuide.id}`, updateGuide)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_GUIDE,
                    payload: response.data.guide
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Guide
    const deleteGuide = async guideId => {
        try {
            const response = await axios.delete(`${apiUrl}/guides/${guideId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_GUIDE,
                    payload: guideId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Guide Context Data
    const guideContextData = {
        guideState,
        getGuides,
        addGuide,
        findGuide,
        updateGuide,
        deleteGuide,
        showToast,
        setShowToast,
        showAddGuide,
        setAddGuide,
        showUpdateGuide,
        setShowUpdateGuide
    }

    return ( 
        <GuideContext.Provider value = { guideContextData } > 
            { children } 
        </GuideContext.Provider>
    )

}

export default GuideContextProvider