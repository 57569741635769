import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Moment from 'moment';

const ContractTable = ({ contracts }) => {

    //console.log('contractsTable', contracts)
    const [optionTypes, setOptionTypes] = useState(["hotel", "cruise", "restaurant", "guide", "car", "ticket"]);

    useEffect( () => 
        setTimeout(function(){ 
            optionTypes.map((option) => {
                $('#list-'+option).DataTable(); 
            })
            
        }, 1000)
    , [contracts]) //

    //let optionTypes = ["hotel", "cruise", "restaurant", "guide", "car", "ticket"]

    return (
        <Fragment>
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <Tabs className="nav profile nav-tabs block-pdf-content navtab-custom panel-tabs" defaultActiveKey="event-hotel" transition={false} id="noanim-tab-example">
                                        {optionTypes.map((option, index) => {
                                            let dem = 0;
                                            return (
                                                <Tab key={index} className="border-left border-bottom border-right border-top-0 p-4" eventKey={`event-${option}`} title={option}>
                                                    <div className="streamline user-activity">
                                                        <table id={`list-${option}`} className="table table-striped mg-b-0 text-md-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th>Name</th>
                                                                    <th>Destination</th>
                                                                    <th>Country</th>
                                                                    <th>Partner</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {contracts.map((contract) => {
                                                                    if(contract.typeService === option){
                                                                        dem++;
                                                                        return (
                                                                            <tr key={contract.id}>
                                                                                <td style={ {lineHeight: "38px"} }>{dem}</td>
                                                                                <td style={ {lineHeight: "38px"} }>
                                                                                    {contract.name}&nbsp;&nbsp;
                                                                                    {contract.promotionTime.length > 0 && (
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id={`tooltip-top`}>
                                                                                                {contract.promotionTime.map((promotion, keyIndex) => (
                                                                                                    <div key={keyIndex} className='item-promotion text-left'>
                                                                                                        <p><strong style={ {fontSize: "16px"} }>{promotion.name}</strong></p>
                                                                                                        <p><strong>Start Date:</strong>&nbsp;{Moment(promotion.startDate).format('MMMM D, YYYY')}</p>
                                                                                                        <p><strong>End Date:</strong>&nbsp;{Moment(promotion.endDate).format('MMMM D, YYYY')}</p>
                                                                                                    </div>
                                                                                                ))}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                        <i style={ {cursor: "pointer"} } className="fas fa-gift"></i>
                                                                                        </OverlayTrigger>
                                                                                    )}
                                                                                </td>
                                                                                <td style={ {lineHeight: "38px"} }>{contract.destinationName}</td>
                                                                                <td style={ {lineHeight: "38px"} }>{contract.countryName}</td>
                                                                                <td style={ {lineHeight: "38px"} }>{contract.partnerName}</td>
                                                                                <td>
                                                                                    <div className="row">
                                                                                        <div className="contract-btn">
                                                                                            <Link className="btn btn-with-icon" to={`/contract-file/${contract.id}`} title="Contract Details" target={'_blank'}><i className="far fa-grin-tongue-wink"></i>Show</Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Tab>
                                            )
                                        })}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default ContractTable;