import { Fragment, useContext, useEffect, useState } from 'react';
import { CustomerContext } from '../../../contexts/CustomerContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { CountryContext } from '../../../contexts/CountryContext';
import Select from 'react-select';
import { Button, Form } from 'react-bootstrap';
import { apiUrl } from '../../../contexts/constants';
import axios from 'axios';
import AlertMessage from '../../../components/layout/AlertMessage';
import DefaultTemplate from '../templates/DefaultTemplate';

const MailCompose = () => {

    const {authState: { user }} = useContext(AuthContext)

    const {
        countryState: { countries },
        getCountries
    } = useContext(CountryContext)

    const { 
        customerState: { customers },
        getCustomers
    } = useContext(CustomerContext)

    // Start: Get all xe , []
    useEffect( () => getCustomers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all xe , []
    useEffect( () => getCountries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const [alert, setAlert] = useState(null)
    const [isDisable, setIsDisable] = useState(false)
    const [arrayChange, setArrayChange] = useState([])
    const [textCountry, setTextCountry] = useState([])
    const [nameCountry, setNameCountry] = useState([])

    let countryCustomers = {};
    let arrayIDCountry = []
    if(customers){
        customers.map((item) => {
            if (!countryCustomers[item.country]) {
                countryCustomers[item.country] = [item];
                arrayIDCountry.push(item.country)
            } else {
                countryCustomers[item.country].push(item);
            }
        })
    }

    const [arrayCustomers, setArrayCustomers] = useState([])

    // State
    const [newMailCompose, setNewMailCompose] = useState({
        name: '',
        subject: '',
        sales: user.id,
        nameSales: user.name
    })

    const { name, subject, sales, nameSales } = newMailCompose

    const onChangeValue = event => setNewMailCompose( {...newMailCompose, [event.target.name]: event.target.value } )

    

    let optionCustomers = []

    if(customers.length > 0){
        customers.map((customer) => 
            optionCustomers = [ ...optionCustomers, 
                {
                    value: customer.email,
                    label: customer.email,
                    name: customer.name,
                    country: customer.country
                }
            ]
        )
    }

    const fromMails = [
        { value: 'info@asialinkstravel.com', label: 'info@asialinkstravel.com' },
        { value: 'info@asiaprivateholidays.com', label: 'info@asiaprivateholidays.com' }
    ]

    let arrayTam = []
    

    const handleChangeCheckBok = (item, name, event) => {
        if(event.target.checked === true){
            arrayTam.push(item)
            setTextCountry((textCountry) => [...textCountry, item])
            setNameCountry((nameCountry) => [...nameCountry, name])
        }else{
            const index = arrayTam.indexOf(item);
            const textIndex = textCountry.indexOf(item);
            const nameIndex = nameCountry.indexOf(name);
            if (index > -1) { 
                arrayTam.splice(index, 1); 
            }
            if (textIndex > -1) { 
                textCountry.splice(textIndex, 1)
                setTextCountry(textCountry)
            }
            if (nameIndex > -1) { 
                nameCountry.splice(nameIndex, 1)
                setNameCountry(nameCountry)
            }
        }

        if(arrayTam.length > 0){
            setNewMailCompose( {...newMailCompose, country: JSON.stringify(arrayTam) } )
            arrayTam.map((itemTam) => {
                optionCustomers.map((itemCustomer) => {
                    if(itemCustomer.country === itemTam){
                        setArrayChange((arrayChange) => [...arrayChange, itemCustomer])
                    }
                })
            })
        }
    }

    
    

    const mailCompose = async newMail => {
        try {
            const response = await axios.post(`${apiUrl}/mail-compose`, newMail)
            return response.data
        } catch (error) {
            return error.response ? error.response : {success: false, message: 'Server error!'}
        }
    }

    

    const onSubmit = async event => {
        event.preventDefault()
        setIsDisable(true)
        if(arrayCustomers){
            newMailCompose['arrayCustomers'] = arrayCustomers
        }
        newMailCompose['nameCountry'] = JSON.stringify(nameCountry)
        newMailCompose['country'] = JSON.stringify(textCountry)

        //console.log('newMailCompose', newMailCompose)
        const {success, message} = await mailCompose(newMailCompose)
        
        if(success === true) {
            setAlert({ type: 'success', message: message })
            setTimeout(() => window.location.reload(true), 5000)
            setIsDisable(false)
            closeDialog()
            
        }else{
            setAlert({ type: 'danger', message: message })
            setTimeout(() => setAlert(null), 5000)
        }
    }

    const handleChangeSelect = (newValue) => {
        const arrayEmails = newValue;
        let textEmails = "";
        let textNames = "";
        if(arrayEmails){
            arrayEmails.map((arrayEmail, index) => {
                if(index === 0){
                    textEmails = arrayEmail.value;
                    textNames = arrayEmail.name;
                }else{
                    textEmails = textEmails + ',' + arrayEmail.value;
                    textNames = textNames + ',' + arrayEmail.name;
                }
            })
            setNewMailCompose( {...newMailCompose, email: textEmails } )
            if(textNames){
                setArrayCustomers(textNames)
            }
        }
    }

    const handleChangeFromMail = (newValue) => {
        const dataEmail = newValue;
        if(dataEmail){
            setNewMailCompose( {...newMailCompose, fromMail: dataEmail.value } )
        }
    }

    const closeDialog = () => {
        newMailCompose({
            subject: '',
            email: ''
        })
    }

    
    

    //console.log('arrayTam', arrayTam)

    const getContentCountry = item => {
        var result = null
        countries.map((country, index) => {
            if(country.id === item){
                result = (
                    <div key={index} className="form-group">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id={`checkbox-${index}`} onChange={handleChangeCheckBok.bind(this, item, country.location)} />
                                <label htmlFor={`checkbox-${index}`} className="custom-control-label mt-1"><strong>{country.location} ({countryCustomers[item].length})</strong></label>
                            </div>
                        </div>
                    </div>
                )
            }
        })

        return result
    }

    //console.log('arrayChange', arrayChange)

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="pull-left">Mail Compose</h3>
                            </div>
                        </div>
                    </div>

                    <div className='row row-sm'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                            <div className="card"> 
                                <div className="card-header"> 
                                    <h3 className="card-title">Compose new message</h3> 
                                    <div className='choose-country'>
                                        {countries !== undefined && arrayIDCountry.length > 0 && (
                                            arrayIDCountry.map((item, index) => (
                                                <Fragment key={index}>{getContentCountry(item)}</Fragment>
                                            ))
                                        )}
                                    </div>
                                </div> 
                                <Form onSubmit={onSubmit}>
                                    <AlertMessage info={alert} />
                                    <div className="card-body">
                                        <div className="form-group">
                                            <div className="row align-items-center"> 
                                                <label className="col-sm-2">Name</label> 
                                                <div className="col-sm-10"> 
                                                    <input type="text" className="form-control" id="name" name="name" value={name} onChange={onChangeValue} placeholder="Enter Name Email" /> 
                                                </div> 
                                            </div> 
                                        </div>
                                        <div className="form-group">
                                            <div className="row align-items-center"> 
                                                <label className="col-sm-2">From</label> 
                                                <div className="col-sm-10"> 
                                                    <Select isClearable
                                                        onChange={handleChangeFromMail.bind(this)}
                                                        options={fromMails} 
                                                        menuPosition={'fixed'}
                                                        placeholder="Select Mail"
                                                        name="fromMail"
                                                    /> 
                                                </div> 
                                            </div> 
                                        </div>
                                        <div className="form-group">
                                            <div className="row align-items-center"> 
                                                <label className="col-sm-2">To</label> 
                                                <div className="col-sm-10"> 
                                                    <Select isClearable
                                                        onChange={handleChangeSelect.bind(this)}
                                                        options={arrayChange.length > 0 ? arrayChange : optionCustomers} 
                                                        menuPosition={'fixed'}
                                                        placeholder="Select Customer"
                                                        name="customer"
                                                        isMulti
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    /> 
                                                </div> 
                                            </div> 
                                        </div> 
                                        <div className="form-group"> 
                                            <div className="row align-items-center"> 
                                                <label className="col-sm-2">Subject</label> 
                                                <div className="col-sm-10"> 
                                                    <input type="text" className="form-control" id="subject" name="subject" value={subject} onChange={onChangeValue} placeholder="Enter Subject" /> 
                                                </div> 
                                            </div> 
                                        </div>
                                        <div className="form-group"> 
                                            <div className="row"> 
                                                <label className="col-sm-2">Template</label> 
                                                <div className="col-sm-8"> 
                                                    <p><strong>Default Template</strong></p>
                                                </div> 
                                                <div className='col-sm-2'>
                                                    <div className="btn-list ml-auto"> 
                                                        <Button style={{float: 'right'}} className="btn btn-primary btn-with-icon" variant='primary' type='submit' disabled={isDisable}><i className="fe fe-save"></i> Send Mail!!!</Button>
                                                    </div> 
                                                </div>
                                            </div> 
                                        </div> 
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <DefaultTemplate />
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </Form> 
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default MailCompose