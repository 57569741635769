import { Fragment, useContext, useEffect } from 'react';
import { RequestContext } from '../../contexts/RequestContext';
import { useParams, Link } from 'react-router-dom';
import Moment from 'moment';

const DetailRequest = () => {
    //searchRequest
    const { 
        requestState: { booking },
        searchRequest
    } = useContext(RequestContext)

    const { id } = useParams()

    // Start: Get all staffs , []
    useEffect( () => searchRequest(id), [id] ) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('booking', booking)
    let body = null

    if(booking !== null){
        body = (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="content-info mt-5">
                            <div className="header-info text-left">
                                <h3>Detail Request</h3>
                            </div>
                            <div className="body-info mt-3">
                                {booking.species !== 'contact' ? (
                                    <>
                                        <div className='row'>
                                            <div className='col-3'><strong>{booking.typeBooking === 'tour' ? `Tour Name:` : `Cruise Name:`}</strong></div>
                                            <div className='col-9'>{booking.tourName}</div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-3'><strong>Duration:</strong></div>
                                            <div className='col-9'>{booking.duration} day(s)</div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-3'><strong>Route:</strong></div>
                                            <div className='col-9'>{booking.route}</div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-3'><strong>Tour Class:</strong></div>
                                            <div className='col-9'>{booking.tourClass}</div>
                                        </div>
                                    </>
                                ) : null}
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Full Name:</strong></div>
                                    <div className='col-9'>{booking.mrName} {booking.firstName} {booking.lastName}</div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Email:</strong></div>
                                    <div className='col-9'>{booking.email}</div>
                                </div> 
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Phone:</strong></div>
                                    <div className='col-9'>{booking.phone}</div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Country:</strong></div>
                                    <div className='col-9'>{booking.country}</div>
                                </div>
                                {booking.adult !== null ? (
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Adult:</strong></div>
                                    <div className='col-9'>{booking.adult} Pax</div>
                                </div>
                                ) : null }
                                {booking.child !== null ? (
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Children:</strong></div>
                                    <div className='col-9'>{booking.child} Pax</div>
                                </div>
                                ) : null }
                                {booking.infant !== null ? (
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Infants:</strong></div>
                                    <div className='col-9'>{booking.infant} Pax</div>
                                </div>
                                ) : null }
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Start Date:</strong></div>
                                    <div className='col-9'>{Moment(booking.tourDate).format('Do MMM YYYY')}</div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-3'><strong>Requirements:</strong></div>
                                    <div className='col-9'>{booking.messages}</div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-3'>
                                        <Link className="btn btn-success btn-with-icon" to="/requests" title="Back List Requests"><i className="fe fe-corner-up-left"></i> Back List Requests</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body !== null ? body : null}
        </Fragment>
    )
}

export default DetailRequest