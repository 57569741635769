import { FULL_ITINERARIES_LOADED_FAIL, FULL_ITINERARIES_LOADED_SUCCESS, FIND_FULL_ITINERARY, UPDATE_FULL_ITINERARY, DELETE_FULL_ITINERARY, ADD_FULL_ITINERARY, SEARCH_FULL_ITINERARY, COPY_FULL_ITINERARY } from '../contexts/constants';

export const fullItineraryReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case FULL_ITINERARIES_LOADED_SUCCESS:
            return {
                ...state,
                fullItineraries: payload,
                fullItinerariesLoading: false
            }
        case FULL_ITINERARIES_LOADED_FAIL:
            return {
                ...state,
                fullItineraries: [],
                fullItinerariesLoading: false
            }
        case ADD_FULL_ITINERARY:
            {
                return {
                    ...state,
                    fullItineraries: [...state.fullItineraries, payload],
                }
            }
        case COPY_FULL_ITINERARY:
            {
                return {
                    ...state,
                    fullItineraries: [...state.fullItineraries, payload],
                }
            }
        case FIND_FULL_ITINERARY:
            return {
                ...state,
                fullItinerary: payload
            }
        case SEARCH_FULL_ITINERARY:
            return {
                ...state,
                fullItinerary: payload
            }
        case UPDATE_FULL_ITINERARY:
            const newFullItinerary = state.fullItineraries.map(fullItinerary => {
                if (fullItinerary.id === payload.id) {
                    return payload
                } else {
                    return fullItinerary
                }
            })
            return {
                ...state,
                fullItineraries: newFullItinerary
            }
        case DELETE_FULL_ITINERARY:
            return {
                ...state,
                fullItineraries: state.fullItineraries.filter(fullItinerary => fullItinerary.id !== payload)
            }
        default:
            return state
    }
}