import { createContext, useState, useReducer } from 'react';
import { itineraryReducer } from '../reducers/itineraryReducer';
import { apiUrl, ADD_ITINERARY, ITINERARIES_LOADED_FAIL, ITINERARIES_LOADED_SUCCESS, DELETE_ITINERARY, FIND_ITINERARY, UPDATE_ITINERARY } from './constants';
import axios from 'axios';


export const ItineraryContext = createContext()

const ItineraryContextProvider = ({ children }) => {

    // State
    const [itineraryState, dispatch] = useReducer(itineraryReducer, {
        itinerary: null,
        itineraries: [],
        itinerariesLoading: true
    })

    const [showAddItinerary, setAddItinerary] = useState(false)
    const [showUpdateItinerary, setShowUpdateItinerary] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Itineraries
    const getItineraries = async() => {
        try {
            const response = await axios.get(`${apiUrl}/itineraries`)
            if (response.data.success) {
                dispatch({ type: ITINERARIES_LOADED_SUCCESS, payload: response.data.itineraries })
            }
        } catch (error) {
            dispatch({ type: ITINERARIES_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Itinerary
    const addItinerary = async newItinerary => {
        try {
            const response = await axios.post(`${apiUrl}/itineraries`, newItinerary)
            if (response.data.success) {
                dispatch({
                    type: ADD_ITINERARY,
                    payload: response.data.itinerary
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Itinerary when user is updating itinerary
    const findItinerary = itineraryId => {
        const itinerary = itineraryState.itineraries.find(itinerary => itinerary.id === itineraryId)
        dispatch({
            type: FIND_ITINERARY,
            payload: itinerary
        })
    }

    // update Itinerary
    const updateItinerary = async updateItinerary => {
        try {
            const response = await axios.put(`${apiUrl}/itineraries/${updateItinerary.id}`, updateItinerary)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_ITINERARY,
                    payload: response.data.itinerary
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Itinerary
    const deleteItinerary = async itineraryId => {
        try {
            const response = await axios.delete(`${apiUrl}/itineraries/${itineraryId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_ITINERARY,
                    payload: itineraryId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Category Context Data
    const itineraryContextData = {
        itineraryState,
        getItineraries,
        addItinerary,
        findItinerary,
        updateItinerary,
        deleteItinerary,
        showToast,
        setShowToast,
        showAddItinerary,
        setAddItinerary,
        showUpdateItinerary,
        setShowUpdateItinerary
    }

    return ( 
        <ItineraryContext.Provider value = { itineraryContextData } > 
            { children } 
        </ItineraryContext.Provider>
    )


}

export default ItineraryContextProvider