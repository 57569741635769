//export const apiUrl = process.env.NODE_ENV !== 'production' ? 'http://asiaprivateholidays.local/api' : 'https://fierce-wave-20551.herokuapp.com/api'
export const apiUrl = process.env.NODE_ENV !== 'production' ? 'http://asiaprivatetravels.local/api' : 'https://asiaprivatetravels.com/api'
export const apiUrlLink = process.env.NODE_ENV !== 'production' ? 'http://asiaprivatetravels.local' : 'https://asiaprivatetravels.com'

export const LOCAL_STORAGE_TOKEN_NAME = 'learnit-mern'
export const USERS_LOADED_SUCCESS = 'USERS_LOADED_SUCCESS'
export const USERS_LOADED_FAIL = 'USERS_LOADED_FAIL'
export const ADD_USER = 'ADD_USER'
export const FIND_USER = 'FIND_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'

export const POSTS_LOADED_SUCCESS = 'POSTS_LOADED_SUCCESS'
export const POSTS_LOADED_FAIL = 'POSTS_LOADED_FAIL'
export const ADD_POST = 'ADD_POST'
export const DELETE_POST = 'DELETE_POST'
export const FIND_POST = 'FIND_POST'
export const UPDATE_POST = 'UPDATE_POST'

export const CRUISES_LOADED_SUCCESS = 'CRUISES_LOADED_SUCCESS'
export const CRUISES_LOADED_FAIL = 'CRUISES_LOADED_FAIL'
export const ADD_CRUISE = 'ADD_CRUISE'
export const FIND_CRUISE = 'FIND_CRUISE'
export const UPDATE_CRUISE = 'UPDATE_CRUISE'
export const DELETE_CRUISE = 'DELETE_CRUISE'
export const FIND_A_CRUISE = 'FIND_A_CRUISE'

export const ROOMS_LOADED_SUCCESS = 'ROOMS_LOADED_SUCCESS'
export const ROOMS_LOADED_FAIL = 'ROOMS_LOADED_FAIL'

// category
export const CATEGORIES_LOADED_SUCCESS = 'CATEGORIES_LOADED_SUCCESS'
export const CATEGORIES_LOADED_FAIL = 'CATEGORIES_LOADED_FAIL'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const FIND_CATEGORY = 'FIND_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

// materials
export const MATERIALS_LOADED_SUCCESS = 'MATERIALS_LOADED_SUCCESS'
export const MATERIALS_LOADED_FAIL = 'MATERIALS_LOADED_FAIL'
export const ADD_MATERIAL = 'ADD_MATERIAL'
export const DELETE_MATERIAL = 'DELETE_MATERIAL'
export const FIND_MATERIAL = 'FIND_MATERIAL'
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL'

// CUSTOMERS
export const CUSTOMERS_LOADED_SUCCESS = 'CUSTOMERS_LOADED_SUCCESS'
export const CUSTOMERS_LOADED_FAIL = 'CUSTOMERS_LOADED_FAIL'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const FIND_CUSTOMER = 'FIND_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

// Full Itineraries
export const FULL_ITINERARIES_LOADED_SUCCESS = 'FULL_ITINERARIES_LOADED_SUCCESS'
export const FULL_ITINERARIES_LOADED_FAIL = 'FULL_ITINERARIES_LOADED_FAIL'
export const ADD_FULL_ITINERARY = 'ADD_FULL_ITINERARY'
export const DELETE_FULL_ITINERARY = 'DELETE_FULL_ITINERARY'
export const FIND_FULL_ITINERARY = 'FIND_FULL_ITINERARY'
export const SEARCH_FULL_ITINERARY = 'SEARCH_FULL_ITINERARY'
export const UPDATE_FULL_ITINERARY = 'UPDATE_FULL_ITINERARY'
export const COPY_FULL_ITINERARY = 'COPY_FULL_ITINERARY'

// Invoices
export const INVOICES_LOADED_SUCCESS = 'INVOICES_LOADED_SUCCESS'
export const INVOICES_LOADED_FAIL = 'INVOICES_LOADED_FAIL'
export const ADD_INVOICE = 'ADD_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const FIND_INVOICE = 'FIND_INVOICE'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const SEARCH_INVOICE = 'SEARCH_INVOICE'

//Requests
export const REQUESTS_LOADED_SUCCESS = 'REQUESTS_LOADED_SUCCESS'
export const REQUESTS_LOADED_FAIL = 'REQUESTS_LOADED_FAIL'
export const ADD_REQUEST = 'ADD_REQUEST'
export const DELETE_REQUEST = 'DELETE_REQUEST'
export const FIND_REQUEST = 'FIND_REQUEST'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'
export const SEND_REQUEST = 'SEND_REQUEST'
export const FAILED_REQUEST = 'FAILED_REQUEST'
export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const REQUESTS_LOADED_RUN_SUCCESS = 'REQUESTS_LOADED_RUN_SUCCESS'

//ACCOMMODATIONS
export const ACCOMMODATIONS_LOADED_SUCCESS = 'ACCOMMODATIONS_LOADED_SUCCESS'
export const ACCOMMODATIONS_LOADED_FAIL = 'ACCOMMODATIONS_LOADED_FAIL'
export const ADD_ACCOMMODATION = 'ADD_ACCOMMODATION'
export const DELETE_ACCOMMODATION = 'DELETE_ACCOMMODATION'
export const FIND_ACCOMMODATION = 'FIND_ACCOMMODATION'
export const UPDATE_ACCOMMODATION = 'UPDATE_ACCOMMODATION'

//Services
export const SERVICES_LOADED_SUCCESS = 'SERVICES_LOADED_SUCCESS'
export const SERVICES_LOADED_FAIL = 'SERVICES_LOADED_FAIL'
export const ADD_SERVICE = 'ADD_SERVICE'
export const DELETE_SERVICE = 'DELETE_SERVICE'
export const FIND_SERVICE = 'FIND_SERVICE'
export const UPDATE_SERVICE = 'UPDATE_SERVICE'

//cars
export const CARS_LOADED_SUCCESS = 'CARS_LOADED_SUCCESS'
export const CARS_LOADED_FAIL = 'CARS_LOADED_FAIL'
export const ADD_CAR = 'ADD_CAR'
export const DELETE_CAR = 'DELETE_CAR'
export const FIND_CAR = 'FIND_CAR'
export const UPDATE_CAR = 'UPDATE_CAR'

// Itineraries
export const ITINERARIES_LOADED_SUCCESS = 'ITINERARIES_LOADED_SUCCESS'
export const ITINERARIES_LOADED_FAIL = 'ITINERARIES_LOADED_FAIL'
export const ADD_ITINERARY = 'ADD_ITINERARY'
export const DELETE_ITINERARY = 'DELETE_ITINERARY'
export const FIND_ITINERARY = 'FIND_ITINERARY'
export const UPDATE_ITINERARY = 'UPDATE_ITINERARY'

// Guides
export const GUIDES_LOADED_SUCCESS = 'GUIDES_LOADED_SUCCESS'
export const GUIDES_LOADED_FAIL = 'GUIDES_LOADED_FAIL'
export const ADD_GUIDE = 'ADD_GUIDE'
export const DELETE_GUIDE = 'DELETE_GUIDE'
export const FIND_GUIDE = 'FIND_GUIDE'
export const UPDATE_GUIDE = 'UPDATE_GUIDE'

// Todo List
export const TODO_LISTS_LOADED_SUCCESS = 'TODO_LISTS_LOADED_SUCCESS'
export const TODO_LISTS_LOADED_FAIL = 'TODO_LISTS_LOADED_FAIL'
export const ADD_TODO_LIST = 'ADD_TODO_LIST'
export const DELETE_TODO_LIST = 'DELETE_TODO_LIST'
export const FIND_TODO_LIST = 'FIND_TODO_LIST'
export const UPDATE_TODO_LIST = 'UPDATE_TODO_LIST'

// Partners
export const PARTNERS_LOADED_SUCCESS = 'PARTNERS_LOADED_SUCCESS'
export const PARTNERS_LOADED_FAIL = 'PARTNERS_LOADED_FAIL'
export const ADD_PARTNER = 'ADD_PARTNER'
export const DELETE_PARTNER = 'DELETE_PARTNER'
export const FIND_PARTNER = 'FIND_PARTNER'
export const UPDATE_PARTNER = 'UPDATE_PARTNER'

// Destinations
export const DESTINATIONS_LOADED_SUCCESS = 'DESTINATIONS_LOADED_SUCCESS'
export const DESTINATIONS_LOADED_FAIL = 'DESTINATIONS_LOADED_FAIL'

// Countries
export const COUNTRIES_LOADED_SUCCESS = 'COUNTRIES_LOADED_SUCCESS'
export const COUNTRIES_LOADED_FAIL = 'COUNTRIES_LOADED_FAIL'

// Link Payments
export const CHECK_PAYMENT = 'CHECK_PAYMENT'

//Contracts
export const CONTRACTS_LOADED_SUCCESS = 'CONTRACTS_LOADED_SUCCESS'
export const CONTRACTS_LOADED_FAIL = 'CONTRACTS_LOADED_FAIL'
export const SEARCH_CONTRACT = 'SEARCH_CONTRACT'
export const ADD_CONTRACT = 'ADD_CONTRACT'
export const DELETE_CONTRACT = 'DELETE_CONTRACT'
export const FIND_CONTRACT = 'FIND_CONTRACT'
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT'