import { ADD_ITINERARY, ITINERARIES_LOADED_FAIL, ITINERARIES_LOADED_SUCCESS, DELETE_ITINERARY, FIND_ITINERARY, UPDATE_ITINERARY } from '../contexts/constants';

export const itineraryReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ITINERARIES_LOADED_SUCCESS:
            return {
                ...state,
                itineraries: payload,
                itinerariesLoading: false
            }
        case ITINERARIES_LOADED_FAIL:
            return {
                ...state,
                itineraries: [],
                itinerariesLoading: false
            }
        case ADD_ITINERARY:
            {
                return {
                    ...state,
                    itineraries: [...state.itineraries, payload],
                }
            }
        case FIND_ITINERARY:
            return {
                ...state,
                itinerary: payload
            }
        case UPDATE_ITINERARY:
            const newItinerary = state.itineraries.map(itinerary => {
                if (itinerary.id === payload.id) {
                    return payload
                } else {
                    return itinerary
                }
            })
            return {
                ...state,
                itineraries: newItinerary
            }
        case DELETE_ITINERARY:
            return {
                ...state,
                itineraries: state.itineraries.filter(itinerary => itinerary.id !== payload)
            }
        default:
            return state
    }

}