import { createContext, useState, useReducer } from 'react';
import { accommodationReducer } from '../reducers/accommodationReducer';
import { apiUrl, ADD_ACCOMMODATION, ACCOMMODATIONS_LOADED_FAIL, ACCOMMODATIONS_LOADED_SUCCESS, DELETE_ACCOMMODATION, FIND_ACCOMMODATION, UPDATE_ACCOMMODATION } from './constants';
import axios from 'axios';


export const AccommodationContext = createContext()

const AccommodationContextProvider = ({ children }) => {

    // State
    const [accommodationState, dispatch] = useReducer(accommodationReducer, {
        accommodation: null,
        accommodations: [],
        accommodationsLoading: true
    })

    const [showAddAccommodation, setAddAccommodation] = useState(false)
    const [showUpdateAccommodation, setShowUpdateAccommodation] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Accommodations
    const getAccommodations = async() => {
        try {
            const response = await axios.get(`${apiUrl}/accommodations`)
            if (response.data.success) {
                dispatch({ type: ACCOMMODATIONS_LOADED_SUCCESS, payload: response.data.accommodations })
            }
        } catch (error) {
            dispatch({ type: ACCOMMODATIONS_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Accommodation
    const addAccommodation = async newAccommodation => {
        try {
            const response = await axios.post(`${apiUrl}/accommodations`, newAccommodation)
            if (response.data.success) {
                dispatch({
                    type: ADD_ACCOMMODATION,
                    payload: response.data.accommodation
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Accommodation when user is updating Accommodation
    const findAccommodation = accommodationId => {
        const accommodation = accommodationState.accommodations.find(accommodation => accommodation.id === accommodationId)
        dispatch({
            type: FIND_ACCOMMODATION,
            payload: accommodation
        })
    }

    // Update Accommodation
    const updateAccommodation = async updateAccommodation => {
        try {
            const response = await axios.put(`${apiUrl}/accommodations/${updateAccommodation.id}`, updateAccommodation)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_ACCOMMODATION,
                    payload: response.data.accommodation
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Accommodation
    const deleteAccommodation = async accommodationId => {
        try {
            const response = await axios.delete(`${apiUrl}/accommodations/${accommodationId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_ACCOMMODATION,
                    payload: accommodationId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Accommodation Context Data
    const accommodationContextData = {
        accommodationState,
        getAccommodations,
        addAccommodation,
        findAccommodation,
        updateAccommodation,
        deleteAccommodation,
        showToast,
        setShowToast,
        showAddAccommodation,
        setAddAccommodation,
        showUpdateAccommodation,
        setShowUpdateAccommodation
    }

    return ( 
        <AccommodationContext.Provider value = { accommodationContextData } > 
            { children } 
        </AccommodationContext.Provider>
    )


}

export default AccommodationContextProvider