import { Fragment, useContext, useState } from 'react';
import { RequestContext } from '../../../contexts/RequestContext';
import { InvoiceContext } from '../../../contexts/InvoiceContext';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Moment from 'moment';
import ListAddService from './ListAddService';

const CreateInvoice = () => {

    // Context
    const {
        requestState: { booking },
        showAddInvoice,
        setShowAddInvoice,
        setShowToast
    } = useContext(RequestContext)

    // Context
    const {
        addInvoice
    } = useContext(InvoiceContext)

    const [isValid, setIsValid] = useState(false);
    const [durationValid, setDurationValid] = useState(false);
    const [orderValid, setOrderValid] = useState(false);

    const addDays = (date, days) => {
        var result = new Date(date);
        if (days > 0) {
            result.setDate(result.getDate() + days);
        } else {
            result = date;
        }
        //console.log('fsadf', Moment(result).format('MM DD YYYY'))
        return Moment(result).format('YYYY-MM-DD');
    }

    var dateEndDefault = addDays(booking.tourDate, booking.duration)

    //console.log('booking', booking)

    // State
    const [newInvoice, setNewInvoice] = useState({
        invoidID: '',
        mrName: booking.mrName,
        firstName: booking.firstName,
        lastName: booking.lastName,
        amountPay: '',
        country: booking.country,
        phone: booking.phone,
        email: booking.email,
        description: '',
        service: '',
        amount: '',
        payment: 'new',
        dateStart: booking.tourDate,
        dateEnd: dateEndDefault,
        dateDeposits: Moment(new Date()).format('YYYY-MM-DD'),
        dateFull: '',
        adult: booking.adult,
        child: booking.child,
        infant: booking.infant,
        duration: booking.duration,
        discount: '',
        duedate: '',
        checkDiscount: 'money',
        booking_id: booking.id,
        user_id: booking.sale.id
    })

    const { description, amount, dateStart, dateEnd, discount, amountPay } = newInvoice

    const onChangeValue = event => setNewInvoice({...newInvoice, [event.target.name]: event.target.value })
    const onChangeNumber = event => setNewInvoice({...newInvoice, [event.target.name]: Number(event.target.value) })

    const [numberKey, setNumberKey] = useState(0);
    const [arrServices, setArrServices] = useState([])

    let optionNumbers = []
    let optionOrders = []
    let optionDurations = []

    for (var j = 1; j < 32; j++) {
        optionNumbers = [...optionNumbers,
            {
                value: j,
                label: j + ' Pax',
            }
        ]
    }

    for (var k = 1; k < 32; k++) {
        optionOrders = [...optionOrders,
            {
                value: `order-${k}`,
                label: `Order ${k}`,
            }
        ]
    }

    for (var z = 1; z < 32; z++) {
        optionDurations = [...optionDurations,
            {
                value: z,
                label: z,
            }
        ]
    }

    const onSubmit = async event => {
        event.preventDefault()
        newInvoice['service'] = JSON.stringify(arrServices)
        newInvoice['invoidID'] = `${Moment(dateStart).format('YYMMDD')}_${convertString(`${booking.firstName} ${booking.lastName}`)}_APH`
        const {success, message} = await addInvoice(newInvoice)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setNewInvoice({
            invoidID: '',
            mrName: booking.mrName,
            firstName: booking.firstName,
            lastName: booking.lastName,
            amountPay: '',
            country: booking.country,
            phone: booking.phone,
            email: booking.email,
            description: '',
            service: '',
            amount: '',
            payment: 'new',
            dateStart: booking.tourDate,
            dateEnd: dateEndDefault,
            dateDeposits: Moment(new Date()).format('YYYY-MM-DD'),
            dateFull: '',
            adult: booking.adult,
            child: booking.child,
            infant: booking.infant,
            duration: booking.duration,
            discount: '',
            duedate: '',
            checkDiscount: 'money',
            booking_id: booking.id,
            user_id: booking.sale.id
        })
        setShowAddInvoice(false)
    }

    const setTotal = (valueTotal) => {
        //setNewDepot( {...newDepot, total: valueTotal } )
    }

    const addButtonService = (index) => {
        setNumberKey(index + 1)
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "adult"){
                setIsValid(true)
                setNewInvoice( {...newInvoice, adult: dataValue } )
            }
            if(actionMeta.name === "child"){
                setNewInvoice( {...newInvoice, child: dataValue } )
            }
            if(actionMeta.name === "infant"){
                setNewInvoice( {...newInvoice, infant: dataValue } )
            }
            if(actionMeta.name === "duration"){
                setDurationValid(true)
                setNewInvoice( {...newInvoice, duration: dataValue } )
                var result = new Date(dateStart);
                if(dataValue > 0){
                    result.setDate(result.getDate() + dataValue);
                }else{
                    result = dateStart;
                }
                setNewInvoice( {...newInvoice, dateEnd: Moment(result).format('YYYY-MM-DD') } )
            }
            if(actionMeta.name === "order-pay"){
                setOrderValid(true);
                //setNewInvoice( {...newInvoice, linkPay: `${booking.website}/payment/${dataValue}/${booking.id}` } );
                setNewInvoice( {...newInvoice, orderPay: dataValue } );
            }
        }
    }

    const convertString = (stringTexts) => {
        
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ"    
        ];

        for (var i=0; i<AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            stringTexts = stringTexts.replace(re, char);
        }

       // console.log('stringTexts', stringTexts)

        stringTexts = stringTexts.split(' ');
        var minText = stringTexts[0]

        stringTexts.map((itemText, dem) => { 
            if(dem > 0){
                if(itemText.length < minText){
                    minText = itemText;
                }
            }
        })
        return minText.toUpperCase()
        
    }


    //console.log('numberKey', numberKey)

    return (
        <Fragment>
            <Modal show={showAddInvoice} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add Invoice
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <div className='main-content-body-invoice'>
                        <div className='card card-invoice'>
                            <div className='card-body'>
                                <div className="invoice-header"> 
                                    <h1 className="invoice-title">Invoice</h1> 
                                    <div className="billed-from"> 
                                        <h6>Asia Links Travel, Jsc.</h6> 
                                        <p><strong>Address:</strong> 25, Group 7, Tan Mai, Hoang Mai, Hanoi, Vietnam<br /><strong>Tel No:</strong> +84 987 975 514<br /><strong>Email:</strong> info@asiaprivateholidays.com</p>
                                    </div>
                                </div>
                                <div className="row mg-t-20"> 
                                    <div className="col-md"> 
                                        <label className="tx-gray-600"><strong>Billed To</strong></label> 
                                        <div className="billed-to"> 
                                            <h6>{booking.mrName} {booking.firstName} {booking.lastName},</h6> 
                                            <p><strong>Country:</strong> {booking.country}<br /><strong>Tel No:</strong> {booking.phone}<br /><strong>Email:</strong> {booking.email}</p>
                                        </div> 
                                    </div> 
                                    <div className="col-md"> 
                                        <label className="tx-gray-600"><strong>Invoice Information</strong></label> 
                                        <p className="invoice-info-row"><span>Invoice No</span> <span>{Moment(dateStart).format('YYMMDD')}_{convertString(`${booking.firstName} ${booking.lastName}`)}_APH</span></p>
                                        <p className="invoice-info-row"><span>Project ID</span> <span>APH_{Moment(booking.created_at).format('YYMMDD')}_{booking.id}</span></p>
                                        <p className="invoice-info-row"><span>Tour Name</span> <span>{booking.tourName}</span></p>
                                        <p className="invoice-info-row"><span>Arrival Date:</span> <span>{Moment(dateStart).format('MMM DD, YYYY')}</span></p>
                                        <p className="invoice-info-row"><span>Departure Date:</span> <span>{Moment(dateEnd).format('MMM DD, YYYY')}</span></p>
                                    </div> 
                                </div>
                                <div className='period-trip'>
                                    <h6>Period of Trip</h6>
                                </div>
                                <Row className='mg-t-20'>
                                    <Col className="col-md">
                                        <Row>
                                            <Col>
                                                <label><strong>From</strong></label>
                                                <input type="date" className="form-control" id="dateStart" name="dateStart" value={dateStart} onChange={onChangeValue} required />
                                            </Col>
                                            <Col>
                                                <label><strong>To</strong></label>
                                                <input type="date" className="form-control" id="dateEnd" name="dateEnd" value={dateEnd} onChange={onChangeValue} required />
                                            </Col>
                                            <Col>
                                                <label><strong>Duration</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    options={optionDurations} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Duration"
                                                    name="duration"
                                                />
                                                {/*<input type="number" className="form-control" id="duration" name="duration" placeholder="No. Day" onChange={onChangeNumber} required />*/}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-md">
                                        <Row>
                                            <Col>
                                                <label><strong>{`Adult ( > 12)`}</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    options={optionNumbers} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Adult"
                                                    name="adult"
                                                />
                                            </Col>
                                            <Col>
                                                <label><strong>{`Child (4 -> 12)`}</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    options={optionNumbers} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Child"
                                                    name="child"
                                                />
                                            </Col>
                                            <Col>
                                                <label><strong>{`Infant (0 - 3)`}</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    options={optionNumbers} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Infant"
                                                    name="infant"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {numberKey > 0 ? (
                                    <ListAddService arrServices={arrServices} setArrServices={setArrServices} setTotal={setTotal} numberKey={numberKey} newInvoice={newInvoice} setNewInvoice={setNewInvoice} discount={discount} onChangeValue={onChangeValue} description={description} amount={amount} />
                                ) : ''}
                                <div className="form-group d-inline-block w-100">
                                    <Button className="float-right btn btn-success btn-with-icon mt-3" variant='success' onClick={addButtonService.bind(this, numberKey)}><i className="fe fe-plus-circle"></i> Add Service</Button>
                                </div>
                                <Row className='mg-t-20'>
                                    <Col md={{ span: 4, offset: 4 }}>
                                        <div className='info-transaction'>
                                            <h4 className='text-center'>Create Link Payment</h4>
                                            <div className="form-group">
                                                <label><strong>Transaction OrderInfo</strong></label>
                                                <input type="text" className="form-control" id="vpc_OrderInfo" name="vpc_OrderInfo" defaultValue={`#${booking.id}`} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label><strong>Order Pay</strong></label>
                                                <Select isClearable
                                                    onChange={handleChangeSelect.bind(this)}
                                                    options={optionOrders} 
                                                    menuPosition={'fixed'}
                                                    placeholder="Select Order"
                                                    name="order-pay"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label><strong>Purchase Amount</strong></label>
                                                <input type="number" className="form-control" id="amountPay" name="amountPay" placeholder="No. Amount" onChange={onChangeNumber} value={amountPay} required />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        {numberKey > 0 ? (
                            <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit' disabled={isValid === true && durationValid === true && orderValid === true ? '' : 'disabled'} ><i className="fe fe-save"></i> Save!!!</Button>
                        ) : null}
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default CreateInvoice