import { DESTINATIONS_LOADED_FAIL, DESTINATIONS_LOADED_SUCCESS } from '../contexts/constants';

export const destinationReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case DESTINATIONS_LOADED_SUCCESS:
            return {
                ...state,
                destinations: payload,
                destinationsLoading: false
            }
        case DESTINATIONS_LOADED_FAIL:
            return {
                ...state,
                destinations: [],
                destinationsLoading: false
            }
        default:
            return state
    }
}