import { Fragment, useState } from 'react';
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select';

const SendRequest = ( { bookingID, users, sendRequest } ) => {

    let optionSales = []

    const [showDelete, setShowDelete] = useState(false)
    const [sales, setSales] = useState(0)
    const handleClose = () => setShowDelete(false)
    //const handleShow = () => setShowDelete(true)

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    const handleChangeSelect = (newValue, actionMeta) => {
        const data = newValue;
        setSales(data.value)
        //console.log('data', data)
        setShowDelete(true)
    }

    const sendConfirm = id => {
        var textId = id + '-' + sales
        sendRequest(textId)
        handleClose()
        //window.location.reload()
    }

    return (
        <Fragment>
            <Select isClearable
                onChange={handleChangeSelect.bind(this)}
                options={optionSales} 
                menuPosition={'fixed'}
                placeholder="Choose Sales"
                name="sales"
            />
            <Modal show={showDelete} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Send Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to send request this?</Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary btn-with-icon" variant="secondary" onClick={handleClose}>
                        <i className="fe fe-x-circle"></i> Cancel
                    </Button>
                    <Button className="btn btn-danger btn-with-icon" variant="primary" onClick={sendConfirm.bind(this, bookingID)} >
                        <i className="fe fe-mail"></i> Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default SendRequest