import { Fragment, useContext, useState, useEffect } from 'react';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { DestinationContext } from '../../contexts/DestinationContext';
import { ServiceContext } from '../../contexts/ServiceContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddItinerary = () => {

    // Context
    const {showAddItinerary, setAddItinerary, addItinerary, setShowToast} = useContext(ItineraryContext)

    const { 
        destinationState: { destinations },
        getDestinations 
    } = useContext(DestinationContext)

    const {
        serviceState: { services },
        getServices
    } = useContext(ServiceContext)

    // State
    const [newItinerary, setNewItinerary] = useState({
        name: '',
        nameVN: '',
        detail: '',
        detailVN: ''
    })

    const [totalService, setTotalService] = useState(0)

    const { name, nameVN, detail, detailVN } = newItinerary

    const onChangeValue = event => setNewItinerary( {...newItinerary, [event.target.name]: event.target.value } )

    // Start: Get all data destination, []
    useEffect( () => getDestinations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all data service, []
    useEffect( () => getServices(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('services', services)
    let optionServices = []
    let optionDestinations = []

    if(destinations.length > 0){
        destinations.map((destination) => 
            optionDestinations = [ ...optionDestinations, 
                {
                    value: destination.id,
                    label: destination.name,
                }
            ]
        )
    }

    if(services.length > 0){
        services.map((service) => 
            optionServices = [ ...optionServices, 
                {
                    value: service.id,
                    label: service.name,
                    price: service.amount
                }
            ]
        )
    }

    const handleChange = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === "destination"){
                setNewItinerary( {...newItinerary, destination: dataValue } )
            }
        }
    }

    const handleChangeService = (newValue, actionMeta) => {
        const arrayServices = newValue;
        let textServices = "";
        let tong = 0;
        if(arrayServices){
            arrayServices.map((service, index) => {
                if(index === 0){
                    textServices = service.value;
                    tong = service.price;
                }else{
                    textServices = textServices + ',' + service.value;
                    tong = tong + service.price;
                }
            })
            //setTotalService
            if(tong > 0){
                setTotalService(tong)
                setNewItinerary( {...newItinerary, services: textServices } )
            }
            //console.log('textServices', tong) totalService

            //setNewItinerary( {...newItinerary, meal: textMeal } )
        }
        
    }

    const onSubmit = async event => {
        event.preventDefault()
        newItinerary['totalService'] = totalService;
        //console.log('newItinerary', newItinerary)
        const {success, message} = await addItinerary(newItinerary)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const closeDialog = () => {
        setNewItinerary({
            name: '',
            nameVN: '',
            detail: '',
            detailVN: ''
        })
        setAddItinerary(false)
    }

    return (
        <Fragment>
            <Modal show={showAddItinerary} onHide={closeDialog}
                size="full"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Day by Day Itinerary
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Name English</strong></label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Enter Itinerary Name" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </Col>
                                <Col>
                                    <label><strong>Name VN</strong></label>
                                    <input type="text" className="form-control" id="nameVN" name="nameVN" placeholder="Enter Itinerary Name" value={nameVN} onChange={onChangeValue} />
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Destination</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        options={optionDestinations}
                                        menuPosition={'fixed'}
                                        placeholder="Select Destination"
                                        name="destination"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Services</strong></label>
                                    <Select isClearable
                                        onChange={handleChangeService.bind(this)}
                                        options={optionServices}
                                        menuPosition={'fixed'}
                                        placeholder="Select Service"
                                        name="service"
                                        isMulti
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Detail English</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={detail ? detail : ''}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setNewItinerary( {...newItinerary, detail: data } )
                                        } }
                                    />
                                </Col>
                                <Col>
                                    <label><strong>Detail VN</strong></label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={detailVN ? detailVN : ''}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setNewItinerary( {...newItinerary, detailVN: data } )
                                        } }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <p><strong>Total:</strong> <span className="tx-primary tx-bold">${Number(totalService).toFixed(2)}</span></p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddItinerary