import { Fragment, useState } from 'react';

const ItemAddService = ( { arrServices, setArrServices, keyItem, itemTotal } ) => {

    const [quantity, setQuantity] = useState(0)
    const [price, setPrice] = useState(0)
    const [name, setName] = useState("")
    const [subDescription, setSubDescription] = useState("")

    const onChangeValue = (index, event) => {
        if (!arrServices[index]) {
            arrServices[index] = {};
        }
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        arrServices[index][fieldName] =  event.target.value;
        if(fieldName === "name"){
            setName( event.target.value )
        }

        if(fieldName === "subDescription"){
            setSubDescription( event.target.value )
        }
        
    }

    const onChangeTextQuantity = (index, event) => {
        if (!arrServices[index]) {
            arrServices[index] = {};
        }
        
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        arrServices[index][fieldName] =  event.target.value;
        arrServices[index]['totalPrice'] =  price*event.target.value;
        setQuantity( event.target.value )
        dataItemTotal(event.target.value, price, arrServices)
    }

    const onChangeTextPrice = (index, event) => {
        if (!arrServices[index]) {
            arrServices[index] = {};
        }
        
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        arrServices[index][fieldName] =  event.target.value;
        arrServices[index]['totalPrice'] =  quantity*event.target.value;

        setPrice( event.target.value )
        dataItemTotal(quantity, event.target.value, arrServices)
        
    }

    const dataItemTotal = (quantity, price, arrSuppliers) => {
        itemTotal(quantity*price, keyItem)
        setArrServices(arrSuppliers)
    }

    return (
        <Fragment>
            <tr>
                <td>
                    <input type="text" className="form-control" id="name" name={`name-${keyItem}`} value={name} placeholder="Service Name" onChange={onChangeValue.bind(this, keyItem)} />
                </td>
                <td className="tx-12">
                    <textarea className="form-control" id="subDescription" placeholder="Description" name={`subDescription-${keyItem}`} value={subDescription} spellCheck="false" onChange={onChangeValue.bind(this, keyItem)}></textarea>
                </td>
                <td className="tx-center">
                    <input type="number" className="form-control" id="quantity" name={`quantity-${keyItem}`} value={quantity} placeholder="No. Number" onChange={onChangeTextQuantity.bind(this, keyItem)} />
                </td>
                <td className="tx-right">
                    <input type="number" className="form-control" id="price" name={`price-${keyItem}`} value={price} placeholder="No. Price" onChange={onChangeTextPrice.bind(this, keyItem)} />
                </td>
                <td className="tx-right" style={ {lineHeight: "40px"} }>
                    <strong>${quantity*price}</strong>
                    <input type="hidden" className="form-control" id="totalPrice" name={`totalPrice-${keyItem}`} value={quantity*price} />
                </td>
            </tr>
        </Fragment>
    )
}

export default ItemAddService