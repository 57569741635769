import { Fragment, useState } from "react"
import {Modal, Button} from 'react-bootstrap';

const FailedRequest = ( { bookingId, failedRequest } ) => {

    const [showDelete, setShowDelete] = useState(false)
    const handleClose = () => setShowDelete(false)
    const handleShow = () => setShowDelete(true)

    const deleteConfirm = id => {
        failedRequest(id)
        handleClose()
    }

    return (
        <Fragment>
            <Button style={{display: 'inline-block'}} className="btn btn-warning btn-with-icon" onClick={handleShow}>
                <i className="fe fe-play"></i> Failed
            </Button>
            <Modal show={showDelete} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Request Failed</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to request failed this?</Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary btn-with-icon" variant="secondary" onClick={handleClose}>
                        <i className="fe fe-x-circle"></i> Cancel
                    </Button>
                    <Button className="btn btn-danger btn-with-icon" variant="primary" onClick={deleteConfirm.bind(this, bookingId)} >
                        <i className="fe fe-play"></i> Failed
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )

}

export default FailedRequest