import { Fragment } from 'react';
import ListComplete from './ListComplete';

const Complete = () => {
    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="pull-left">List Complete</h3>
                            </div>
                        </div>
                    </div>
                    
                    <ListComplete />
                </div>
            </div>
        </Fragment>
    )
}

export default Complete