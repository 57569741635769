import { Fragment, useContext, useEffect } from 'react';
import { AccommodationContext } from '../../contexts/AccommodationContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Spinner, Card} from 'react-bootstrap';
import UpdateAccommodation from './UpdateAccommodation';
import DeleteModal from '../../components/layout/DeleteModal';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListAccommodation = () => {

    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)
    const { 
        accommodationState: { accommodation, accommodations, accommodationsLoading },
        getAccommodations,
        findAccommodation,
        deleteAccommodation,
        setShowUpdateAccommodation
    } = useContext(AccommodationContext)

    const chooseAccommodation = accommodationId => {
        findAccommodation(accommodationId)
        setShowUpdateAccommodation(true)
    }

    // Start: Get all Itineraries , []
    useEffect( () => getAccommodations(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    if(accommodationsLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(accommodations.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a accommodation
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Hotline</th>
                                                <th>Email</th>
                                                <th>Destination</th>
                                                <th>Code Accommodation</th>
                                                <th className="w-220">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accommodations.map((accommodation, index) => (
                                            <tr key={index}>
                                                <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                <td style={ {lineHeight: "38px"} }>{accommodation.name}</td>
                                                <td style={ {lineHeight: "38px"} }>{accommodation.hotline}</td>
                                                <td style={ {lineHeight: "38px"} }>{accommodation.email}</td>
                                                <td style={ {lineHeight: "38px"} }>{accommodation.destinationName}</td>
                                                <td style={ {lineHeight: "38px"} }>{accommodation.codeAccom}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            <button className="btn btn-success btn-with-icon btn-block" onClick={chooseAccommodation.bind(this, accommodation.id)}><i className="fe fe-edit"></i> Edit</button>
                                                        </div>
                                                        <div className="col-sm">
                                                            <DeleteModal idProps={accommodation.id} deleteFunc={deleteAccommodation} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body}
            {accommodation !== null && <UpdateAccommodation />}
        </Fragment>
    )
}

export default ListAccommodation
