import { Fragment, useState } from 'react';
import ItemDurationItinerary from './ItemDurationItinerary';

const ListDurationItinerary = ( { numberDuration, arrItineraries, setArrItineraries, adult, child, infant, arrival, newFullItinerary, setNewFullItinerary } ) => {

    const [allTotal, setAllTotal] = useState(0)
    const [arrTotal, setArrTotal] = useState([])

    Array(numberDuration).fill(1).map((option, index) => {
        if (!arrTotal[index]) {
            arrTotal[index] = [];
        }
    })

    
    const itemTotal = (total, item) => {
        if(total > 0){
            arrTotal[item].push(total)
            totalFnc(arrTotal)
        }
    }

    const totalFnc = (arrTotal) => {
        var totalAll = 0
        // eslint-disable-next-line array-callback-return
        arrTotal.map((item) => {
            if(item[item.length -1] !== undefined){
                totalAll += Number(item[item.length -1])
            }
        })
        setAllTotal(totalAll)
        setNewFullItinerary( {...newFullItinerary, totalLand: totalAll } )
        //setTotal(totalAll)
        /*if( itemRadio === "money" ){
            setNewInvoice( {...newInvoice, amount: (totalAll - discount) } )
        }else{
            setNewInvoice( {...newInvoice, amount: Number((totalAll - ((discount/100)*totalAll)).toFixed(2)) } )
        }*/
    }

    //console.log('allTotal', allTotal)

    return (
        <Fragment>
            {
                Array(numberDuration).fill(1).map((option, index) => (
                    <ItemDurationItinerary key={index} keyItem={index} arrItineraries={arrItineraries} setArrItineraries={setArrItineraries} itemTotal={itemTotal} adult={adult} child={child} infant={infant} arrival={arrival} />
                    )
                )
            }
            <div className="form-group">
                <h3>Total Land tour: ${allTotal}</h3>
            </div>
        </Fragment>
    )
}

export default ListDurationItinerary