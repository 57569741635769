import { Fragment, useContext, useEffect } from 'react';
//import { ContractContext } from '../../contexts/ContractContext';
//import { AuthContext } from '../../contexts/AuthContext';
//import {Spinner, Card} from 'react-bootstrap';


import ContractTable from './ContractTable';

const ContractController = ({ dataContracts }) => {

    // Context
    

    



    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="pull-left">List Contracts</h3>
                            </div>
                        </div>
                    </div>
                    <ContractTable contracts={dataContracts} />
                </div>
            </div>
        </Fragment>
    )

}

export default ContractController;