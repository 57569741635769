import { createContext, useState, useReducer } from 'react';
import { fullItineraryReducer } from '../reducers/fullItineraryReducer';
import { apiUrl, FULL_ITINERARIES_LOADED_FAIL, FULL_ITINERARIES_LOADED_SUCCESS, FIND_FULL_ITINERARY, UPDATE_FULL_ITINERARY, DELETE_FULL_ITINERARY, ADD_FULL_ITINERARY, SEARCH_FULL_ITINERARY, COPY_FULL_ITINERARY } from './constants';
import axios from 'axios';


export const FullItineraryContext = createContext()

const FullItineraryContextProvider = ({ children }) => {

    // State
    const [fullItineraryState, dispatch] = useReducer(fullItineraryReducer, {
        fullItinerary: null,
        fullItineraries: [],
        fullItinerariesLoading: true
    })

    const [showAddFullItinerary, setAddFullItinerary] = useState(false)
    const [showUpdateFullItinerary, setShowUpdateFullItinerary] = useState(false)
    const [showCopyFullItinerary, setShowCopyFullItinerary] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Full Itineraries
    const getFullItineraries = async() => {
        try {
            const response = await axios.get(`${apiUrl}/full-itineraries`)
            if (response.data.success) {
                dispatch({ type: FULL_ITINERARIES_LOADED_SUCCESS, payload: response.data.fullItineraries })
            }
        } catch (error) {
            dispatch({ type: FULL_ITINERARIES_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Full Itinerary
    const addFullItinerary = async newFullItinerary => {
        try {
            const response = await axios.post(`${apiUrl}/full-itineraries`, newFullItinerary)
            if (response.data.success) {
                dispatch({
                    type: ADD_FULL_ITINERARY,
                    payload: response.data.fullItinerary
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Full Itinerary when user is updating Full Itinerary
    const findFullItinerary = fullItineraryId => {
        const fullItinerary = fullItineraryState.fullItineraries.find(fullItinerary => fullItinerary.id === fullItineraryId)
        dispatch({
            type: FIND_FULL_ITINERARY,
            payload: fullItinerary
        })
    }

    // update Full Itinerary
    const updateFullItinerary = async updateFullItinerary => {
        try {
            const response = await axios.put(`${apiUrl}/full-itineraries/${updateFullItinerary.id}`, updateFullItinerary)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_FULL_ITINERARY,
                    payload: response.data.fullItinerary
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // copy Full Itinerary
    const copyFullItinerary = async copyFullItinerary => {
        try {
            const response = await axios.post(`${apiUrl}/full-itineraries/${copyFullItinerary.id}`, copyFullItinerary)
            if (response.data.success) {
                dispatch({
                    type: COPY_FULL_ITINERARY,
                    payload: response.data.fullItinerary
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Search Full Itinerary
    const searchFullItinerary = async fullItineraryId => {
        try {
            const response = await axios.get(`${apiUrl}/full-itineraries/${fullItineraryId}`)
            if (response.data.success) {
                dispatch({
                    type: SEARCH_FULL_ITINERARY,
                    payload: response.data.fullItinerary
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Delete Full Itinerary
    const deleteFullItinerary = async fullItineraryId => {
        try {
            const response = await axios.delete(`${apiUrl}/full-itineraries/${fullItineraryId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_FULL_ITINERARY,
                    payload: fullItineraryId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Full Itinerary Context Data
    const fullItineraryContextData = {
        fullItineraryState,
        getFullItineraries,
        addFullItinerary,
        copyFullItinerary,
        findFullItinerary,
        searchFullItinerary,
        updateFullItinerary,
        deleteFullItinerary,
        showToast,
        setShowToast,
        showAddFullItinerary,
        setAddFullItinerary,
        showUpdateFullItinerary,
        setShowUpdateFullItinerary,
        showCopyFullItinerary,
        setShowCopyFullItinerary
    }

    return ( 
        <FullItineraryContext.Provider value = { fullItineraryContextData } > 
            { children } 
        </FullItineraryContext.Provider>
    )

}

export default FullItineraryContextProvider