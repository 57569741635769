import { createContext, useState, useReducer } from 'react';
import { partnerReducer } from '../reducers/partnerReducer';
import { apiUrl, PARTNERS_LOADED_SUCCESS, PARTNERS_LOADED_FAIL, ADD_PARTNER, DELETE_PARTNER, FIND_PARTNER, UPDATE_PARTNER } from './constants';
import axios from 'axios';


export const PartnerContext = createContext()

const PartnerContextProvider = ({ children }) => {

    // State
    const [partnerState, dispatch] = useReducer(partnerReducer, {
        partner: null,
        partners: [],
        partnersLoading: true
    })

    const [showAddPartner, setAddPartner] = useState(false)
    const [showUpdatePartner, setShowUpdatePartner] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Partners
    const getPartners = async() => {
        try {
            const response = await axios.get(`${apiUrl}/partners`)
            if (response.data.success) {
                dispatch({ type: PARTNERS_LOADED_SUCCESS, payload: response.data.partners })
            }
        } catch (error) {
            dispatch({ type: PARTNERS_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Partner
    const addPartner = async newPartner => {
        try {
            const response = await axios.post(`${apiUrl}/partners`, newPartner)
            if (response.data.success) {
                dispatch({
                    type: ADD_PARTNER,
                    payload: response.data.partner
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Partner when user is updating Partner
    const findPartner = partnerId => {
        const partner = partnerState.partners.find(partner => partner.id === partnerId)
        dispatch({
            type: FIND_PARTNER,
            payload: partner
        })
    }

    // update Partner
    const updatePartner = async updatePartner => {
        try {
            const response = await axios.put(`${apiUrl}/partners/${updatePartner.id}`, updatePartner)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_PARTNER,
                    payload: response.data.partner
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Partner
    const deletePartner = async partnerId => {
        try {
            const response = await axios.delete(`${apiUrl}/partners/${partnerId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_PARTNER,
                    payload: partnerId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Partner Context Data
    const partnerContextData = {
        partnerState,
        getPartners,
        addPartner,
        findPartner,
        updatePartner,
        deletePartner,
        showToast,
        setShowToast,
        showAddPartner,
        setAddPartner,
        showUpdatePartner,
        setShowUpdatePartner
    }

    return ( 
        <PartnerContext.Provider value = { partnerContextData } > 
            { children } 
        </PartnerContext.Provider>
    )

}

export default PartnerContextProvider