import { REQUESTS_LOADED_RUN_SUCCESS, REQUESTS_LOADED_FAIL, REQUESTS_LOADED_SUCCESS, FIND_REQUEST, UPDATE_REQUEST, DELETE_REQUEST, ADD_REQUEST, SEND_REQUEST, FAILED_REQUEST, SEARCH_REQUEST } from '../contexts/constants';

export const requestReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case REQUESTS_LOADED_RUN_SUCCESS:
            return {
                ...state,
                bookings: payload,
                bookingsLoading: false
            }
        case REQUESTS_LOADED_SUCCESS:
            return {
                ...state,
                bookings: payload,
                bookingsLoading: false
            }
        case REQUESTS_LOADED_FAIL:
            return {
                ...state,
                bookings: [],
                bookingsLoading: false
            }
        case ADD_REQUEST:
            {
                return {
                    ...state,
                    bookings: [...state.bookings, payload],
                }
            }
        case FIND_REQUEST:
            return {
                ...state,
                booking: payload
            }
        case SEARCH_REQUEST:
            return {
                ...state,
                booking: payload
            }
        case UPDATE_REQUEST:
            const newBook = state.bookings.map(booking => {
                if (booking.id === payload.id) {
                    return payload
                } else {
                    return booking
                }
            })
            return {
                ...state,
                bookings: newBook
            }
        case DELETE_REQUEST:
            return {
                ...state,
                bookings: state.bookings.filter(booking => booking.id !== payload)
            }
        case SEND_REQUEST:
            return {
                ...state,
                bookings: state.bookings.filter(booking => booking.id !== payload)
            }
        case FAILED_REQUEST:
            return {
                ...state,
                bookings: state.bookings.filter(booking => booking.id !== payload)
            }
        default:
            return state
    }
}