import React, { Fragment } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Moment from 'moment';
import { Link } from 'react-router-dom';

const ShowBookings = ({ listBookings }) => {

    //console.log('listBookings', listBookings)

    const addDays = (date, days) => {
        var result = new Date(date);
        
        if (days > 0) {
            result.setDate(result.getDate() + (Number(days) -1));
        } else {
            result = date;
        }
        
        return Moment(result).format('MMM DD, YYYY');
    }

    const popover = (dataBooking) => (
        <Popover id="popover-basic">
            <Popover.Content>
                <div className="row">
                    <div className="col-md-5"><label>Booking</label></div>
                    <div className="col-md-7"><strong>APH_{Moment(dataBooking.created_at).format('YYMMDD')}_{dataBooking.id}</strong></div>
                </div>
                <div className="row">
                    <div className="col-md-5"><label>Guest Name</label></div>
                    <div className="col-md-7"><strong>{dataBooking.mrName} {dataBooking.firstName} {dataBooking.lastName}</strong></div>
                </div>
                {dataBooking.tourName !== null && (
                    <div className="row">
                        <div className="col-md-5"><label>Tour Name</label></div>
                        <div className="col-md-7"><strong>{dataBooking.tourName}</strong></div>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-5"><label>Date Start</label></div>
                    <div className="col-md-7"><strong>{Moment(dataBooking.tourDate).format('MMM DD, YYYY')}</strong></div>
                </div>
                <div className="row">
                    <div className="col-md-5"><label>Date Start</label></div>
                    <div className="col-md-7"><strong>{addDays(dataBooking.tourDate, dataBooking.duration)}</strong></div>
                </div>
                {dataBooking.sale !== '' && (
                    <div className="row">
                        <div className="col-md-5"><label>Sales</label></div>
                        <div className="col-md-7"><strong>{dataBooking.sale.name}</strong></div>
                    </div>
                )}
                
                
            </Popover.Content>
        </Popover>
    );
    let body = null

    if(listBookings !== undefined){
        body = (
            listBookings.map((booking, index) => (
                <div key={booking.id} className={`item-status booking-${booking.status}`}>
                    <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={popover(booking)}>
                        <Link to={`/operating/${booking.id}`} target="_blank"
                            className={'show-booking'}
                        >APH_{Moment(booking.created_at).format('YYMMDD')}_{booking.id}
                        </Link>
                    </OverlayTrigger>
                </div>
            ))
        )
    }

    return (
        <Fragment>
            {body !== '' && body}
        </Fragment>
    )
}

export default ShowBookings