import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RequestContext } from '../../contexts/RequestContext';
import ShowBookings from './ShowBookings';

const Booking = () => {

    const { 
        requestState: { bookings },
        getSuccess
    } = useContext(RequestContext)

    const nowDate = new Date();
    const monthCurrent = 10; //nowDate.getMonth();
    const yearCurrent = nowDate.getFullYear();
    const daysInMonths = new Date(yearCurrent, monthCurrent, 0).getDate();
    
    // Start: Get all xe , []
    useEffect( () => getSuccess(`?month=${monthCurrent}&year=${yearCurrent}`), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    const arrDayCountCustomers = []
    let data = {};

    if (bookings) {
        bookings.forEach((item) => {
            const itemDate = item.tourDate;
            if (!data[itemDate]) {
                data[itemDate] = [item];
            } else {
                data[itemDate].push(item);
            }
        });
        //console.log('data', data)
        // eslint-disable-next-line no-lone-blocks
        { Array.from(Array(daysInMonths), (e, i) => {
            const dayCustomer = ("0" + (i+1)).slice(-2);
            const dateCustomer = `${yearCurrent}-${monthCurrent}-${dayCustomer}`;
            var countCustomer = 0
            if(data[dateCustomer] !== undefined){
                if(data[dateCustomer].length > 1){
                    data[dateCustomer].map((arrCus) => {
                        countCustomer += 1
                        arrDayCountCustomers[i+1] = countCustomer 
                    })
                }else{
                    data[dateCustomer].map((arrCus) => {
                        countCustomer += 1
                        arrDayCountCustomers[i+1] = countCustomer
                    })
                }
            }
        })}
    }

    //console.log('data', data)
    let body = null

    if(data){
        body = (
            <div className='dashboard-table'>
                <div className='card card-table-two'>
                    <div className="d-flex justify-content-between"><h4 className="card-title mb-1">List Tour in month {monthCurrent}</h4></div>
                    <span className="tx-12 tx-muted mb-3 ">List of successful delegations.</span>
                    <div className="booking-tour table-responsive mb-5">
                        <table className="table table-striped table-bordered mt-2 mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Code Booking</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from(Array(daysInMonths), (e, i) => {
                                    let dayRun = i + 1
                                    if(i+1 < 10 ){
                                        dayRun = `0${i+1}`
                                    }else{
                                        dayRun = `${i+1}`
                                    }
                                    const dateItem = `${yearCurrent}-${monthCurrent}-${dayRun}`
                                    //console.log('data[`${dateItem}`]', data[`${dateItem}`])

                                    return (
                                        <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>
                                                {data[`${dateItem}`] !== undefined && (
                                                    <ShowBookings listBookings={data[`${dateItem}`]} />
                                                )}
                                            </td>
                                            <td>{arrDayCountCustomers[i+1]}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body !== null && body}
        </Fragment>
    )
}

export default Booking