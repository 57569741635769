import { Fragment, useContext, useState, useEffect } from 'react';
import { TodoListContext } from '../../contexts/TodoListContext';
import { AuthContext } from '../../contexts/AuthContext';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import Select from 'react-select';

const AddTodoList = () => {

    // Context
    const { showAddTodoList, setAddTodoList, addTodoList, setShowToast } = useContext(TodoListContext)

    const { authState: { users }, getUsers } = useContext(AuthContext)

    const [isCheck, setIsCheck] = useState(false)
    const [isPCheck, setIsPCheck] = useState(false)

    // State
    const [newTodo, setNewTodo] = useState({
        name: '',
        dueFrom: '',
        dueTo: '',
        description: '',
        noteTodo: ''
    })

    const { name, dueFrom, dueTo, description, noteTodo } = newTodo

    const onChangeValue = event => setNewTodo( {...newTodo, [event.target.name]: event.target.value } )

    // Start: Get all data users, []
    useEffect( () => getUsers(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    let optionSales = []

    const priorities = [
        { value: 'slow', label: 'Slow' },
        { value: 'medium', label: 'Medium'},
        { value: 'high', label: 'High'}
    ]

    if(users.length > 0){
        users.map((data) => 
            optionSales = [ ...optionSales, 
                {
                    value: data.id,
                    label: data.name,
                }
            ]
        )
    }

    const onSubmit = async event => {
        event.preventDefault()
        //console.log('newTodo', newTodo)
        newTodo['status'] = 'unfinished';
        const {success, message} = await addTodoList(newTodo)
        closeDialog()
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
    }

    const handleChange = (newValue, actionMeta) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            if(actionMeta.name === 'priority'){
                setNewTodo( {...newTodo, priority: dataValue } )
                setIsPCheck(true)
            }
            if(actionMeta.name === 'user'){
                setNewTodo( {...newTodo, user: dataValue } )
                setIsCheck(true)
            }
        }
    }

    const closeDialog = () => {
        setNewTodo({
            name: '',
            dueFrom: '',
            dueTo: '',
            description: '',
            noteTodo: ''
        })
        setAddTodoList(false)
    }

    return (
        <Fragment>
            <Modal show={showAddTodoList} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add To-do
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <label><strong>Name</strong></label>
                            <input type="text" className="form-control" id="name" name="name" placeholder="Enter Name To-do" value={name} onChange={onChangeValue} required aria-describedby='name-help' />
                            <Form.Text id='name-help' muted>Required</Form.Text>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>From</strong></label>
                                    <input type="date" className="form-control" id="dueFrom" name="dueFrom" placeholder="Enter Name Car" value={dueFrom} onChange={onChangeValue} required />
                                </Col>
                                <Col>
                                    <label><strong>To</strong></label>
                                    <input type="date" className="form-control" id="dueTo" name="dueTo" placeholder="Enter Name Car" value={dueTo} onChange={onChangeValue} required />
                                </Col>
                            </Row>
                        </div>
                        <div className="form-group">
                            <Row>
                                <Col>
                                    <label><strong>Priority</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        options={priorities}
                                        menuPosition={'fixed'}
                                        placeholder="Select Priority"
                                        name="priority"
                                    />
                                </Col>
                                <Col>
                                    <label><strong>User</strong></label>
                                    <Select isClearable
                                        onChange={handleChange.bind(this)}
                                        options={optionSales}
                                        menuPosition={'fixed'}
                                        placeholder="Select User"
                                        name="user"
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            <label><strong>Description</strong></label>
                            <textarea className="form-control" placeholder="Enter Description" rows="5" name='description' value={description} onChange={onChangeValue} spellCheck="false"></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit' disabled={(isCheck === true && isPCheck === true) ? false : true}><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddTodoList
