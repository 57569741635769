import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const ItemAddLinkPayment = ( { itemKey, invoice, resultOrders, arrLinkPayments, setArrLinkPayments, checkLinkOrder } ) => {


    const [amountPay, setAmountPay] = useState(0)
    let chay = itemKey + checkLinkOrder

    const onChangeValue = (index, event) => {
        if (!arrLinkPayments[index]) {
            arrLinkPayments[index] = {};
        }
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        arrLinkPayments[index][fieldName] =  Number(event.target.value);
        arrLinkPayments[index]['id'] =  0;
        if(fieldName === "amountPay"){
            setAmountPay( Number(event.target.value) )
        }
        
    }

    const handleChangeSelect = (index, newValue) => {
        if (!arrLinkPayments[index]) {
            arrLinkPayments[index] = {};
        }
        const data = newValue;
        if(data){
            const dataValue = data.value
            arrLinkPayments[index]['orderPay'] =  dataValue;
        }
    }

    //console.log('arrLinkPayments', arrLinkPayments)
    //console.log('chay', chay)
    //console.log('index', itemKey)

    return (
        <Fragment>
            <Row className='mg-t-20'>
                <Col md={{ span: 4, offset: 4 }}>
                    <div className='info-transaction'>
                        <h4 className='text-center'>Create Link Payment</h4>
                        <div className="form-group">
                            <label><strong>Transaction OrderInfo</strong></label>
                            <input type="text" className="form-control" id="vpc_OrderInfo" name="vpc_OrderInfo" defaultValue={`#${invoice.booking_id}`} readOnly />
                        </div>
                        <div className="form-group">
                            <label><strong>Order Pay</strong></label>
                            <Select isClearable
                                onChange={handleChangeSelect.bind(this, chay)}
                                options={resultOrders} 
                                menuPosition={'fixed'}
                                placeholder="Select Order"
                                name={`orderPay-${chay}`}
                            />
                        </div>
                        <div className="form-group">
                            <label><strong>Purchase Amount</strong></label>
                            <input type="number" className="form-control" id="amountPay" name={`amountPay-${chay}`} value={amountPay} onChange={onChangeValue.bind(this, chay)} placeholder="No. Amount" />
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ItemAddLinkPayment