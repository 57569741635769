import { createContext, useState, useReducer } from 'react';
import { countryReducer } from '../reducers/countryReducer';
import { apiUrl, COUNTRIES_LOADED_FAIL, COUNTRIES_LOADED_SUCCESS } from './constants';
import axios from 'axios';


export const CountryContext = createContext()

const CountryContextProvider = ({ children }) => {

    // State
    const [countryState, dispatch] = useReducer(countryReducer, {
        country: null,
        countries: [],
        countriesLoading: true
    })

    /*const [showAddCar, setAddCar] = useState(false)
    const [showUpdateCar, setShowUpdateCar] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })*/

    // Get all Cars
    const getCountries = async() => {
        try {
            const response = await axios.get(`${apiUrl}/countries`)
            if (response.data.success) {
                dispatch({ type: COUNTRIES_LOADED_SUCCESS, payload: response.data.countries })
            }
        } catch (error) {
            dispatch({ type: COUNTRIES_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Country Context Data
    const countryContextData = {
        countryState,
        getCountries
    }

    return ( 
        <CountryContext.Provider value = { countryContextData } > 
            { children } 
        </CountryContext.Provider>
    )

}

export default CountryContextProvider