import { createContext, useState, useReducer } from 'react';
import { todoListReducer } from '../reducers/todoListReducer';
import { apiUrl, TODO_LISTS_LOADED_FAIL, TODO_LISTS_LOADED_SUCCESS, FIND_TODO_LIST, UPDATE_TODO_LIST, DELETE_TODO_LIST, ADD_TODO_LIST } from './constants';
import axios from 'axios';


export const TodoListContext = createContext()

const TodoListContextProvider = ({ children }) => {

    // State
    const [todoListState, dispatch] = useReducer(todoListReducer, {
        todoList: null,
        todoLists: [],
        todoListsLoading: true
    })

    const [showAddTodoList, setAddTodoList] = useState(false)
    const [showUpdateTodoList, setShowUpdateTodoList] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Todo Lists
    const getTodoLists = async() => {
        try {
            const response = await axios.get(`${apiUrl}/todo-lists`)
            if (response.data.success) {
                dispatch({ type: TODO_LISTS_LOADED_SUCCESS, payload: response.data.todoLists })
            }
        } catch (error) {
            dispatch({ type: TODO_LISTS_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add TodoList
    const addTodoList = async newTodoList => {
        try {
            const response = await axios.post(`${apiUrl}/todo-lists`, newTodoList)
            if (response.data.success) {
                dispatch({
                    type: ADD_TODO_LIST,
                    payload: response.data.todoList
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find TodoList when user is updating TodoList
    const findTodoList = todoListId => {
        const todoList = todoListState.todoLists.find(todoList => todoList.id === todoListId)
        dispatch({
            type: FIND_TODO_LIST,
            payload: todoList
        })
    }

    // update TodoList
    const updateTodoList = async updateTodoList => {
        try {
            const response = await axios.put(`${apiUrl}/todo-lists/${updateTodoList.id}`, updateTodoList)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_TODO_LIST,
                    payload: response.data.todoList
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete TodoList
    const deleteTodoList = async todoListId => {
        try {
            const response = await axios.delete(`${apiUrl}/todo-lists/${todoListId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_TODO_LIST,
                    payload: todoListId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Todo List Context Data
    const todoListContextData = {
        todoListState,
        getTodoLists,
        addTodoList,
        findTodoList,
        updateTodoList,
        deleteTodoList,
        showToast,
        setShowToast,
        showAddTodoList,
        setAddTodoList,
        showUpdateTodoList,
        setShowUpdateTodoList
    }

    return ( 
        <TodoListContext.Provider value = { todoListContextData } > 
            { children } 
        </TodoListContext.Provider>
    )

}

export default TodoListContextProvider