import { Fragment, useContext, useEffect, useState } from 'react';
import { FullItineraryContext } from '../../contexts/FullItineraryContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import UpdateFullItinerary from './UpdateFullItinerary';
//import DeleteModal from '../../components/layout/DeleteModal';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import CopyFullItinerary from './copyItinerary/CopyFullItinerary';
import "datatables.net-dt/js/dataTables.dataTables.min.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const ListFullItinerary = () => {

    // Context
    const [isUpdate, setIsUpdate] = useState(false)
    const [isCopy, setIsCopy] = useState(false)
    const {authState: {user: {name} }} = useContext(AuthContext)
    const { 
        fullItineraryState: { fullItinerary, fullItineraries, fullItinerariesLoading },
        getFullItineraries,
        findFullItinerary,
        deleteFullItinerary,
        setShowUpdateFullItinerary,
        setShowCopyFullItinerary
    } = useContext(FullItineraryContext)

    const chooseFullItinerary = fullItineraryId => {
        findFullItinerary(fullItineraryId)
        setShowUpdateFullItinerary(true)
        setIsUpdate(true)
        setIsCopy(false)
    }

    const chooseCopyFullItinerary = fullItineraryId => {
        findFullItinerary(fullItineraryId)
        setShowCopyFullItinerary(true)
        setIsCopy(true)
        setIsUpdate(false)
    }

    // Start: Get all Full Itineraries , []
    useEffect( () => getFullItineraries(), [] ) // eslint-disable-line react-hooks/exhaustive-deps
    let body = null

    useEffect( () => 
        setTimeout(function(){ $('#list-request').DataTable(); }, 1000)
    , []) // eslint-disable-line react-hooks/exhaustive-deps

    //console.log('fullItineraries', fullItineraries)

    if(fullItinerariesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(fullItineraries.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name},</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Asia Private Holidays' tour management software!</Card.Title>
                        <Card.Text>
                            Click the button below to generate a Full Itinerary
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <Fragment>
                <div className="ajax-booking-cruise">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-info-cruise mg-b-20">
                                <div className="table-info-cruise-body">
                                    <div className="table-responsive">
                                        <table id="list-request" className="table table-striped mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Route</th>
                                                    <th>Durations</th>
                                                    <th>Prepared</th>
                                                    <th>Arrival</th>
                                                    <th>Travel Consultant</th>
                                                    <th className="w-220">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fullItineraries.map((itinerary, index) => (
                                                <tr key={index}>
                                                    <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                    <td style={ {lineHeight: "38px"} }>{itinerary.nameRoute}</td>
                                                    <td style={ {lineHeight: "38px"} }>{itinerary.durations} Day(s)</td>
                                                    <td style={ {lineHeight: "38px"} }>{itinerary.customerName}</td>
                                                    <td style={ {lineHeight: "38px"} }>{Moment(itinerary.arrival).format('MMM D, YYYY')}</td>
                                                    <td style={ {lineHeight: "38px"} }>{itinerary.sale.name}</td>
                                                    <td>
                                                        <div className="action-invoice itinerary-action row">
                                                            <div className="col">
                                                                <OverlayTrigger overlay={<Tooltip>View Itinerary</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <Link  to={`/full-itineraries/${itinerary.id}`} title={`View ${itinerary.name}`} className="btn btn-primary btn-with-icon btn-block" target={'_blank'}>
                                                                            <i className="fe fe-book-open"></i> View
                                                                        </Link>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="col">
                                                                <button className="btn btn-success btn-with-icon btn-block" onClick={chooseFullItinerary.bind(this, itinerary.id)}><i className="fe fe-edit"></i> Edit</button>
                                                            </div>
                                                            <div className="col">
                                                                <button className="btn btn-info btn-with-icon btn-block" onClick={chooseCopyFullItinerary.bind(this, itinerary.id)}><i className="fe fe-file-text"></i> Copy</button>
                                                            </div>
                                                            {/*<div className="col">
                                                                <DeleteModal idProps={itinerary.id} deleteFunc={deleteFullItinerary} />
                                                            </div>*/}
                                                        </div>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )  
    }

    return (
        <Fragment>
            {body}
            {fullItinerary !== null && isUpdate === true && <UpdateFullItinerary />}
            {fullItinerary !== null && isCopy === true && <CopyFullItinerary />}
        </Fragment>
    )
}

export default ListFullItinerary