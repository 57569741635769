import { Fragment, useContext } from 'react';
import { Toast } from 'react-bootstrap';
import Information from '../components/dashboard/Information';

import { AuthContext } from '../contexts/AuthContext';
import { TodoListContext } from '../contexts/TodoListContext';
import Booking from './bookings/Booking';
import AddTodoList from './todoLists/AddTodoList';



const Dashboard = () => {
    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)

    const { 
        setAddTodoList,
        showToast: {show, message, type},
        setShowToast
    } = useContext(TodoListContext)

    return (
        <Fragment>
            
            <div className="main-content horizontal-content">
                <div className="container">
                    <div className="breadcrumb-header justify-content-between">
                        <div className="left-content">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-1 mg-b-lg-1">Hello, {name}!</h2>
                                <p className="mg-b-0">Tour Management Dashboard - Asia Private Travels</p>
                            </div>
                        </div>
                        <div className='main-dashboard-header-right'>
                            <div>
                                <button className="right-content btn btn-success btn-block" onClick={setAddTodoList.bind(this, true)}><i className="fe fe-plus-circle"></i> Add Todo</button>
                            </div>
                        </div>
                    </div>

                    <Booking />
                    <Information />        
                    <AddTodoList />
                    <Toast 
                        show={show} 
                        style={ {position: 'fixed', top: '20%', right: '10px'} } 
                        className={`bg-${type} text-white`} 
                        onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>
                        <strong style={ {color: '#ffffff'} }>{message}</strong>
                        </Toast.Body>
                    </Toast>
                </div>
            </div>
            
        </Fragment>
    )
}

export default Dashboard
