import { createContext, useState, useReducer } from 'react';
import { requestReducer } from '../reducers/requestReducer';
import { apiUrl, REQUESTS_LOADED_RUN_SUCCESS, REQUESTS_LOADED_FAIL, REQUESTS_LOADED_SUCCESS, FIND_REQUEST, UPDATE_REQUEST, DELETE_REQUEST, ADD_REQUEST, SEND_REQUEST, FAILED_REQUEST, SEARCH_REQUEST } from './constants';
import axios from 'axios';


export const RequestContext = createContext()

const RequestContextProvider = ({ children }) => {

    // State
    const [requestState, dispatch] = useReducer(requestReducer, {
        booking: null,
        bookings: [],
        bookingsLoading: true
    })

    const [showAddRequest, setAddRequest] = useState(false)
    const [showUpdateRequest, setShowUpdateRequest] = useState(false)
    const [showAddInvoice, setShowAddInvoice] = useState(false)
    const [showUpdateInvoice, setShowUpdateInvoice] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // get all requests success
    const getSuccess = async $object => {
        try {
            const response = await axios.get(`${apiUrl}/requests/success${$object}`)
            if (response.data.success) {
                dispatch({ type: REQUESTS_LOADED_RUN_SUCCESS, payload: response.data.bookings })
            }
        } catch (error) {
            console.log('API Error', error)
        }
    }

    // Get all Requests
    const getRequests = async () => {
        try {
            const response = await axios.get(`${apiUrl}/requests`)
            if (response.data.success) {
                dispatch({ type: REQUESTS_LOADED_SUCCESS, payload: response.data.bookings })
            }
        } catch (error) {
            dispatch({ type: REQUESTS_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Request
    const addRequest = async newRequest => {
        try {
            const response = await axios.post(`${apiUrl}/requests`, newRequest)
            if (response.data.success) {
                dispatch({
                    type: ADD_REQUEST,
                    payload: response.data.booking
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Request when user is updating Request
    const findRequest = requestId => {
        const booking = requestState.bookings.find(booking => booking.id === requestId)
        dispatch({
            type: FIND_REQUEST,
            payload: booking
        })
    }

    // Search Request
    const searchRequest = async requestId => {
        //
        try {
            const response = await axios.get(`${apiUrl}/requests/${requestId}`)
            if (response.data.success) {
                dispatch({ 
                    type: SEARCH_REQUEST, 
                    payload: response.data.booking 
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // update Request
    const updateRequest = async updateRequest => {
        try {
            const response = await axios.put(`${apiUrl}/requests/${updateRequest.id}`, updateRequest)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_REQUEST,
                    payload: response.data.booking
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // send Request
    const sendRequest = async requestId => {
        try {
            //console.log('requestId', requestId)
            var arrRequest = requestId.split("-");
            const response = await axios.get(`${apiUrl}/requests/send/${requestId}`)
            if (response.data.success) {
                dispatch({
                    type: SEND_REQUEST,
                    payload: arrRequest[0]
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // send Request
    const failedRequest = async requestId => {
        try {
            const response = await axios.get(`${apiUrl}/requests/failed/${requestId}`)
            if (response.data.success) {
                dispatch({
                    type: FAILED_REQUEST,
                    payload: requestId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Delete Request
    const deleteRequest = async requestId => {
        try {
            const response = await axios.delete(`${apiUrl}/requests/${requestId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_REQUEST,
                    payload: requestId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Request Context Data
    const requestContextData = {
        requestState,
        getSuccess,
        getRequests,
        addRequest,
        findRequest,
        searchRequest,
        updateRequest,
        sendRequest,
        failedRequest,
        deleteRequest,
        showToast,
        setShowToast,
        showAddRequest,
        setAddRequest,
        showUpdateRequest,
        setShowUpdateRequest,
        showAddInvoice,
        setShowAddInvoice,
        showUpdateInvoice,
        setShowUpdateInvoice
    }

    return ( 
        <RequestContext.Provider value = { requestContextData } > 
            { children } 
        </RequestContext.Provider>
    )

}

export default RequestContextProvider