import { createContext, useState, useReducer } from 'react';
import { serviceReducer } from '../reducers/serviceReducer';
import { apiUrl, ADD_SERVICE, SERVICES_LOADED_FAIL, SERVICES_LOADED_SUCCESS, DELETE_SERVICE, FIND_SERVICE, UPDATE_SERVICE } from './constants';
import axios from 'axios';


export const ServiceContext = createContext()

const ServiceContextProvider = ({ children }) => {

    // State
    const [serviceState, dispatch] = useReducer(serviceReducer, {
        service: null,
        services: [],
        servicesLoading: true
    })

    const [showAddService, setAddService] = useState(false)
    const [showUpdateService, setShowUpdateService] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Services
    const getServices = async() => {
        try {
            const response = await axios.get(`${apiUrl}/services`)
            if (response.data.success) {
                dispatch({ type: SERVICES_LOADED_SUCCESS, payload: response.data.services })
            }
        } catch (error) {
            dispatch({ type: SERVICES_LOADED_FAIL })
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Add Service
    const addService = async newService => {
        try {
            const response = await axios.post(`${apiUrl}/services`, newService)
            if (response.data.success) {
                dispatch({
                    type: ADD_SERVICE,
                    payload: response.data.service
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Find Service when user is updating Service
    const findService = serviceId => {
        const service = serviceState.services.find(service => service.id === serviceId)
        dispatch({
            type: FIND_SERVICE,
            payload: service
        })
    }

    // update Service
    const updateService = async updateService => {
        try {
            const response = await axios.put(`${apiUrl}/services/${updateService.id}`, updateService)
            if (response.data.success) {
                dispatch({
                    type: UPDATE_SERVICE,
                    payload: response.data.service
                })
                return response.data
            }
        } catch (error) {
            return error.response.data ? error.response.data : { success: false, message: 'Server error!' }
        }
    }

    // Delete Service
    const deleteService = async serviceId => {
        try {
            const response = await axios.delete(`${apiUrl}/services/${serviceId}`)
            if (response.data.success) {
                dispatch({
                    type: DELETE_SERVICE,
                    payload: serviceId
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Service Context Data
    const serviceContextData = {
        serviceState,
        getServices,
        addService,
        findService,
        updateService,
        deleteService,
        showToast,
        setShowToast,
        showAddService,
        setAddService,
        showUpdateService,
        setShowUpdateService
    }

    return ( 
        <ServiceContext.Provider value = { serviceContextData } > 
            { children } 
        </ServiceContext.Provider>
    )


}

export default ServiceContextProvider