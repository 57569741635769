import { Fragment, useEffect } from 'react';
import $ from "jquery";
import Select from 'react-select';

const ListPromotionMail = () => {

    useEffect(() => {
        $('#checkAll').on('click', function() {
            if ($(this).is(':checked')) {
                $('.main-mail-list .ckbox input').each(function() {
                    $(this).closest('.main-mail-item').addClass('selected');
                    $(this).attr('checked', true);
                });
                $('.main-mail-options .btn:not(:first-child)').removeClass('disabled');
            } else {
                $('.main-mail-list .ckbox input').each(function() {
                    $(this).closest('.main-mail-item').removeClass('selected');
                    $(this).attr('checked', false);
                });
                $('.main-mail-options .btn:not(:first-child)').addClass('disabled');
            }
        });
        $('.main-mail-item .main-mail-checkbox input').on('click', function() {
            if ($(this).is(':checked')) {
                $(this).attr('checked', false);
                $(this).closest('.main-mail-item').addClass('selected');
                $('.main-mail-options .btn:not(:first-child)').removeClass('disabled');
            } else {
                $(this).attr('checked', true);
                $(this).closest('.main-mail-item').removeClass('selected');
                if (!$('.main-mail-list .selected').length) {
                    $('.main-mail-options .btn:not(:first-child)').addClass('disabled');
                }
            }
        });
    }, [])

    const arrayTemplates = [
        { value: 1, label: 'Tet Holiday Promotion' },
        { value: 2, label: 'Summer Holiday Promotion' },
        { value: 3, label: 'Winter Holiday Promotion' },
        { value: 4, label: 'Black Friday Promotion' },
        { value: 5, label: 'Christmas promotion' }
    ]

    const handleChangeSelect = (newValue) => {
        const data = newValue;
        if(data){
            const dataValue = data.value
            
        }
    }

    return (
        <Fragment>
            <div className="row row-sm main-content-mail">

                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card">
                        <div className="main-content-body main-content-body-mail card-body">
                            <div className="main-mail-header">
                                <div>
                                    <h4 className="main-content-title mg-b-5">Send Email Marketing</h4>
                                </div>
                                <div> 
                                    <span>1-50 of 1200</span> 
                                    <div className="btn-group" role="group"> 
                                        <button className="btn btn-outline-secondary disabled" type="button"><i className="icon ion-ios-arrow-back"></i></button> 
                                        <button className="btn btn-outline-secondary" type="button"><i className="icon ion-ios-arrow-forward"></i></button> 
                                    </div> 
                                </div> 
                            </div>

                            <div className="main-mail-options"> 
                                <label className="ckbox">
                                    <input id="checkAll" type="checkbox" />
                                    <span></span>
                                </label> 
                                <div className='subject-mail'>
                                    <input type="text" className="form-control" id="subject" name="subject" placeholder="Enter Subject Promotion Mail" required />
                                </div>
                                <div className='action-group'>
                                    <Select isClearable
                                        className={`template-holidays`}
                                        onChange={handleChangeSelect.bind(this)}
                                        options={arrayTemplates} 
                                        menuPosition={'fixed'}
                                        placeholder="Select Promotion"
                                        name="promotion"
                                    />
                                </div>
                                <div className="btn-group"> 
                                    <button className="btn btn-light"><i className="bx bx-refresh"></i></button>
                                    <button className="btn btn-light disabled"><i className="fe fe-send"></i></button> 
                                </div>
                            </div>

                            <div className="main-mail-list"> 
                                <div className="main-mail-item unread"> 
                                    <div className="main-mail-checkbox"> 
                                        <label className="ckbox">
                                            <input type="checkbox" /> 
                                            <span></span>
                                        </label> 
                                    </div> 
                                    <div className="main-mail-body"> 
                                        <div className="main-mail-from"> Adrian Monino </div> 
                                        <div className="main-mail-subject"> 
                                            <strong style={{width: '100%', float: 'left'}}>Someone who believes in you</strong> 
                                            <span style={{width: '100%', float: 'left'}}>enean commodo li gula eget dolor cum socia eget dolor enean commodo li gula eget dolor cum socia eget dolor</span> 
                                        </div> 
                                    </div> 
                                </div>
                                <div className="main-mail-item unread"> 
                                    <div className="main-mail-checkbox"> 
                                        <label className="ckbox">
                                            <input type="checkbox" /> 
                                            <span></span>
                                        </label> 
                                    </div> 
                                    <div className="main-mail-body"> 
                                        <div className="main-mail-from"> Adrian Monino 2</div> 
                                        <div className="main-mail-subject"> 
                                            <strong style={{width: '100%', float: 'left'}}>Someone who believes in you 2</strong> 
                                            <span style={{width: '100%', float: 'left'}}>enean commodo li gula eget dolor cum socia eget dolor enean commodo li gula eget dolor cum socia eget dolor</span> 
                                        </div> 
                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default ListPromotionMail